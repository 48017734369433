import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { initialState } from 'store/company/initialState';
import { ICompaniesState, IVoucher, IVoucherData } from 'store/company/interfaces';
import { FetchingStatus } from 'store/interfaces';
import { sendUserData } from './sendUserData';

export const sendUserDataExtraReducer = (builder: ActionReducerMapBuilder<ICompaniesState>) => {
  builder.addCase(sendUserData.pending, (state, action) => {
    state.fetchingStatus = FetchingStatus.PENDING;
    state.error = null;
    state.errorViolations = null;
  });
  builder.addCase(sendUserData.fulfilled, (state, action) => {
    state.fetchingStatus = FetchingStatus.FULFILLED;
    state.successEmail = state.userInfo ? state.userInfo.businessEmail : null;
    state['successDuration'] = state.userInfo ? state.userInfo.duration : null;
    state.error = null;
    state.errorViolations = null;
    state.userInfo = null;
    state.invitationToken = null;
    // state.fetchingStatus = FetchingStatus.FULFILLED;
    //state = { ...initialState, fetchingStatus: FetchingStatus.FULFILLED };
  });

  builder.addCase(sendUserData.rejected, (state, action: any) => {
    if ((action.payload as IVoucherData)?.invalid?.length > 0 && state.vouchers.data) {
      const validResult = [];
      const invalidResult = [];
      for (let i = 0; i < state.vouchers.data.valid.length; i++) {
        let invalidItem = action.payload.invalid.find(
          (invalidItem: IVoucher) =>
            invalidItem.voucher.code === state.vouchers.data?.valid[i].voucher.code,
        );
        if (invalidItem.message === 'failure') {
          invalidResult.push({
            ...invalidItem,
            voucher: state.vouchers.data?.valid[i].voucher,
          });
        } else if (invalidItem.message === 'success') {
          validResult.push(state.vouchers.data?.valid[i]);
        }
      }
      state['invalidSubmittedVouchers'] = invalidResult;
      state.vouchers.data.valid = validResult;
      state.vouchers.data.hansefitCost = action.payload.hansefitCost;
      state.vouchers.data.payableAmount = action.payload.payableAmount;
      state.vouchers.data.totalVoucherValue = action.payload.totalVoucherValue;
    }
    state.error = action.error;
    state.errorViolations = action?.payload?.violations.map((item: any) => {
      return {
        ...item,
        propertyPath: item.propertyPath.replaceAll('][', '.').replace(/[\[\]]/g, ''),
      };
    });
    state.fetchingStatus = FetchingStatus.REJECTED;
  });
};
