import { MenuItem } from '@mui/material';
import CCPDatePicker from 'components/FormComponents/DatePicker';
import CCPSelect from 'components/FormComponents/Select';
import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { format } from 'date-fns';

interface SartDatePickerProps {
  control: any;
  label: string;
  disabled: boolean;
  hidden: boolean;
  name: string;
  isMonthPicker: boolean;
  minimumDate: Date | undefined;
  maximumDate: Date | undefined;
  getValues: any;
}

const StartDatePicker: React.FC<SartDatePickerProps> = ({
  control,
  label,
  disabled,
  hidden,
  name,
  isMonthPicker,
  minimumDate,
  maximumDate,
  getValues,
}) => {
  const { formatMessage } = useIntl();

  const monthMessages: Record<string, { id: string; defaultMessage: string }> = defineMessages({
    0: {
      id: 'month_picker.january',
      defaultMessage: 'January',
    },
    1: {
      id: 'month_picker.february',
      defaultMessage: 'February',
    },
    2: {
      id: 'month_picker.march',
      defaultMessage: 'March',
    },
    3: {
      id: 'month_picker.april',
      defaultMessage: 'April',
    },
    4: {
      id: 'month_picker.may',
      defaultMessage: 'May',
    },
    5: {
      id: 'month_picker.june',
      defaultMessage: 'June',
    },
    6: {
      id: 'month_picker.july',
      defaultMessage: 'July',
    },
    7: {
      id: 'month_picker.august',
      defaultMessage: 'August',
    },
    8: {
      id: 'month_picker.september',
      defaultMessage: 'September',
    },
    9: {
      id: 'month_picker.october',
      defaultMessage: 'October',
    },
    10: {
      id: 'month_picker.november',
      defaultMessage: 'November',
    },
    11: {
      id: 'month_picker.december',
      defaultMessage: 'December',
    },
  });

  const months = useMemo(() => {
    if (minimumDate && maximumDate) {
      const prepareMonthObj = (
        year: number,
        currentMonth: number,
        monthTitle: { id: string; defaultMessage: string },
      ) => {
        return {
          value: format(new Date(year, currentMonth, 1), 'yyyy-MM-dd'),
          title: `${formatMessage(monthTitle)} ${year}`,
        };
      };
      if (minimumDate.getFullYear() === maximumDate.getFullYear()) {
        return Object.keys(monthMessages)
          .filter(
            key => Number(key) >= minimumDate.getMonth() && Number(key) <= maximumDate.getMonth(),
          )
          .map(key => prepareMonthObj(minimumDate.getFullYear(), Number(key), monthMessages[key]));
      } else {
        return Object.keys(monthMessages)
          .filter(key => Number(key) >= minimumDate.getMonth())
          .map(key => prepareMonthObj(minimumDate.getFullYear(), Number(key), monthMessages[key]))
          .concat(
            Object.keys(monthMessages)
              .filter(key => Number(key) <= maximumDate.getMonth())
              .map(key =>
                prepareMonthObj(maximumDate.getFullYear(), Number(key), monthMessages[key]),
              ),
          );
      }
    }
    return [];
  }, [minimumDate, maximumDate]);

  return isMonthPicker ? (
    <CCPSelect control={control} disabled={disabled} hidden={hidden} name={name} label={label}>
      {months.map(month => (
        <MenuItem
          style={{ minHeight: '48px' }}
          key={month.value}
          value={month.value}
          selected={month.value === getValues(name)}>
          {month.title}
        </MenuItem>
      ))}
    </CCPSelect>
  ) : (
    <CCPDatePicker
      control={control}
      disabled={disabled}
      hidden={hidden}
      name={name}
      isMonthPicker={isMonthPicker}
      minDate={minimumDate}
      maxDate={maximumDate}
      label={label}
    />
  );
};

export default StartDatePicker;
