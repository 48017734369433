import { styled } from '@mui/material';

export const MainLayoutStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  '.main-content': {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  '.page-wrapper': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '56px 0',
    minHeight: 450,
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      padding: 0,
      minHeight: 'auto',
    },

    '.card': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minWidth: 620,
      maxWidth: 920,
      padding: 48,
      margin: 16,
      [theme.breakpoints.down('sm')]: {
        padding: 24,
        width: '100%',
        minWidth: 'auto',
        minHeight: 'auto',
      },
    },
    '.buttons-container': {
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        button: {
          width: '100%',
          maxWidth: '100%',
        },
      },
    },
  },
}));
