import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { FetchingStatus } from 'store/interfaces';
import { ICompaniesState, IUserInfo, VoucherCalculations } from '../interfaces';
import { IPaymentForm } from 'features/stepper/interfaces';

export const resetVoucherFetchingStatus: CaseReducer<
  ICompaniesState,
  PayloadAction<FetchingStatus>
> = (state, { payload }) => {
  state.vouchers.fetchingStatus = payload;
};

export const setVoucherError: CaseReducer<ICompaniesState, PayloadAction<string | null>> = (
  state,
  { payload },
) => {
  state.errorVoucher = payload;
};

export const fillUserInfo: CaseReducer<ICompaniesState, PayloadAction<IUserInfo>> = (
  state,
  { payload },
) => {
  state.userInfo = { ...state.userInfo, ...payload };
};

export const prefillPaymentInfo: CaseReducer<ICompaniesState, PayloadAction<IPaymentForm>> = (
  state,
  { payload },
) => {
  if (state.userInfo) {
    state.userInfo.paymentData = { ...state.userInfo?.paymentData, ...payload };
  }
};

export const resetFullState: CaseReducer<ICompaniesState, PayloadAction<string>> = (
  state,
  { payload },
) => {
  state.fetchingStatus = FetchingStatus.IDLE;
  state.data = null;
  state.error = null;
  state.password = null;
  state.userInfo = null;
  state.prefilled = {};
  state.errorVoucher = null;
  state.invalidSubmittedVouchers = [];
  state.signupId = payload;
  state.vouchers = { fetchingStatus: FetchingStatus.IDLE, data: null, error: null };
};

export const resetViolationErrors: CaseReducer<ICompaniesState, PayloadAction<null>> = (
  state,
  { payload },
) => {
  state.errorViolations = null;
};

export const updateVoucherCalculations: CaseReducer<
  ICompaniesState,
  PayloadAction<VoucherCalculations>
> = (state, { payload }) => {
  if (state.userInfo && state.userInfo.customFields) {
    state.userInfo.hansefit_membership = payload.membershipValue;
    state.userInfo.payable_amount = payload.paymentValue;
    state.userInfo.customFields.voucher_value = payload.profitBalance;
  }
};
