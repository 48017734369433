import * as yup from 'yup';
import { AnyObject, Maybe } from 'yup/lib/types';

yup.addMethod(yup.string, 'iban', function (errorMessage) {
  return this.test(`test-IBAN`, errorMessage, function (value) {
    const { path, createError } = this;

    let countries: {
      [key: string]: { countryCode: string; length: number; structure: string; example: string };
    } = {};

    const parseStructure = (structure: string) => {
      // split in blocks of 3 chars
      var regex = structure.match(/(.{3})/g)?.map(function (block) {
        // parse each structure block (1-char + 2-digits)
        var format,
          pattern = block.slice(0, 1),
          repeats = parseInt(block.slice(1), 10);

        switch (pattern) {
          case 'A':
            format = '0-9A-Za-z';
            break;
          case 'B':
            format = '0-9A-Z';
            break;
          case 'C':
            format = 'A-Za-z';
            break;
          case 'F':
            format = '0-9';
            break;
          case 'L':
            format = 'a-z';
            break;
          case 'U':
            format = 'A-Z';
            break;
          case 'W':
            format = '0-9a-z';
            break;
        }

        return '([' + format + ']{' + repeats + '})';
      });

      return new RegExp('^' + regex?.join('') + '$');
    };

    const A = 'A'.charCodeAt(0),
      Z = 'Z'.charCodeAt(0);

    const iso13616Prepare = (iban: string) => {
      iban = iban.toUpperCase();
      iban = iban.substr(4) + iban.substr(0, 4);

      return iban
        .split('')
        .map(function (n) {
          var code = n.charCodeAt(0);
          if (code >= A && code <= Z) {
            // A = 10, B = 11, ... Z = 35
            return code - A + 10;
          } else {
            return n;
          }
        })
        .join('');
    };

    const iso7064Mod97_10 = (iban: string) => {
      var remainder = iban,
        block;

      while (remainder.length > 2) {
        block = remainder.slice(0, 9);
        remainder = (parseInt(block, 10) % 97) + remainder.slice(block.length);
      }

      return parseInt(remainder, 10) % 97;
    };

    const isValid = function (iban: string | undefined) {
      if (iban === undefined || (typeof iban === 'string' && iban.length < 2)) return false;
      iban = iban.replaceAll(' ', '');
      const countryCode = iban.slice(0, 2);

      return (
        countries[countryCode] &&
        countries[countryCode].length == iban.length &&
        countries[countryCode].countryCode === iban.slice(0, 2) &&
        parseStructure(countries[countryCode].structure).test(iban.slice(4)) &&
        iso7064Mod97_10(iso13616Prepare(iban)) == 1
      );
    };

    const addSpecification = (
      countryCode: string,
      length: number,
      structure: string,
      example: string,
    ) => {
      countries[countryCode] = { countryCode, length, structure, example };
    };

    addSpecification('AD', 24, 'F04F04A12', 'AD1200012030200359100100');
    addSpecification('AE', 23, 'F03F16', 'AE070331234567890123456');
    addSpecification('AL', 28, 'F08A16', 'AL47212110090000000235698741');
    addSpecification('AT', 20, 'F05F11', 'AT611904300234573201');
    addSpecification('AZ', 28, 'U04A20', 'AZ21NABZ00000000137010001944');
    addSpecification('BA', 20, 'F03F03F08F02', 'BA391290079401028494');
    addSpecification('BE', 16, 'F03F07F02', 'BE68539007547034');
    addSpecification('BG', 22, 'U04F04F02A08', 'BG80BNBG96611020345678');
    addSpecification('BH', 22, 'U04A14', 'BH67BMAG00001299123456');
    addSpecification('BR', 29, 'F08F05F10U01A01', 'BR9700360305000010009795493P1');
    addSpecification('BY', 28, 'A04F04A16', 'BY13NBRB3600900000002Z00AB00');
    addSpecification('CH', 21, 'F05A12', 'CH9300762011623852957');
    addSpecification('CR', 22, 'F04F14', 'CR72012300000171549015');
    addSpecification('CY', 28, 'F03F05A16', 'CY17002001280000001200527600');
    addSpecification('CZ', 24, 'F04F06F10', 'CZ6508000000192000145399');
    addSpecification('DE', 22, 'F08F10', 'DE89370400440532013000');
    addSpecification('DK', 18, 'F04F09F01', 'DK5000400440116243');
    addSpecification('DO', 28, 'U04F20', 'DO28BAGR00000001212453611324');
    addSpecification('EE', 20, 'F02F02F11F01', 'EE382200221020145685');
    addSpecification('EG', 29, 'F04F04F17', 'EG800002000156789012345180002');
    addSpecification('ES', 24, 'F04F04F01F01F10', 'ES9121000418450200051332');
    addSpecification('FI', 18, 'F06F07F01', 'FI2112345600000785');
    addSpecification('FO', 18, 'F04F09F01', 'FO6264600001631634');
    addSpecification('FR', 27, 'F05F05A11F02', 'FR1420041010050500013M02606');
    addSpecification('GB', 22, 'U04F06F08', 'GB29NWBK60161331926819');
    addSpecification('GE', 22, 'U02F16', 'GE29NB0000000101904917');
    addSpecification('GI', 23, 'U04A15', 'GI75NWBK000000007099453');
    addSpecification('GL', 18, 'F04F09F01', 'GL8964710001000206');
    addSpecification('GR', 27, 'F03F04A16', 'GR1601101250000000012300695');
    addSpecification('GT', 28, 'A04A20', 'GT82TRAJ01020000001210029690');
    addSpecification('HR', 21, 'F07F10', 'HR1210010051863000160');
    addSpecification('HU', 28, 'F03F04F01F15F01', 'HU42117730161111101800000000');
    addSpecification('IE', 22, 'U04F06F08', 'IE29AIBK93115212345678');
    addSpecification('IL', 23, 'F03F03F13', 'IL620108000000099999999');
    addSpecification('IS', 26, 'F04F02F06F10', 'IS140159260076545510730339');
    addSpecification('IT', 27, 'U01F05F05A12', 'IT60X0542811101000000123456');
    addSpecification('IQ', 23, 'U04F03A12', 'IQ98NBIQ850123456789012');
    addSpecification('JO', 30, 'A04F22', 'JO15AAAA1234567890123456789012');
    addSpecification('KW', 30, 'U04A22', 'KW81CBKU0000000000001234560101');
    addSpecification('KZ', 20, 'F03A13', 'KZ86125KZT5004100100');
    addSpecification('LB', 28, 'F04A20', 'LB62099900000001001901229114');
    addSpecification('LC', 32, 'U04F24', 'LC07HEMM000100010012001200013015');
    addSpecification('LI', 21, 'F05A12', 'LI21088100002324013AA');
    addSpecification('LT', 20, 'F05F11', 'LT121000011101001000');
    addSpecification('LU', 20, 'F03A13', 'LU280019400644750000');
    addSpecification('LV', 21, 'U04A13', 'LV80BANK0000435195001');
    addSpecification('MC', 27, 'F05F05A11F02', 'MC5811222000010123456789030');
    addSpecification('MD', 24, 'U02A18', 'MD24AG000225100013104168');
    addSpecification('ME', 22, 'F03F13F02', 'ME25505000012345678951');
    addSpecification('MK', 19, 'F03A10F02', 'MK07250120000058984');
    addSpecification('MR', 27, 'F05F05F11F02', 'MR1300020001010000123456753');
    addSpecification('MT', 31, 'U04F05A18', 'MT84MALT011000012345MTLCAST001S');
    addSpecification('MU', 30, 'U04F02F02F12F03U03', 'MU17BOMM0101101030300200000MUR');
    addSpecification('NL', 18, 'U04F10', 'NL91ABNA0417164300');
    addSpecification('NO', 15, 'F04F06F01', 'NO9386011117947');
    addSpecification('PK', 24, 'U04A16', 'PK36SCBL0000001123456702');
    addSpecification('PL', 28, 'F08F16', 'PL61109010140000071219812874');
    addSpecification('PS', 29, 'U04A21', 'PS92PALS000000000400123456702');
    addSpecification('PT', 25, 'F04F04F11F02', 'PT50000201231234567890154');
    addSpecification('QA', 29, 'U04A21', 'QA30AAAA123456789012345678901');
    addSpecification('RO', 24, 'U04A16', 'RO49AAAA1B31007593840000');
    addSpecification('RS', 22, 'F03F13F02', 'RS35260005601001611379');
    addSpecification('SA', 24, 'F02A18', 'SA0380000000608010167519');
    addSpecification('SC', 31, 'U04F04F16U03', 'SC18SSCB11010000000000001497USD');
    addSpecification('SE', 24, 'F03F16F01', 'SE4550000000058398257466');
    addSpecification('SI', 19, 'F05F08F02', 'SI56263300012039086');
    addSpecification('SK', 24, 'F04F06F10', 'SK3112000000198742637541');
    addSpecification('SM', 27, 'U01F05F05A12', 'SM86U0322509800000000270100');
    addSpecification('ST', 25, 'F08F11F02', 'ST68000100010051845310112');
    addSpecification('SV', 28, 'U04F20', 'SV62CENR00000000000000700025');
    addSpecification('TL', 23, 'F03F14F02', 'TL380080012345678910157');
    addSpecification('TN', 24, 'F02F03F13F02', 'TN5910006035183598478831');
    addSpecification('TR', 26, 'F05F01A16', 'TR330006100519786457841326');
    addSpecification('UA', 29, 'F25', 'UA511234567890123456789012345');
    addSpecification('VA', 22, 'F18', 'VA59001123000012345678');
    addSpecification('VG', 24, 'U04F16', 'VG96VPVG0000012345678901');
    addSpecification('XK', 20, 'F04F10F02', 'XK051212012345678906');

    // The following countries are not included in the official IBAN registry but use the IBAN specification

    // Angola
    addSpecification('AO', 25, 'F21', 'AO69123456789012345678901');
    // Burkina
    addSpecification('BF', 27, 'F23', 'BF2312345678901234567890123');
    // Burundi
    addSpecification('BI', 16, 'F12', 'BI41123456789012');
    // Benin
    addSpecification('BJ', 28, 'F24', 'BJ39123456789012345678901234');
    // Ivory
    addSpecification('CI', 28, 'U02F22', 'CI70CI1234567890123456789012');
    // Cameron
    addSpecification('CM', 27, 'F23', 'CM9012345678901234567890123');
    // Cape Verde
    addSpecification('CV', 25, 'F21', 'CV30123456789012345678901');
    // Algeria
    addSpecification('DZ', 24, 'F20', 'DZ8612345678901234567890');
    // Iran
    addSpecification('IR', 26, 'F22', 'IR861234568790123456789012');
    // Madagascar
    addSpecification('MG', 27, 'F23', 'MG1812345678901234567890123');
    // Mali
    addSpecification('ML', 28, 'U01F23', 'ML15A12345678901234567890123');
    // Mozambique
    addSpecification('MZ', 25, 'F21', 'MZ25123456789012345678901');
    // Senegal
    addSpecification('SN', 28, 'U01F23', 'SN52A12345678901234567890123');

    // The following are regional and administrative French Republic subdivision IBAN specification (same structure as FR, only country code vary)
    addSpecification('GF', 27, 'F05F05A11F02', 'GF121234512345123456789AB13');
    addSpecification('GP', 27, 'F05F05A11F02', 'GP791234512345123456789AB13');
    addSpecification('MQ', 27, 'F05F05A11F02', 'MQ221234512345123456789AB13');
    addSpecification('RE', 27, 'F05F05A11F02', 'RE131234512345123456789AB13');
    addSpecification('PF', 27, 'F05F05A11F02', 'PF281234512345123456789AB13');
    addSpecification('TF', 27, 'F05F05A11F02', 'TF891234512345123456789AB13');
    addSpecification('YT', 27, 'F05F05A11F02', 'YT021234512345123456789AB13');
    addSpecification('NC', 27, 'F05F05A11F02', 'NC551234512345123456789AB13');
    addSpecification('BL', 27, 'F05F05A11F02', 'BL391234512345123456789AB13');
    addSpecification('MF', 27, 'F05F05A11F02', 'MF551234512345123456789AB13');
    addSpecification('PM', 27, 'F05F05A11F02', 'PM071234512345123456789AB13');
    addSpecification('WF', 27, 'F05F05A11F02', 'WF621234512345123456789AB13');

    return isValid(value) || createError({ path, message: errorMessage });
  });
});

declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType,
  > extends yup.BaseSchema<TType, TContext, TOut> {
    iban(errorMessage: string): StringSchema<TType, TContext>;
  }
}

export default yup;
