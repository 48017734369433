import yup from 'utils/yupIBAN';

export const contactSchema = (hasStep3: boolean, hasVoucher: boolean) => {
  return (hasStep3 && !hasVoucher)
    ? yup
        .object({
          confirmCompany: yup.boolean().oneOf([true], 'form.employee.error.checkbox.required'),
          confirmPrivacy: yup.boolean().oneOf([true], 'form.employee.error.checkbox.required'),
          confirmPayments: yup.boolean().oneOf([true], 'form.employee.error.checkbox.required'),
        })
        .required()
    // : !hasVoucher ?
    //   yup
    //     .object({
    //       confirmCompany: yup.boolean().oneOf([true], 'form.employee.error.checkbox.required'),
    //       confirmPrivacy: yup.boolean().oneOf([true], 'form.employee.error.checkbox.required'),
    //     })
    //     .required()
    : yup
    .object({
      confirmCompany: yup.boolean().oneOf([true], 'form.employee.error.checkbox.required'),
      confirmPrivacy: yup.boolean().oneOf([true], 'form.employee.error.checkbox.required'),
    })
    .required()
};
export default contactSchema;
