import { IconButton, InputAdornment } from '@mui/material';
import { Clear } from '@mui/icons-material';
import { ChangeEvent, ClipboardEventHandler, ReactNode, useCallback, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DefaultTextField } from './DefaultTextField.styles';
import { useIntl } from 'react-intl';
import { defaultTextfieldError } from 'utils/defaultErrorMessage';

export interface InputFieldProps {
  label: string | ReactNode;
  name: string;
  control: any;
  disabled?: boolean;
  isWarningError?: boolean;
  notShowErrorText?: boolean;
  width?: number | string;
  reset?: boolean;
  type?: 'string' | 'integer' | 'float' | 'enum';
  hidden?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  onPaste?: ClipboardEventHandler<HTMLDivElement> | undefined;
  className?: string;
  onFocus?: () => void;
}

const CCPTextField = ({
  label,
  control,
  name,
  reset,
  width,
  type,
  disabled,
  hidden,
  onBlur,
  onFocus,
  className,
  onPaste,
  isWarningError = false,
  notShowErrorText = false,
}: InputFieldProps) => {
  const intl = useIntl();
  const { resetField, setValue, trigger, clearErrors } = useFormContext();
  const handleClear = useCallback(() => {
    resetField(name);
  }, [resetField, name]);
  const handleChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      clearErrors(name);
      if (type === 'integer' && e.currentTarget.value.match(/^[0-9]*$/)) {
        setValue(name, e.currentTarget.value);
        await trigger(name);
      } else if (
        type === 'float' &&
        (e.currentTarget.value.match(/^\d{1,}(\.\d{0,4})?$/) || e.currentTarget.value === '')
      ) {
        setValue(name, e.currentTarget.value);
        await trigger(name);
      } else if (type === 'string' || type === undefined) {
        setValue(name, e.currentTarget.value);

        if (type === 'string') {
          await trigger(name);
        }
      } else {
        setValue(name, e.currentTarget.value.slice(0, -1));
      }
    },
    [trigger, type, name, setValue, clearErrors],
  );

  const properties = useMemo(() => (type ? { onChange: handleChange } : {}), [handleChange, type]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { ref, ...fieldProps } = field;
        return (
          <DefaultTextField
            {...fieldProps}
            className={className}
            onFocus={onFocus}
            onBlur={onBlur ? onBlur : field.onBlur}
            onPaste={onPaste}
            inputRef={field.ref}
            disabled={disabled}
            hidden={hidden}
            {...properties}
            width={width}
            isWarningError={isWarningError}
            error={!!fieldState.error}
            helperText={
              fieldState.error?.message && !notShowErrorText
                ? intl.formatMessage({
                    id: fieldState.error?.message,
                    defaultMessage:
                      defaultTextfieldError[fieldState.error.message] || fieldState.error.message,
                  })
                : undefined
            }
            label={label}
            type='text'
            InputProps={{
              endAdornment: reset ? (
                <InputAdornment position='end'>
                  <IconButton onClick={handleClear} edge='end'>
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ) : (
                <></>
              ),
            }}
          />
        );
      }}
    />
  );
};

export default CCPTextField;
