import { Dialog, styled } from '@mui/material';

const LightboxPopupStyled = styled(Dialog)(({ theme }) => ({
  iframe: {
    borderStyle: 'hidden',
    width: 630,
    height: 540,
    [theme.breakpoints.down(480)]: {
      width: 'auto',
    },
  },

  '& .MuiBackdrop-root': {
    backgroundColor: theme.palette.transparent.blue07,
  },
  '& .MuiPaper-root': {
    boxShadow: `0 10px 10px 0 ${theme.palette.transparent.boxShadowDark01}`,
    '.headerBox': {
      minWidth: 637,
      padding: '36px 48px',
    },
  },
}));

export default LightboxPopupStyled;
