import { Box } from '@mui/material';
import { BaseSetting } from 'features/landing/interfaces';
import React, { useMemo } from 'react';
import { getCDNVideoLink } from 'utils/getCDNVideoLink';
import { ButtonArea, Headline, SingleImage, StudioList, TextArea, Video } from '..';
import { default as theme } from 'theme/theme';
import { useWindowSize } from 'usehooks-ts';

const ColumnsArea: React.FC<BaseSetting & { columns: any[] }> = ({ columns, backgroundColor }) => {
  const windowSize = useWindowSize();

  const isImagesColumns = useMemo(
    () =>
      columns.every(item => item.length === 1 && item[0].blockName === 'signup-landing-page-image'),
    [columns],
  );

  const columnWidth = useMemo(() => {
    if (isImagesColumns) {
      if (windowSize.width <= theme.breakpoints.values.sm) {
        return `calc(${Math.floor(100 / 2)}% - 10px)`;
      }
      return `calc(${Math.floor(100 / columns.length)}% - 20px)`;
    }
    if (!windowSize.width || windowSize.width >= theme.breakpoints.values.lg)
      return `calc(${Math.floor(100 / columns.length)}% - 20px)`;
    if (windowSize.width >= theme.breakpoints.values.md)
      return `calc(${Math.floor(100 / (columns.length < 4 ? columns.length : 4))}% - 20px)`;
    if (windowSize.width >= theme.breakpoints.values.sm) {
      if (columns.length % 4 === 0) return `calc(${Math.floor(100 / 2)}% - 20px)`;
      return `calc(${Math.floor(100 / (columns.length < 3 ? columns.length : 3))}% - 20px)`;
    }
    // if (windowSize.width >= 600)
    //   return `calc(${Math.floor(100 / (columns.length < 2 ? columns.length : 2))}% - 20px)`;
    return '100%';
  }, [columns, windowSize, isImagesColumns]);
  const renderRows = columns.map((mainItem, idx) => (
    <Box
      key={idx}
      sx={{
        width: columnWidth,
        flexDirection: 'column',
        display: 'flex',
        alignItems: windowSize.width >= theme.breakpoints.values.sm ? 'flex-start' : 'center',
      }}>
      {mainItem.map((item: any, index: number) => {
        switch (item.blockName) {
          case 'signup-landing-page-headline': {
            return (
              <Headline
                key={index}
                internal={true}
                placement={item.placement}
                text={item.text}
                backgroundColor={backgroundColor}
              />
            );
          }
          case 'signup-landing-page-video': {
            return (
              <Video
                key={index}
                placement={item.placement}
                path={getCDNVideoLink(item.video)}
                backgroundColor={item.backgroundColor || theme.palette.background.paper}
                type={item.video.type}
                poster={item.video.poster || ''}
                title={item.video.title || ''}
                description={item.video.description || ''}
              />
            );
          }
          case 'signup-landing-page-image': {
            return (
              <SingleImage
                key={index}
                internal
                placement={item.placement}
                imageUrl={item.image.url}
                backgroundColor={backgroundColor}
                imageWidth={item.width}
                linkUrl={item.link?.path || ''}
                linkTarget={item.link?.target || ''}
                onlyImage={isImagesColumns}
              />
            );
          }
          case 'signup-landing-page-text': {
            return (
              <TextArea
                key={index}
                internal
                placement={item.placement}
                text={item.text}
                backgroundColor={backgroundColor}
              />
            );
          }
          case 'signup-landing-page-button': {
            return (
              <ButtonArea
                key={index}
                internal
                placement={item.placement}
                text={item.text}
                link={item.link.path}
                style={item.style}
                target={item.link.target}
                backgroundColor={backgroundColor}
              />
            );
          }
          case 'signup-landing-page-list': {
            return (
              <StudioList
                key={index}
                internal
                placement={item.placement}
                backgroundColor={item.backgroundColor || theme.palette.background.paper}
                items={item.items}
                headline={item.headline}
              />
            );
          }

          default: {
            return null;
          }
        }
      })}
    </Box>
  ));

  return (
    <Box className='main-style' sx={{ backgroundColor }} data-testid='landing-item'>
      <Box className='limit-width'>
        <Box
          display='flex'
          flexDirection='row'
          justifyContent={
            isImagesColumns && windowSize.width <= theme.breakpoints.values.sm
              ? 'flex-start'
              : 'center'
          }
          gap='20px'
          flexWrap='wrap'
          className='columns'>
          {renderRows}
        </Box>
      </Box>
    </Box>
  );
};

export default ColumnsArea;
