export const defaultTextfieldError: { [k: string]: any } = {
  'schema.enter_password.required': 'Password is required',
  'schema.enter_password.invalid': 'Password is invalid',
  'schema.enter_password.min.length': 'Password must be longer',
  'form.employee.error.first_name.required': 'First name is required',
  'form.employee.error.last_name.required': 'Last name is required',
  'form.employee.error.gender.required': 'Gender is required',
  'form.employee.error.date_of_birth.invalid': 'Invalid date of birth format',
  'form.employee.error.date_of_birth.invalid_range': 'Invalid selected date',
  'form.employee.error.date_of_birth.required': 'Date of birth is required',
  'form.employee.error.service_date.invalid': 'Invalid service start date format',
  'form.employee.error.service_date.invalid_range': 'Invalid selected date',
  'form.employee.error.service_date.required': 'Service start date is required',
  'form.employee.error.corporate_email.required': 'Corporate email is required',
  'form.employee.error.corporate_email.invalid': 'Invalid corporate email format',
  'form.employee.error.corporate_email.invalid_domains': 'Email does not belong to company domains',
  'form.employee.error.phone.required': 'Phone number is required',
  'form.employee.error.phone.invalid': 'Invalid phone number format',
  'form.employee.error.phone_prefix.required': 'Prefix must be filled',
  'form.employee.error.iban.required': 'Field IBAN is required',
  'form.employee.error.iban.invalid': 'Field IBAN is incorrect',
  'form.employee.error.bic.required': 'Field bic is required',
  'form.employee.error.accountHolderName.required': 'Field account name is required',
  'form.employee.error.street.required': 'Field street is required',
  'form.employee.error.houseNumber.required': 'Field house number is required',
  'form.employee.error.city.required': 'Field city is required',
  'form.employee.error.zip.required': 'Field zip is required',
  'form.employee.error.country.required': 'Field county is required',
  'form.employee.error.checkbox.required': 'The confirmation is mandatory',
  'employee_management.form.error.customfield.required_custom_fields_are_not_set':
    'Something error',
  'form.employee.error.custom_field.invalid_integer': 'Integer error',
  'form.employee.error.custom_field.invalid_decimal': 'Decimal error',
  'form.employee.error.confirm_email_mismatch': 'Emails do not match',
  'form.employee.error.confirm_email.required': 'This field is required',
};
