import { Box, Button, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from 'store';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { actions } from 'store/company/company.slice';
import { CompanySelectors } from 'store/company/company.selectors';
import React, { useEffect, useMemo } from 'react';
import { IViolationsError } from 'store/company/interfaces';
import { useViolationErrors } from 'utils';
import { Stepper } from '..';
import Spacer from 'components/Spacer';
import ContactInfo from '../FormParts/ContactInfo';
import getContactsSchema from 'utils/getContactsSchema';
import { getDialCodes, getPhonePrefix, getPhoneWithoutPrefix } from 'utils/phoneFunctions';
import StartDatePanel from '../FormParts/StartDatePanel';
import { getMaxStartDate, getMinStartDate } from 'utils/prepareDates';
import { format } from 'date-fns';
import RequiredInfo from '../FormParts/RequiredInfo';

interface StepTwoProps {
  updateStep: (stepNumber: number | string) => void;
  sendRequest: (error: IViolationsError[]) => void;
}

const StepTwo: React.FC<StepTwoProps> = ({ updateStep }) => {
  const intl = useIntl();
  const { stepNumber } = useParams();
  const dispatch = useAppDispatch();
  const violationErrors = useAppSelector(CompanySelectors.getViolationErrors);
  const company = useAppSelector(CompanySelectors.getCompanyData);
  const userInfo = useAppSelector(CompanySelectors.getUserInfo);
  const hasStep3 = company && company.paymentProviders?.length !== 0;
  const countries = useAppSelector(state => state.company.countriesList.data) || [];

  const dialCodes = getDialCodes(countries);
  const countryGlobalField = company?.globalCustomFields?.find(
    globalItem => globalItem.key === 'hansefit_country',
  );
  const minimumDate = getMinStartDate(company);
  const maximumDate = getMaxStartDate(company);

  const schema: any = useMemo(() => {
    const EditEmployeeSchemaShaped = getContactsSchema(company, violationErrors, userInfo, true, {
      min: minimumDate,
      max: maximumDate,
    });

    return EditEmployeeSchemaShaped;
  }, [company, violationErrors, userInfo]);

  const methods = useForm({
    defaultValues: {
      businessEmail: userInfo?.businessEmail || '',
      confirmEmail: userInfo?.confirmEmail || '',
      phoneNumberPrefix: userInfo?.phoneNumber
        ? getPhonePrefix(userInfo.phoneNumber, dialCodes)
        : '+49',
      phoneNumber: userInfo?.phoneNumber
        ? getPhoneWithoutPrefix(userInfo.phoneNumber, dialCodes)
        : '',
      customFields: userInfo?.customFields || {},
      globalCustomFields:
        userInfo?.globalCustomFields ||
        (!!countryGlobalField && countries?.find(item => item.code === 'DE')
          ? { [countryGlobalField.key]: 'DE' }
          : {}),
      startDate: userInfo?.startDate || null,
    },
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  const { handleSubmit, setError, watch, clearErrors } = methods;

  const addViolationErrors = useViolationErrors({
    errors: violationErrors || null,
    scope: 'employee_management',
    watch,
    clearErrors,
  });

  useEffect(() => {
    addViolationErrors.forEach(error => {
      if (error.propertyPath === 'sepa.businessEmail') {
        setError('businessEmail' as any, {
          type: 'violation',
          message: error.message || '',
        });
      } else {
        setError(error.propertyPath as any, {
          type: 'violation',
          message: error.message || '',
        });
      }
    });
  }, [violationErrors, addViolationErrors, setError]);

  const onSubmit = async (data: any) => {
    // data = prepareSaveData(data, 'customFields', company);
    // data = prepareSaveData(data, 'globalCustomFields', company);
    dispatch(
      actions.fillUserInfo({
        ...data,
        phoneNumber:
          data.phoneNumber.trim().length > 0 ? `${data.phoneNumberPrefix}${data.phoneNumber}` : '',
        startDate: format(new Date(data.startDate as Date), 'yyyy-MM-dd'),
      }),
    );
    dispatch(actions.resetViolationErrors(null));
    if (hasStep3) updateStep(Number(stepNumber) + 1);
    else updateStep('summary');
  };

  return (
    <Box className='stepWrapper'>
      <Stepper />

      <Box className='headline'>
        <Typography variant='h5'>
          {intl.formatMessage({
            id: 'step2.description',
            defaultMessage: 'Please, provide your contact data',
          })}
        </Typography>
        <Spacer height={12} />
        <RequiredInfo />
      </Box>
      <Spacer height={24} />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ContactInfo hasVoucher={false} />
          <StartDatePanel
            isFlexibleContract={company?.regularStartDate === 'flexible'}
            maximumDate={maximumDate}
            minimumDate={minimumDate}
          />

          <Box className='footer'>
            <Button onClick={() => updateStep(Number(stepNumber) - 1)} variant='outlined'>
              {intl.formatMessage({
                id: 'step2.back_button',
                defaultMessage: 'Back',
              })}
            </Button>
            <Button type='submit' variant='contained'>
              {hasStep3
                ? intl.formatMessage({
                    id: 'step2.next_button',
                    defaultMessage: 'Next: Payment method',
                  })
                : intl.formatMessage({
                    id: 'step2.continue',
                    defaultMessage: 'Continue: Summary page',
                  })}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default StepTwo;
