import { FormControlLabel, styled } from '@mui/material';

export const LabelCheckboxStyled = styled(FormControlLabel)(({ theme }) => ({
  alignItems: 'flex-start',
  marginLeft: -3,
  '&.error_checkbox .MuiCheckbox-root': {
    color: theme.palette.error.main,
  },
  '.MuiFormControlLabel-label ': {
    paddingTop: '1px',
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 1.38,
    color: theme.palette.neutralDark.contrastText,
  },
  '+ .error_checkbox_helper': {
    color: theme.palette.error.main,
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.29,
    letterSpacing: 'normal',
    marginLeft: 33,
  },

  'p, h2, h1': {
    marginTop: '0 !important',
    marginBottom: '0 !important',
  },
}));
