import { Box, Button, Typography } from '@mui/material';
import { BaseSetting } from 'features/landing/interfaces';
import React from 'react';
import { useNavigate, useParams } from 'react-router';

const ButtonArea: React.FC<
  BaseSetting & {
    text: string;
    style: 'primary' | 'secondary';
    link: string;
    internal?: boolean;
    target?: '_blank' | '_self' | '_top' | '_parent' | 'lightbox';
    handlePopup?: () => void;
  }
> = ({ text, backgroundColor, placement, style, link, internal = false, target, handlePopup }) => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const removeFirstSlash = (url: string) => (url.startsWith('/') ? url.substr(1) : url);
  const goToPage = () => {
    if (link.includes('://')) {
      if (handlePopup && target === 'lightbox') {
        handlePopup();
      } else window.open(link, target || '_blank');
    } else {
      if (!target || target !== '_blank') {
        navigate(
          id && link.includes(id)
            ? `/${removeFirstSlash(link)}`
            : `/${id}/${removeFirstSlash(link)}`,
        );
      } else {
        window.open(`${window.location.origin}/${removeFirstSlash(link)}`, target || '_blank');
      }
    }
  };
  return (
    <Box
      className={internal ? '' : 'main-style'}
      sx={{ backgroundColor }}
      data-testid='landing-item'>
      <Box className={'limit-width'} textAlign={placement}>
        <Button variant={style === 'primary' ? 'contained' : 'outlined'} onClick={goToPage}>
          {text}
        </Button>
      </Box>
    </Box>
  );
};

export default ButtonArea;
