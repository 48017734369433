import { Controller, useFormContext } from 'react-hook-form';
import { ChangeEvent, useCallback } from 'react';
import { DefaultCheckbox } from '../DefaultCheckbox.styles';
import { LabelCheckboxStyled } from './LabelCheckbox.styles';
import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { defaultTextfieldError } from 'utils/defaultErrorMessage';
import { useMemo } from 'react';

interface CCPLabelCheckboxProps {
  label: string;
  name: string;
  control: any;
  htmlLabel?: any;
}

export const CCPLabelCheckbox = (props: CCPLabelCheckboxProps) => {
  const intl = useIntl();
  const { name, label, control, htmlLabel } = props;

  const { setValue, trigger } = useFormContext();
  const handleChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      setValue(name, e.target.checked);
      await trigger(name);
    },
    [name, setValue, trigger],
  );

  const properties = useMemo(() => {
    return { onChange: handleChange };
  }, [handleChange]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { ref, ...fieldProps } = field;
        return (
          <Box display="flex" flexDirection="column">
            <LabelCheckboxStyled
              control={
                <>
                  <DefaultCheckbox
                    {...fieldProps}
                    color={'primary'}
                    {...properties}
                    checked={field.value}
                  />
                  {label === '' && htmlLabel && (
                    <Typography
                      variant="body1"
                      className="MuiFormControlLabel-label"
                      dangerouslySetInnerHTML={{ __html: htmlLabel }}
                    />
                  )}
                </>
              }
              label={label}
              className={fieldState.error ? 'error_checkbox' : ''}
            />
            {fieldState.error && (
              <span className="error_checkbox_helper">
                {fieldState.error?.message
                  ? intl.formatMessage({
                      id: fieldState.error?.message,
                      defaultMessage:
                        defaultTextfieldError[fieldState.error.message] || fieldState.error.message,
                    })
                  : undefined}
              </span>
            )}
          </Box>
        );
      }}
    />
  );
};
