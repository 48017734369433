import { useIntl } from 'react-intl';

export const useGendersList = () => {
  const intl = useIntl();

  return [
    {
      title: intl.formatMessage({ id: 'form.employee.gender.male', defaultMessage: 'Male' }),
      value: 'male',
    },
    {
      title: intl.formatMessage({ id: 'form.employee.gender.female', defaultMessage: 'Female' }),
      value: 'female',
    },
    {
      title: intl.formatMessage({
        id: 'form.employee.gender.divers',
        defaultMessage: 'Other option',
      }),
      value: 'other',
    },
    {
      title: intl.formatMessage({
        id: 'form.employee.gender.no_specification',
        defaultMessage: 'No info',
      }),
      value: 'no-input',
    },
  ];
};
