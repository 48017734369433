import axios, { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { axiosWithConfig } from 'api';
import { UserManagementBaseResponse } from 'api/interfaces';

export const getCountriesList = createAsyncThunk<any, undefined>(
  'company/getCountriesList',
  async (_: undefined, { rejectWithValue }) => {
    const endpoint = process.env.REACT_APP_PIMCORE_ENDPOINT + `api/countries`;


    try {
      const { data } = await axios.get(endpoint);

      return data;
    } catch (e) {
      const error: AxiosError<UserManagementBaseResponse> =
        e as AxiosError<UserManagementBaseResponse>;
      return rejectWithValue(error.response?.data);
    }
  },
);
