import { Typography, Button, DialogTitle, DialogContent, DialogActions, Box } from '@mui/material';
import Spacer from 'components/Spacer';
import { useIntl } from 'react-intl';
import { ModalWrapper } from './Modal.styles';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { FetchingStatus } from 'store/interfaces';
import { actions } from 'store/company/company.slice';

interface DeleteVoucherModalProps {
  handleClose: () => void;
  handleRemove: (voucherData: { voucherCode: string; voucherValue: string }) => void;
  voucherData: { voucherCode: string; voucherValue: string };
}

const DeleteVoucherModal: React.FC<DeleteVoucherModalProps> = ({
  handleClose,
  handleRemove,
  voucherData,
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const voucherState = useAppSelector(state => state.company.vouchers);

  const handleSubmit = () => {
    handleRemove(voucherData);
  };

  useEffect(() => {
    if (voucherState.fetchingStatus === FetchingStatus.FULFILLED && voucherState.data) {
      handleClose();
      dispatch(actions.resetVoucherFetchingStatus(FetchingStatus.IDLE));
    }
  }, [voucherState.fetchingStatus]);

  return (
    <ModalWrapper open={true} onClose={handleClose}>
      <DialogTitle variant="h2">
        <Box>
          {' '}
          <ErrorOutlineIcon fontSize="large" color="error" />
        </Box>
        {intl.formatMessage({
          id: 'step0.remove_voucher.modal.headline',
          defaultMessage: 'Remove voucher',
        })}
      </DialogTitle>

      <DialogContent>
        <Spacer height={24} />
        <Typography variant="body2">
          {intl.formatMessage({
            id: 'step0.remove_voucher.modal.subheadline',
            defaultMessage:
              'Are you sure you want to remove the voucher? You will have to enter the value again.',
          })}
        </Typography>
        <Spacer height={46} />
      </DialogContent>
      <Spacer height={46} />
      <DialogActions>
        <Button
          variant={'outlined'}
          className={'cancelBtn'}
          onClick={handleClose}
          disabled={voucherState.fetchingStatus === FetchingStatus.PENDING}>
          {intl.formatMessage({
            id: 'step0.add_voucher.modal.cta.cancel',
            defaultMessage: 'Cancel',
          })}
        </Button>
        <Button
          variant={'contained'}
          onClick={handleSubmit}
          disabled={voucherState.fetchingStatus === FetchingStatus.PENDING}>
          {intl.formatMessage({
            id: 'step0.remove_voucher.modal.cta.confirm',
            defaultMessage: 'Yes, remove',
          })}
        </Button>
      </DialogActions>
      <Spacer height={36} />
    </ModalWrapper>
  );
};

export default DeleteVoucherModal;
