export const distinctArrayValues = (arr1: string[] | string, arr2: string[] | string) => {
  const resultArr = Array.isArray(arr1) ? [...arr1] : arr1.split(',');
  const secondArr = Array.isArray(arr2) ? [...arr2] : arr2.split(',');
  secondArr.forEach(item => {
    if (!resultArr.includes(item)) {
      resultArr.push(item);
    }
  });
  return resultArr;
};
