import { Typography, Card, Box } from '@mui/material';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useAppDispatch } from 'store';
import { actions } from 'store/company/company.slice';
import Spacer from 'components/Spacer';

const Info: React.FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(actions.resetFullState(''));
  }, [dispatch]);

  return (
    <Box className='page-wrapper'>
      <Card className='card' data-testid='empty-page'>
        <Typography variant='h3' className='title'>
          {intl.formatMessage({
            id: 'main.title',
            defaultMessage: 'Hello and welcome at Hansefit!',
          })}
        </Typography>
        <Spacer height={36} />
        <Typography variant='body1' textAlign='center'>
          {intl.formatMessage({
            id: 'main.description',
            defaultMessage: `You can easily register with Hansefit via your employer's registration page and immediately use our network of over 5000 partners! To do this, speak to the Hansefit representative at your company to receive the link to your registration page.`,
          })}
        </Typography>
      </Card>
    </Box>
  );
};

export default Info;
