export enum RoutePaths {
  DefaultPage = '',
  LandingPage = '/:id/landing',
  Login = '/:id',
  LoginForm = '/:id/form',
  Verification = '/:id/verification/:token',
  Password = '/:id/password',
  Stepper = '/:id/step/:stepNumber',
  Successful = '/:id/successful',
  ProfitRedirect = '/api/endusers/profit',
}
