import { IUserInfo } from 'store/company/interfaces';

export const isHiddenField = (
  fieldName: string,
  hiddenFields: string[] | string,
  userInfo: IUserInfo | null,
) => {
  if (fieldName.includes('customFields')) {
    return (
      hiddenFields.includes(fieldName) &&
      userInfo &&
      userInfo.customFields //&&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // userInfo.customFields[fieldName.substr('customFields'.length + 1)] &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // userInfo.customFields[fieldName.substr('customFields'.length + 1)].toString().trim().length >
      //   0
    );
  }
  if (fieldName.includes('globalCustomFields')) {
    return (
      hiddenFields.includes(fieldName) &&
      userInfo &&
      userInfo.globalCustomFields &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      userInfo.globalCustomFields[fieldName.substr('globalCustomFields'.length + 1)] &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      userInfo.globalCustomFields[fieldName.substr('globalCustomFields'.length + 1)]
        .toString()
        .trim().length > 0
    );
  }
  return (
    hiddenFields.includes(fieldName) &&
    userInfo &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    userInfo[fieldName] &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    userInfo[fieldName].toString().trim().length > 0
  );
};
