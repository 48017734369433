import Spacer from 'components/Spacer';
import { useIntl } from 'react-intl';
import {
  Container,
  ErrorIcon,
  WarningIcon,
  ErrorTitle,
  ErrorDescription,
  TextContainer,
} from './ModalError.styles';

const ModalError: React.FC<{
  errorMessage?: string;
  descriptionErrorMessage?: string;
  isWarningMessage?: boolean;
}> = ({
  errorMessage = 'Error title',
  descriptionErrorMessage = 'Error description',
  isWarningMessage = false,
}) => {
  const intl = useIntl();
  return (
    <>
      <Container isWarningMessage={isWarningMessage}>
        {isWarningMessage ? <WarningIcon /> : <ErrorIcon />}
        <TextContainer>
          <ErrorTitle>
            {errorMessage ||
              intl.formatMessage({ id: 'Error title', defaultMessage: 'Error title' })}
          </ErrorTitle>
          {descriptionErrorMessage && (
            <ErrorDescription>
              {descriptionErrorMessage ||
                intl.formatMessage({
                  id: 'error.description',
                  defaultMessage: 'Error description',
                })}
            </ErrorDescription>
          )}
        </TextContainer>
      </Container>
      <Spacer height={42} />
    </>
  );
};

export default ModalError;
