import { Box, Button, Pagination, Stack, Typography } from '@mui/material';
import Spacer from 'components/Spacer';
import { BaseSetting } from 'features/landing/interfaces';
import { useWindowSize } from 'hooks/useWindowSize';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { default as theme } from 'theme/theme';

const ImageCollection: React.FC<BaseSetting & { type: 'static' | 'slider'; images: any[] }> = ({
  type,
  backgroundColor,
  placement,
  images,
}) => {
  const [page, setPage] = useState<number>(0);
  const windowSize = useWindowSize();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const sliderRef = useRef<HTMLDivElement>(null);

  const isMobile = useMemo(() => windowSize?.width && windowSize?.width < 768, [windowSize]);

  const recordsPerPage = useMemo(
    () =>
      isMobile ? 1 : type === 'static' ? images.length : images.length > 5 ? 5 : images.length,
    [isMobile, type, images],
  );

  const pagesCount = useMemo(
    () => Math.ceil(images.length / recordsPerPage),
    [images, recordsPerPage],
  );

  const lastPageRecordsCount = useMemo(() => images.length % 5, [images]);

  const offset = useMemo(() => {
    if (
      lastPageRecordsCount === 0 ||
      lastPageRecordsCount === 4 ||
      (lastPageRecordsCount < 4 && page < pagesCount - 1)
    ) {
      return page * recordsPerPage;
    } else {
      return page === 0 ? 0 : page * recordsPerPage - 1;
    }
  }, [page, recordsPerPage, lastPageRecordsCount, pagesCount]);

  const limit = useMemo(() => {
    if (
      lastPageRecordsCount === 0 ||
      (lastPageRecordsCount === 4 && page < pagesCount - 1) ||
      (lastPageRecordsCount < 4 && page < pagesCount - 2)
    ) {
      return page * recordsPerPage + recordsPerPage;
    } else if (
      lastPageRecordsCount === 4 ||
      (lastPageRecordsCount < 4 && page === pagesCount - 2)
    ) {
      return page * recordsPerPage + recordsPerPage - 1;
    } else {
      return page * recordsPerPage + lastPageRecordsCount;
    }
  }, [recordsPerPage, page, pagesCount, lastPageRecordsCount]);

  useEffect(() => {
    if (page > pagesCount) {
      setPage(0);
    }
  }, [isMobile, page, pagesCount]);

  const removeFirstSlash = (url: string) => (url.startsWith('/') ? url.substr(1) : url);

  const goToPage = useCallback(
    (link: { path?: string; target?: string } | undefined) => {
      if (link) {
        if (link.path) {
          if (link.path.includes('://')) {
            window.open(link.path, link.target || '_blank');
          } else {
            if (!link.target || link.target !== '_blank') {
              navigate(
                id && link.path.includes(id)
                  ? `/${removeFirstSlash(link.path)}`
                  : `/${id}/${removeFirstSlash(link.path)}`,
              );
            } else {
              window.open(
                `${window.location.origin}/${removeFirstSlash(link.path)}`,
                link.target || '_blank',
              );
            }
          }
        }
      }
    },
    [removeFirstSlash],
  );
  const renderOneImage = useCallback(
    (
      idx: number,
      width: string,
      url: string,
      link:
        | {
            path?: string;
            target?: string;
          }
        | undefined,
    ) => (
      <Box key={idx} minWidth={width} maxWidth={width}>
        <Box className='landing-gallery-images'>
          <img
            src={url}
            style={{ maxWidth: '100%', cursor: link?.path ? 'pointer' : 'default' }}
            onClick={() => goToPage(link)}
          />
        </Box>
      </Box>
    ),
    [],
  );

  const renderStaticRows = useMemo(
    () =>
      (type === 'static' ? images : images?.slice(offset, limit))?.map((item, idx) => {
        if (isMobile) {
          return renderOneImage(idx, '100%', item.url, item.link);
        }
        if (type === 'slider') {
          const countOfElements = limit - offset;
          const imageWidth = `calc(${Math.floor(100 / countOfElements)}% - 20px)`;
          return renderOneImage(idx, imageWidth, item.url, item.link);
        }

        //default width for row with 5 elements
        const width = 'calc(20% - 20px)';
        //default width for row with 4 elements or for penultimate page
        const imgWidthOnPrevLastPage = 'calc(25% - 20px)';

        if (
          lastPageRecordsCount === 0 ||
          images.length - idx > lastPageRecordsCount + 5 ||
          (lastPageRecordsCount === 4 && images.length - idx > lastPageRecordsCount)
        ) {
          //rendering just elements with default width for row with 5 elements
          return renderOneImage(idx, width, item.url, item.link);
        } else if (lastPageRecordsCount !== 0) {
          if (images.length <= 5) {
            //rendering row elements if elements count less than 5
            return renderOneImage(idx, `calc(${100 / images.length}% - 20px)`, item.url, item.link);
          }
          if (lastPageRecordsCount === 4 || images.length - idx > lastPageRecordsCount + 1) {
            //rendering last row with 4 records or penultimate row with 4 elements
            return renderOneImage(idx, imgWidthOnPrevLastPage, item.url, item.link);
          } else {
            //rendering last row with dynamic size
            return renderOneImage(
              idx,
              `calc(${100 / (lastPageRecordsCount + 1)}% - 20px)`,
              item.url,
              item.link,
            );
          }
        }
      }),
    [type, images, offset, limit, renderOneImage, lastPageRecordsCount],
  );

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value - 1);
    if (sliderRef.current) sliderRef.current.style.transform = sliderTransform(value - 1);
  };

  const [xDown, setxDown] = useState<number | null>(null);
  //let xDown: number | null = null;

  const getTouches = (evt: any) =>
    evt.touches || // browser API
    evt.originalEvent.touches;

  const handleTouchStart = (evt: any) => {
    const firstTouch = getTouches(evt)[0];
    //xDown = firstTouch.clientX;
    setxDown(firstTouch.clientX);
  };
  const handleTouchEnd = useCallback(
    (evt: any) => {
      console.log('page: ', page);
      console.log('offset: ', offset);

      if (!xDown) {
        return;
      }

      const xDiff = xDown - evt.changedTouches[0].clientX;
      if (xDiff !== 0 && sliderRef.current) {
        if ((xDiff > 0 && page === pagesCount - 1) || (xDiff < 0 && page === 0)) {
          return;
        }
        handleChange(evt, page + (xDiff > 0 ? 2 : 0));
      }

      //xDown = null;
      setxDown(null);
    },
    [page, pagesCount, xDown],
  );

  const handleTouchMove = (evt: any) => {
    if (!xDown) {
      return;
    }

    const xDiff = xDown - evt.touches[0].clientX;
    if (xDiff !== 0 && sliderRef.current) {
      if ((xDiff > 0 && page === pagesCount - 1) || (xDiff < 0 && page === 0)) {
        return;
      }
      sliderRef.current.style.transform = `translateX(calc(${
        page === 0 ? '0px' : '-100%'
      } - ${xDiff}px))`;
    }
  };

  const isMobileSlider = useMemo(
    () => isMobile && type === 'slider' && pagesCount > 1,
    [isMobile, type, pagesCount],
  );

  useEffect(() => {
    if (isMobileSlider) {
      if (sliderRef.current) {
        sliderRef.current.addEventListener('touchstart', handleTouchStart, false);
        sliderRef.current.addEventListener('touchend', handleTouchEnd, false);
        sliderRef.current.addEventListener('touchmove', handleTouchMove, false);
        sliderRef.current.style.transform = sliderTransform(page);
      }
    }
    return () => {
      if (sliderRef.current) {
        sliderRef.current.removeEventListener('touchstart', handleTouchStart);
        sliderRef.current.removeEventListener('touchend', handleTouchEnd);
        sliderRef.current.removeEventListener('touchmove', handleTouchMove);
      }
    };
  }, [isMobileSlider, handleTouchStart, handleTouchMove, handleTouchEnd, sliderRef]);

  const sliderTransform = useCallback(
    (pageNumber: number) =>
      isMobileSlider ? `${pageNumber > 0 ? 'translateX(-100%)' : 'none'}` : 'none',
    [isMobileSlider],
  );

  if (sliderRef.current) {
    sliderRef.current.style.transform = sliderTransform(page);
  }

  const renderRows = useMemo(() => {
    const sliderOffset = isMobileSlider ? offset - (page === pagesCount - 1 ? 0 : 1) : offset;
    const sliderLimit = isMobileSlider
      ? offset + 2 < images.length
        ? offset + 2
        : images.length
      : limit;
    return images
      ?.slice(
        sliderOffset >= 0 ? sliderOffset : offset,
        sliderLimit !== limit ? sliderLimit : limit,
      )
      .map((item, idx) => renderOneImage(idx, '100%', item.url, item.link));
  }, [images, offset, limit, renderOneImage, isMobileSlider, page, pagesCount]);

  return (
    <Box className='main-style' sx={{ backgroundColor }} data-testid='landing-item'>
      <Box className='limit-width' textAlign={placement} overflow='hidden'>
        {!isMobile || type === 'static' ? (
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            gap='24px'
            flexWrap='wrap'>
            {renderStaticRows}
          </Box>
        ) : (
          <Box
            id='test'
            ref={sliderRef}
            sx={{
              transform: sliderTransform(page),
              touchAction: 'none',
            }}
            display='flex'
            flexDirection={isMobileSlider ? 'row' : 'column'}
            gap={isMobileSlider ? '0' : '24px'}
            flexWrap={isMobileSlider ? 'nowrap' : 'wrap'}>
            {renderRows}
          </Box>
        )}
        {type === 'slider' && pagesCount > 1 && (
          <>
            <Spacer height={36} />
            {isMobile ? (
              <Stack
                justifyContent='space-between'
                flexDirection='row'
                color='white'
                alignItems='center'>
                <Button
                  variant='outlined'
                  className='mobile-paging'
                  disabled={page === 0}
                  onClick={evt => handleChange(evt, page)}>
                  <KeyboardArrowLeftIcon htmlColor={theme.palette.neutralDark.main} />
                </Button>
                <Typography variant='h5'>{`${page + 1} from ${images.length}`}</Typography>
                <Button
                  variant='outlined'
                  className='mobile-paging'
                  disabled={page === pagesCount - 1}
                  onClick={evt => handleChange(evt, page + 2)}>
                  <KeyboardArrowRightIcon htmlColor={theme.palette.neutralDark.main} />
                </Button>
              </Stack>
            ) : (
              <Stack justifyContent='flex-end' flexDirection='row' gap={3} color='white'>
                <Pagination
                  page={page + 1}
                  count={pagesCount}
                  onChange={handleChange}
                  siblingCount={isMobile ? 0 : 5}
                  style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', color: 'white' }}
                />
              </Stack>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default ImageCollection;
