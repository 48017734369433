import React from 'react';
import { Box } from '@mui/material';

import LightboxPopupStyled from './LightboxPopup.styles';

interface LighboxProps {
  handleClose: () => void;
  path: string;
}

const LightboxPopup: React.FC<LighboxProps> = ({ handleClose, path }) => {
  const onClose = () => {
    handleClose();
  };
  return (
    <>
      <LightboxPopupStyled open={true} onClose={onClose}>
        <Box className='headerBox'>
          <iframe title='til' src={path} />
        </Box>
      </LightboxPopupStyled>
    </>
  );
};

export default LightboxPopup;
