import { createAsyncThunk } from '@reduxjs/toolkit';
import { VoucherVerificationResponse } from 'api/interfaces';
import axios, { AxiosError } from 'axios';
import { IVoucher } from 'store/company/interfaces';
import showToast from 'utils/showToast';

export const verifyVoucher = createAsyncThunk<any, any>(
  'company/verifyVoucher',
  async (body, { rejectWithValue }) => {
    const endpoint = `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/endusers/profit/verifyVoucher`;

    try {
      const resp = await axios.post(endpoint, body);
      return resp.data;
    } catch (err: any) {
      if (
        Number(err.response?.status) === 400 &&
        (err.response.data.valid.filter(
          (item: IVoucher) => item.voucher.code === body.newVoucher[0],
        ).length > 0 ||
          (err.response.data.valid.length === 0 && err.response.data.invalid.length === 1))
      ) {
        return err.response.data;
      }
      console.log(err);
      const error: AxiosError<VoucherVerificationResponse> =
        err as AxiosError<VoucherVerificationResponse>;
      if (Number(error.response?.status) === 500) {
        showToast('error', {
          id: 'toast.signup.creation_user.error_500',
          defaultMessage:
            'Please try again later. If it does not work then, reach out to your Hansefit representative.',
        });
      } else if (Number(error.response?.status) === 422) {
        showToast(
          'error',
          (error.response?.data as any)[0]
            ? (error.response?.data as any)[0].message
            : {
                id: 'toast.voucher_verification.error',
                defaultMessage: 'Something went wrong',
              },
        );
      }
      return rejectWithValue(error.response?.data);
    }
  },
);
