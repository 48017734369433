import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { ICompaniesState } from 'store/company/interfaces';
import { FetchingStatus } from 'store/interfaces';
import { checkPassword } from './checkPassword';

export const checkPasswordExtraReducer = (builder: ActionReducerMapBuilder<ICompaniesState>) => {
  builder.addCase(checkPassword.pending, (state, action) => {
    state.fetchingStatus = FetchingStatus.PENDING;
    state.error = null;
    state.errorViolations = null;
  });
  builder.addCase(checkPassword.fulfilled, (state, action) => {
    if (action.payload) {
      state.password = action.payload;
    } else {
      state.error = { message: 'Password is invalid' };
    }
    state.fetchingStatus = FetchingStatus.FULFILLED;
  });

  builder.addCase(checkPassword.rejected, (state, action) => {
    state.error = action.error;
    state.fetchingStatus = FetchingStatus.REJECTED;
  });
};
