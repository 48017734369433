import { getCountriesList } from './async/getCountriesList/getCountriesList';
import { getCompanyInfo } from './async/getCompanyInfo/getCompanyInfo';
import { checkPassword } from './async/checkPassword/checkPassword';
import { sendUserData } from './async/sendUserData/sendUserData';
import { getUserInfo } from './async/getUserInfo/getUserInfo';
import { verifyVoucher } from './async/verifyVoucher/verifyVoucher';

export const CompanyActions = {
  getCountriesList,
  getCompanyInfo,
  checkPassword,
  sendUserData,
  getUserInfo,
  verifyVoucher,
};
