import { Box, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { formatPrice } from 'utils/formatPrice';

interface CostDetailsProps {
  bodyText: string;
  subnote: string;
  value: string | undefined;
  containerStyle: string;
  divider?: boolean;
}

const CostDetails = ({
  bodyText,
  subnote,
  value,
  containerStyle,
  divider = true,
}: CostDetailsProps) => {
  const price = value ? (isNaN(parseInt(value)) ? value : formatPrice(value)) : 0;

  return (
    <Box
      className={
        divider ? `divider-${containerStyle} sectionBox__costs-item` : `sectionBox__costs-item `
      }>
      <Box className='sectionBox__costs-item__content'>
        <Typography variant='body2' className={containerStyle === 'highlight' ? 'highlighted' : ''}>
          {bodyText}
        </Typography>
        <Typography variant='overline'>{subnote}</Typography>
      </Box>
      <Box className={`sectionBox__costs-item__content input ` + containerStyle}>
        {price || value}
        {containerStyle === 'error' && <ErrorIcon fontSize='small' color='error' />}
      </Box>
    </Box>
  );
};

export default CostDetails;
