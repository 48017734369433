import { Route, Routes, Navigate } from 'react-router-dom';

import { MainLayout, IntlProviderLayout } from 'components';

import { routes } from './routeConfig';

export const RoutesList = () => {
  const renderRoutesList = routes.map(route => {
    const {
      props: { path, component: Component, ...rest },
      routeType,
    } = route;

    return (
      <Route
        key={path}
        path={path}
        element={
          <IntlProviderLayout>
            <MainLayout>
              <Component {...rest} />
            </MainLayout>
          </IntlProviderLayout>
        }
      />
    );
  });

  return (
    <Routes>
      {renderRoutesList}
      <Route path='*' element={<Navigate to='/' replace />} />
    </Routes>
  );
};
