import { Auth } from 'aws-amplify';
import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';
import { AxiosConfig } from '../interfaces';

export const axiosWithConfig = async ({
  withAuthentication = true,
  headers = {},
}: AxiosConfig = {}): Promise<AxiosInstance> => {
  const customHeaders: AxiosRequestHeaders = { ...headers };

  if (withAuthentication) {
    const currentSession = await Auth.currentSession();
    customHeaders['Authorization'] = 'Bearer ' + currentSession.getIdToken().getJwtToken();
  }

  return axios.create({
    timeout: 30000,
    headers: customHeaders,
  });
};
