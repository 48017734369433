export const rangeValidation = (
  val: Date | undefined | null,
  limits: { min: Date; max: Date } | undefined,
) => {
  if (limits && val && typeof val === 'object') {
    const selectedDate = val.getTime();
    return selectedDate >= limits.min.getTime() && selectedDate <= limits.max.getTime();
  }
  return true;
};
