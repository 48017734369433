import { Box, Button, List, ListItem, ListItemText, Typography } from '@mui/material';
import { BaseSetting } from 'features/landing/interfaces';
import React from 'react';
import { useNavigate, useParams } from 'react-router';

const StudioList: React.FC<
  BaseSetting & { headline?: string; items: string[]; internal?: boolean }
> = ({ internal = false, backgroundColor, headline, items, placement }) => {
  return (
    <Box
      className={internal ? '' : 'main-style'}
      sx={{ backgroundColor }}
      data-testid='landing-item'>
      <Box className={'limit-width'}>
        <Typography variant='h4' textAlign='center' marginTop={'42px'}>
          {headline}
        </Typography>
        <List sx={{ listStyle: 'disc', paddingInlineStart: '40px' }}>
          {items.map((item, index) => (
            <ListItem key={index} sx={{ display: 'list-item', padding: '4px 16px' }}>
              <Typography
                className='list-item'
                dangerouslySetInnerHTML={{
                  __html: item,
                }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default StudioList;
