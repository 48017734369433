import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { DefaultTextField } from '../TextField/DefaultTextField.styles';
import { ReactElement } from 'react';
import { defaultTextfieldError } from 'utils/defaultErrorMessage';

export interface InputFieldProps {
  label: string;
  name: string;
  control: any;
  children: ReactElement<any, any>[];
  width?: number | string;
  disabled?: boolean;
  hidden?: boolean;
}

const CCPSelect = ({
  label,
  control,
  name,
  children,
  width,
  disabled,
  hidden,
}: InputFieldProps) => {
  const intl = useIntl();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <DefaultTextField
          {...field}
          width={width}
          disabled={disabled}
          hidden={hidden}
          error={!!fieldState.error}
          helperText={
            fieldState.error?.message
              ? intl.formatMessage({
                  id: fieldState.error?.message,
                  defaultMessage:
                    defaultTextfieldError[fieldState.error.message] || fieldState.error.message,
                })
              : undefined
          }
          label={label}
          select
          SelectProps={{
            IconComponent: () => (
              <ArrowDropDownIcon
                className={'MuiSvgIcon-root MuiSelect-icon MuiSelect-iconOutlined'}
              />
            ),
          }}>
          {children}
        </DefaultTextField>
      )}
    />
  );
};

export default CCPSelect;
