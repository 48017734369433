import { useEffect } from 'react';
import { useAppDispatch } from 'store';
import { actions } from 'store/company/company.slice';

const ProfitRedirect = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(actions.resetFullState('profit'));
    window.location.href = window.location.href.replace(
      `${window.location.origin}/`,
      process.env.REACT_APP_PIMCORE_ENDPOINT || '',
    );
  }, []);

  return null;
};

export default ProfitRedirect;
