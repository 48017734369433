import { Box, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import Spacer from 'components/Spacer';
import { ActionFeedbackWrapper } from './ActionFeedback.styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface ActionFeedbackProps {
  headline: any;
  body?: any;
  type: 'success' | 'error' | 'warning';
}

const ActionFeedback: React.FC<ActionFeedbackProps> = ({ headline, body, type }) => {
  return (
    <ActionFeedbackWrapper>
      <Box className={type}>
        <Box className="container">
          <Box>
            {type === 'success' && <CheckCircleIcon fontSize="small" color="success" />}
            {type === 'error' && <ErrorIcon fontSize="small" color="error" />}
            {type === 'warning' && <ErrorIcon fontSize="small" color="warning" />}
          </Box>
          <Box>
            <Typography variant={'subtitle2'}>{headline}</Typography>
            <Spacer height={4} />
            <Typography variant={'body2'}>{body}</Typography>
          </Box>
        </Box>
      </Box>
    </ActionFeedbackWrapper>
  );
};

export default ActionFeedback;
