import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useIntl } from 'react-intl';
import Spacer from 'components/Spacer';
import StartDatePicker from './StartDatePicker';
import { useFormContext } from 'react-hook-form';
import { useAppSelector } from 'store';
import { CompanySelectors } from 'store/company/company.selectors';
import { distinctArrayValues } from 'utils/distinctArrayValues';
import { isDisabledField } from 'utils/disablePrefilledFields';
import { isHiddenField } from 'utils/hidePrefilledFields';
import { useIsDirectPay } from 'utils/useIsDirectPay';
import { default as theme } from 'theme/theme';
import { useWindowSize } from 'usehooks-ts';
import { useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { formatPrice } from 'utils/formatPrice';
import GridRow from '../StepSummary/gridRow';
import { formatDate } from 'utils/formatDate';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useLocation, useParams } from 'react-router-dom';
import ModalError from 'components/ModalError';
import {
  Container,
  ErrorDescription,
  TextContainer,
} from 'components/ModalError/ModalError.styles';

interface StartDatePanelProps {
  isFlexibleContract: boolean;
  minimumDate?: Date;
  maximumDate?: Date;
  isSummary?: boolean;
}

const StartDatePanel: React.FC<StartDatePanelProps> = ({
  isFlexibleContract,
  minimumDate,
  maximumDate,
  isSummary,
}) => {
  const { formatMessage } = useIntl();
  const windowSize = useWindowSize();
  const { id } = useParams();
  const { pathname } = useLocation();
  const isMobile = useMemo(() => windowSize.width < theme.breakpoints.values.sm, [windowSize]);
  const currentLanguage = useCurrentLanguage();
  const [accordionExpanded, setAccordionExpanded] = useState(
    !isMobile || pathname === `/${id}/step/1`,
  );

  useEffect(() => {
    if (!accordionExpanded && !isMobile) {
      setAccordionExpanded(true);
    }
  }, [windowSize, isMobile]);

  const company = useAppSelector(CompanySelectors.getCompanyData);
  const userInfo = useAppSelector(CompanySelectors.getUserInfo);
  const disabledFields = useAppSelector(CompanySelectors.getDisabledFields) || [];
  const formHiddenFields = useAppSelector(CompanySelectors.getHiddenFields) || [];
  const fullHiddenFields = useAppSelector(CompanySelectors.getFullHiddenFields) || [];
  const hiddenFields = distinctArrayValues(formHiddenFields, fullHiddenFields);
  const isDirectPay = useIsDirectPay();

  const { control, getValues, watch } = useFormContext();

  const selectedStartDate = watch('startDate');
  const selectedFeesDate = useMemo(
    () => format(new Date(selectedStartDate), 'MM-yyyy'),
    [selectedStartDate],
  );

  const hasMinTI = useMemo(() => {
    let minValue = false;
    if (company?.fees) {
      const selectedTIFee = company?.fees[selectedFeesDate]?.trainingInstructionFee;
      //when training instruction fee is empty for selected month it is a min TI fee
      if (!selectedTIFee) {
        return minValue;
      }
      Object.keys(company.fees).forEach(key => {
        let keyDate = new Date(key);
        if (isNaN(keyDate.getTime())) {
          keyDate = new Date(`${key.split('-')[1]}-${key.split('-')[0]}-01`);
        }
        if (
          !minValue &&
          keyDate < new Date(selectedStartDate) &&
          (!company.fees[key].trainingInstructionFee ||
            company.fees[key].trainingInstructionFee < selectedTIFee)
        ) {
          minValue = true;
        }
      });
    }
    return minValue;
  }, [company?.fees, selectedStartDate]);

  const showMonthlyFee = useMemo(() => {
    if (isDirectPay) {
      return true;
    }
    if (company && (company.fees[selectedFeesDate]?.monthlyFee || company.euShare)) {
      return true;
    }
    return false;
  }, [isDirectPay, company, selectedFeesDate]);

  const monthlyFee = useMemo(() => {
    if (!company) {
      return `000,00 €`;
    }

    if (isDirectPay) {
      return formatPrice(company.fees[selectedFeesDate].monthlyFee);
    } else {
      return company.euShare
        ? formatPrice(company.euShare)
        : formatPrice(company.fees[selectedFeesDate].monthlyFee);
    }
  }, [isDirectPay, company, selectedFeesDate]);

  const serviceFee = useMemo(
    () => company?.fees[selectedFeesDate]?.serviceFee || 0,
    [company, selectedFeesDate],
  );
  const trainingInstructionFee = useMemo(
    () => company?.fees[selectedFeesDate]?.trainingInstructionFee || 0,
    [company, selectedFeesDate],
  );

  return (
    <>
      <Accordion
        className={`start-date-info ${!accordionExpanded ? 'start-date-info--summary' : ''}`}
        defaultExpanded
        expanded={accordionExpanded}
        onChange={() => setAccordionExpanded(!accordionExpanded)}>
        <AccordionSummary
          className={`start-date-info--title ${isSummary ? 'start-date-info--title__summary' : ''}`}
          expandIcon={isMobile ? <ExpandMoreIcon /> : null}>
          <Typography variant='body1' fontWeight='600'>
            {formatMessage({
              id: 'stepper.subsection.start_date.title',
              defaultMessage: 'Costs for your membership',
            })}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className='start-date-info--details'>
          <Spacer height='16px' />
          <Box className='start-date-info--helper'>
            <Typography variant='caption'>
              {formatMessage({
                id: 'stepper.subsection.start_date.helper',
                defaultMessage:
                  'Your employer covers part of the costs for you – so you only pay a small fee for your Hansefit membership!',
              })}
            </Typography>
          </Box>
          <Spacer height='56px' />
          <StartDatePicker
            control={control}
            disabled={isDisabledField('startDate', disabledFields, userInfo) || isSummary}
            hidden={isHiddenField('startDate', hiddenFields, userInfo)}
            name='startDate'
            isMonthPicker={!isFlexibleContract}
            minimumDate={minimumDate}
            maximumDate={maximumDate}
            getValues={getValues}
            label={`${formatMessage({
              id: 'form.employee.field.service_date.label',
              defaultMessage: 'Service start date',
            })}*`}
          />
          <Spacer height='6px' />
          {hasMinTI && (
            <Container isWarningMessage className='discount-warning'>
              <TextContainer>
                <ErrorDescription>
                  {formatMessage({
                    id: 'stepper.subsection.start_date.discount.description',
                    defaultMessage: 'Choose an earlier start date to save on the registration fee!',
                  })}
                </ErrorDescription>
              </TextContainer>
            </Container>
          )}
          <Spacer height='32px' />
          {selectedStartDate && trainingInstructionFee + serviceFee !== 0 && (
            <Box>
              <Stack flexDirection='row' justifyContent='space-between'>
                <Typography variant='body2' fontWeight='600'>
                  {formatMessage({
                    id: 'stepper.subsection.start_date.one_time_fee.title',
                    defaultMessage: 'One time costs',
                  })}
                  :
                </Typography>
                <Typography variant='body2' fontWeight='600'>
                  {formatPrice(trainingInstructionFee + serviceFee)}
                </Typography>
              </Stack>
              {trainingInstructionFee > 0 && (
                <>
                  <Spacer height='8px' />
                  <Stack flexDirection='row' justifyContent='space-between'>
                    <Typography variant='caption'>
                      {formatMessage({
                        id: 'stepper.subsection.start_date.only_training_fee.description',
                        defaultMessage: 'Training instruction fee',
                      })}
                      :
                    </Typography>
                    <Typography variant='caption'>{formatPrice(trainingInstructionFee)}</Typography>
                  </Stack>
                </>
              )}
              {serviceFee > 0 && (
                <>
                  <Spacer height='8px' />
                  <Stack flexDirection='row' justifyContent='space-between'>
                    <Typography variant='caption'>
                      {formatMessage({
                        id: 'stepper.subsection.start_date.only_service_fee.description',
                        defaultMessage: 'Service Fee',
                      })}
                      :
                    </Typography>
                    <Typography variant='caption'>{formatPrice(serviceFee)}</Typography>
                  </Stack>
                </>
              )}
              <Spacer height='56px' />
            </Box>
          )}
          {showMonthlyFee && (
            <Box>
              <Stack flexDirection='row' justifyContent='space-between'>
                <Typography variant='body2' fontWeight='600'>
                  {formatMessage({
                    id: 'stepper.subsection.start_date.monthly_fee.title',
                    defaultMessage: 'Monthly fee',
                  })}
                  :
                </Typography>
                {selectedStartDate && company ? (
                  <Typography variant='body2' fontWeight='600'>
                    {monthlyFee}
                  </Typography>
                ) : (
                  <Typography variant='body2' fontSize='14px' color={theme.palette.error.main}>
                    {formatMessage({
                      id: 'stepper.subsection.start_date.monthly_fee.error',
                      defaultMessage: 'Select start date',
                    })}
                  </Typography>
                )}
              </Stack>
              <Spacer height='8px' />
              {selectedStartDate && company && (
                <Typography variant='caption'>
                  {formatMessage({
                    id: 'stepper.subsection.start_date.monthly_fee.description',
                    defaultMessage: 'Your monthly contribution for your Hansefit membership',
                  })}
                </Typography>
              )}
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
      {!accordionExpanded && (
        <Grid container rowGap='8px' className='start-date-info--collapsed'>
          <GridRow
            title={formatMessage({
              id: 'form.employee.field.service_date.label',
              defaultMessage: 'Service start date',
            })}
            value={formatDate(getValues('startDate'), currentLanguage)}
          />
          {trainingInstructionFee + serviceFee !== 0 && (
            <GridRow
              title={formatMessage({
                id: 'stepper.subsection.start_date.one_time_fee.title',
                defaultMessage: 'One time costs',
              })}
              value={company ? formatPrice(trainingInstructionFee + serviceFee) : `000,00 €`}
            />
          )}
          {showMonthlyFee && (
            <GridRow
              title={formatMessage({
                id: 'stepper.subsection.start_date.monthly_fee.title',
                defaultMessage: 'Monthly fee',
              })}
              value={monthlyFee}
            />
          )}
        </Grid>
      )}
    </>
  );
};

export default StartDatePanel;
