import { Box, Stack } from '@mui/material';
import { FooterLogo } from 'assets/icons';
import { useIntl } from 'react-intl';

const LandingFooter: React.FC<{
  signupPagePhone: string | undefined;
  signupPageEmail: string | undefined;
}> = ({ signupPagePhone, signupPageEmail }) => {
  const { formatMessage } = useIntl();

  return (
    <Box className='landing-footer'>
      <Box className='landing-footer-left'>
        <Box>
          <FooterLogo />
        </Box>
        <Stack direction='row' spacing={2} className='landing-footer-left-links'>
          <a
            className='footer-link'
            href={formatMessage({
              id: 'footer.links.contact.imprint.link',
              defaultMessage: 'https://hansefit.de/impressum',
            })}
            rel='noreferrer'
            target='_blank'>
            {formatMessage({ id: 'footer.links.contact.imprint', defaultMessage: 'Imprint' })}
          </a>
          <a
            className='footer-link'
            href={formatMessage({
              id: 'footer.links.contact.privacy.link',
              defaultMessage: 'https://hansefit.de/datenschutz',
            })}
            rel='noreferrer'
            target='_blank'>
            {formatMessage({ id: 'footer.links.contact.privacy', defaultMessage: 'Privacy' })}
          </a>
          <a
            className='footer-link'
            href={formatMessage({
              id: 'footer.links.contact.terms.link',
              defaultMessage: 'https://hansefit.de/nutzungsbedingungen',
            })}
            rel='noreferrer'
            target='_blank'>
            {formatMessage({
              id: 'footer.links.contact.terms',
              defaultMessage: 'Terms of Use',
            })}
          </a>
        </Stack>
      </Box>
      <Box className='contacts-row'>
        {signupPageEmail && (
          <a href={`mailto:${signupPageEmail}`} className='link'>
            {signupPageEmail}
          </a>
        )}
        {signupPagePhone && (
          <a href={`tel:${signupPagePhone}`} className='link'>
            {signupPagePhone}
          </a>
        )}
      </Box>
    </Box>
  );
};
export default LandingFooter;