import { Box, Typography } from '@mui/material';
import { BaseSetting } from 'features/landing/interfaces';
import React from 'react';
import { default as theme } from 'theme/theme';

const Headline: React.FC<
  BaseSetting & {
    text: string;
    internal?: boolean;
  }
> = ({ text, backgroundColor, placement, internal = false }) => (
  <Box
    className={internal ? '' : 'main-style'}
    data-testid='landing-item'
    sx={internal ? {} : { backgroundColor }}>
    <Box className='limit-width'>
      <Typography
        variant='h1'
        textAlign={placement}
        marginTop='42px'
        color={internal ? theme.palette.primary.contrastText : 'inherit'}>
        {text}
      </Typography>
    </Box>
  </Box>
);

export default Headline;
