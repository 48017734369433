import { Box, styled } from '@mui/material';

const AccordionBlockWrapper = styled(Box)<{ background: string }>(({ background, theme }) => ({
  backgroundColor: background,
  p: {
    margin: 0,
    padding: 0,
    color: theme.palette.primary.main,
  },
  '.accordion-title': {
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  '.accordion-text': {
    maxWidth: '1024px',
    margin: 'auto',
  },
  '.main-style': {
    padding: '4px',
  },
  '.MuiPaper-root:before, MuiAccordion-root:before': {
    height: 0,
    opacity: 0,
  },
  '& .Mui-expanded p': {
    color: theme.palette.primary.darker,
  },
}));

export default AccordionBlockWrapper;
