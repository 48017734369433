import { FetchingStatus } from 'store/interfaces';
import { ICompaniesState } from './interfaces';

export const initialState: ICompaniesState = {
  fetchingStatus: FetchingStatus.IDLE,
  successEmail: null,
  successDuration: null,
  data: null,
  error: null,
  errorViolations: null,
  errorVoucher: null,
  invalidSubmittedVouchers: [],
  password: null,
  userInfo: null,
  signupId: null,
  invitationToken: null,
  prefilled: {},
  countriesList: { fetchingStatus: FetchingStatus.IDLE, data: null, error: null },
  vouchers: { fetchingStatus: FetchingStatus.IDLE, data: null, error: null },
};
