import { RoutePaths } from './paths';
import { ConfigRoute, RouteType } from './interfaces';
import Info from 'features/info';
import Password from 'features/password';
import Stepper from 'features/stepper';
import Finish from 'features/finish';
import Login from 'features/login';
import Verification from 'features/verification';
import Landing from 'features/landing';
import ProfitRedirect from 'features/profitRedirect';

const { UNAUTHORIZED } = RouteType;

export const routes: ConfigRoute[] = [
  {
    feature: 'info',
    routeType: UNAUTHORIZED,
    props: {
      path: RoutePaths.DefaultPage,
      component: Info,
      title: '',
    },
  },
  {
    feature: 'landing',
    routeType: UNAUTHORIZED,
    props: {
      path: RoutePaths.LandingPage,
      component: Landing,
      title: '',
    },
  },
  {
    feature: 'profitRedirect',
    routeType: UNAUTHORIZED,
    props: {
      path: RoutePaths.ProfitRedirect,
      component: ProfitRedirect,
      title: '',
    },
  },
  {
    feature: 'login',
    routeType: UNAUTHORIZED,
    props: {
      path: RoutePaths.Login,
      component: Login,
      title: '',
    },
  },
  {
    feature: 'loginForm',
    routeType: UNAUTHORIZED,
    props: {
      path: RoutePaths.LoginForm,
      component: Login,
      title: '',
    },
  },
  {
    feature: 'verification',
    routeType: UNAUTHORIZED,
    props: {
      path: RoutePaths.Verification,
      component: Verification,
      title: '',
    },
  },
  {
    feature: 'password',
    routeType: UNAUTHORIZED,
    props: {
      path: RoutePaths.Password,
      component: Password,
      title: '',
    },
  },
  {
    feature: 'stepper',
    routeType: UNAUTHORIZED,
    props: {
      path: RoutePaths.Stepper,
      component: Stepper,
      title: '',
    },
  },
  {
    feature: 'finish',
    routeType: UNAUTHORIZED,
    props: {
      path: RoutePaths.Successful,
      component: Finish,
      title: '',
    },
  },
];
