import { IUserInfo } from 'store/company/interfaces';

export const isDisabledField = (
  fieldName: string,
  disabledFields: string[],
  userInfo: IUserInfo | null,
) => {
  if (fieldName.includes('customFields')) {
    return (
      disabledFields.includes(fieldName) &&
      userInfo &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      userInfo.customFields[fieldName.substr('customFields'.length + 1)] &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      userInfo.customFields[fieldName.substr('customFields'.length + 1)].toString().trim().length >
        0
    );
  }
  return (
    disabledFields.includes(fieldName) &&
    userInfo &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    userInfo[fieldName] &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    userInfo[fieldName].toString().trim().length > 0
  );
};
