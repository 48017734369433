/*


http://localhost:3000/api/endusers/profit?userData=S2dIUnZLQUxWbUNjaXBiUjF2cCtGUT09&voucher=PF461503-IXSCTN
http://localhost:3000/profit/step/0/?SP-HIDE=customFields.proFit_UserId,customFields.voucher_code,customFields.voucher_value&SP-NOCHANGE=firstName,customFields.company_name,lastName,customFields.voucher_code,customFields.voucher_value&SP-firstName=Hanni&SP-lastName=Winkler&SP-customFields.company_name=proFIT+GmbH&SP-customFields.voucher_code=PF461503-IXSCTN&SP-customFields.voucher_value=10&SP-duration=6m&SP-hansefit_membership=476&SP-payable_amount=466&SP-userData=S2dIUnZLQUxWbUNjaXBiUjF2cCtGUT09

sasha-Test-123?SP-password=test&SP-firstName=Sasha&SP-lastName=Byelik&SP-dateOfBirth=11-09-1991&SP-customFields.field1=test123&SP-NOCHANGE=%5B'firstName'%2C'gender'%2C'customFields.field1'%5D&SP-NOSHOW=%5B'lastName'%2C'gender'%2C'dateOfBirth'%5D

sasha-Test-123?
SP-HIDE=customFields.proFit_UserId&
SP-NOSHOW=firstName,gender&
SP-NOCHANGE=firstName,lastName&
SP-firstName=Anika&
SP-lastName=Kattenbach&
SP-customFields.proFit_UserId=217423432

SP-password=test
SP-lastName=Byelik
SP-dateOfBirth=11-09-1991
SP-customFields.field1=test123
SP-NOCHANGE=firstName,gender,customFields.field1
SP-NOSHOW=lastName,gender,dateOfBirth

SP-password: sends the password of the page

SP-{variable name}: reference to a variable in the form that should get “prefiled” with this data.

SP-NOCHANGE: the field in the form is prefilled AND the user cannot change this field (only allowed if the matching SP variable is present in the paramter list)

SP-NOSHOW: the field in the form is prefilled AND the user will not see the field in the form at all(except summary page), this will override the NOCHANGE flag.

SP-HIDE: the field which we never show to the user(on the summary page need to hide it too), but we need to send this field to the backend

SP-end_date_reached: if presented we need to make start date is not editable
*/

import CompanyFooter from 'components/CompanyFooter';
import Header from 'components/Header';
import { useLocation, useNavigate, useParams } from 'react-router';
import Loader from 'components/Loader';
import { useAppDispatch, useAppSelector } from 'store';
import { CompanySelectors } from 'store/company/company.selectors';
import { ReactNode, useEffect } from 'react';
import qs from 'qs';
import { actions } from 'store/company/company.slice';
import { format } from 'date-fns';
import { CompanyActions } from 'store/company';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { ICompanyState, IUserInfo } from 'store/company/interfaces';
import { IPaymentForm } from 'features/stepper/interfaces';
import { Box } from '@mui/system';
import { MainLayoutStyled } from './MainLayout,styles';

type PaymentInfoField =
  | 'accountHolderIsContractHolder'
  | 'billingAddressIsContactAddress'
  | 'iban'
  | 'bic'
  | 'account_holder'
  | 'street'
  | 'houseNumber'
  | 'city'
  | 'zip'
  | 'confirmSepaCredentials'
  | 'country'
  | 'ibanPrefilled';

const PAYMENT_INFO_FIELDS: PaymentInfoField[] = [
  'accountHolderIsContractHolder',
  'billingAddressIsContactAddress',
  'iban',
  'bic',
  'account_holder',
  'street',
  'houseNumber',
  'city',
  'zip',
  'confirmSepaCredentials',
  'country',
  'ibanPrefilled',
];

const START_WITH = 'SP-';
const CUSTOM_FIELD_START = 'customFields';
const GLOBAL_CUSTOM_FIELD_START = 'globalCustomFields';

const MainLayout = ({ children }: { children: ReactNode }) => {
  const { pathname, search } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const language = useCurrentLanguage();
  const { id, token } = useParams<{ id: string; token: string }>();
  const userInfo = useAppSelector(CompanySelectors.getUserInfo);
  const data = useAppSelector(CompanySelectors.getCompanyData);
  const password = useAppSelector(CompanySelectors.getPassword);
  const signupId = useAppSelector(CompanySelectors.getSignupId);
  const isVoucherError = useAppSelector(store => store.company.errorVoucher);
  const userVouchers = useAppSelector(CompanySelectors.getVouchers);
  const hasVoucher = data?.proFit;
  const path = hasVoucher ? `/${id}/step/0` : `/${id}/step/1`;

  useEffect(() => {
    localStorage.setItem('firstPath', pathname);
    const searchParams = new URLSearchParams(search);
    if (searchParams.has('errorMsg')) {
      const resetData = async () => {
        dispatch(actions.resetFullState('profit'));
        dispatch(actions.setVoucherError(searchParams.get('errorMsg')));
      };

      resetData();
      localStorage.setItem('firstPath', '/profit/step/0/');
      navigate(`/profit/form`);
    }
  }, []);

  useEffect(() => {
    document.documentElement.lang = language;
  }, [language]);

  useEffect(() => {
    if (
      userInfo === null &&
      pathname.toLowerCase().includes('step') &&
      Object.keys(data?.fees || {}).length > 0 &&
      pathname.toLowerCase() !== path
    ) {
      navigate(path);
    }
  }, [userInfo, pathname, data?.fees, path, navigate]);

  useEffect(() => {
    if (id && signupId !== id) {
      navigate(`/${id}/form${search}`);
    }
  }, [signupId, id, navigate, search]);

  const prefillFields = (queryParams: qs.ParsedQs) => {
    let customFields = {} as IUserInfo['customFields'];
    let globalCustomFields = {} as IUserInfo['globalCustomFields'];
    let paymentInfoFields = {} as Partial<IUserInfo['paymentData']>;
    let prefilledFields = {} as ICompanyState;
    let voucherFields = {} as { voucher_code?: string; voucher_value?: string };
    let prefilledUserFields = {} as IUserInfo;

    for (const key in queryParams) {
      if (key.indexOf(START_WITH) === 0) {
        const fieldName = key.substring(START_WITH.length);
        let fieldValue = queryParams[key]?.toString() || '';
        const extractedFieldNameFromDotNotation = fieldName.includes('.')
          ? fieldName.split('.').pop()
          : null;

        if (['dateOfBirth', 'startDate'].includes(fieldName)) {
          // convert date to yyyy-MM-dd format
          fieldValue = format(new Date(fieldValue), 'yyyy-MM-dd');
        }

        if (['password', 'NOCHANGE', 'NOSHOW', 'HIDE'].includes(fieldName)) {
          if (fieldName === 'NOCHANGE' && queryParams['SP-end_date_reached']) {
            fieldValue += ',startDate';
          }

          prefilledFields = {
            ...prefilledFields,
            [fieldName]: fieldValue,
          };
        }

        if (PAYMENT_INFO_FIELDS.includes(fieldName as PaymentInfoField)) {
          if (fieldValue) {
            paymentInfoFields = {
              ...paymentInfoFields,
              [fieldName]: fieldValue,
            };
          }
        }

        if (fieldName.includes(CUSTOM_FIELD_START) && extractedFieldNameFromDotNotation) {
          if (fieldName.includes('voucher_code') || fieldName.includes('voucher_value')) {
            voucherFields = {
              ...voucherFields,
              [extractedFieldNameFromDotNotation]: fieldValue,
            };
          } else {
            customFields = {
              ...customFields,
              [extractedFieldNameFromDotNotation]: fieldValue,
            };
          }
        }

        if (fieldName.includes(GLOBAL_CUSTOM_FIELD_START) && extractedFieldNameFromDotNotation) {
          globalCustomFields = {
            ...globalCustomFields,
            [extractedFieldNameFromDotNotation]: fieldValue,
          };
        }

        // if it is not a custom field or payment info field overwrite userInfo
        if (
          !fieldName.includes(CUSTOM_FIELD_START) &&
          !fieldName.includes(GLOBAL_CUSTOM_FIELD_START) &&
          !PAYMENT_INFO_FIELDS.includes(fieldName as PaymentInfoField) &&
          !['password', 'NOCHANGE', 'NOSHOW', 'HIDE'].includes(fieldName) &&
          !['startDate', 'endDate'].includes(fieldName)
        ) {
          // overwrite userInfo
          prefilledUserFields = {
            ...prefilledUserFields,
            [fieldName]: fieldValue,
          };
        }
      }
    }

    if (
      queryParams['SP-end_date_reached']?.length &&
      typeof queryParams['SP-end_date_reached'] === 'string'
    ) {
      const endDate = format(new Date(queryParams['SP-end_date_reached']), 'yyyy-MM-dd');

      dispatch(
        actions.fillUserInfo({
          end_date_reached: endDate,
          startDate: endDate,
        } as IUserInfo),
      );
    }

    if (Object.keys(customFields).length > 0) {
      dispatch(actions.fillUserInfo({ customFields } as IUserInfo));
    }
    if (Object.keys(globalCustomFields).length > 0) {
      dispatch(actions.fillUserInfo({ globalCustomFields } as IUserInfo));
    }
    if (paymentInfoFields && Object.keys(paymentInfoFields).length > 0) {
      dispatch(
        actions.prefillPaymentInfo({
          ...paymentInfoFields,
          ibanPrefilled: true,
        } as IPaymentForm),
      );
    }
    if (Object.keys(prefilledFields).length > 0) {
      Object.entries(prefilledFields).forEach(([key, value]) => {
        dispatch(
          actions.prefillFields({
            fieldName: key,
            fieldValue: value,
          }),
        );
      });
    }
    if (Object.keys(prefilledUserFields).length > 0) {
      dispatch(actions.fillUserInfo(prefilledUserFields));
    }

    if (hasVoucher) {
      dispatch(
        CompanyActions.verifyVoucher({
          userData: customFields?.proFit_UserId || '',
          newVoucher: voucherFields?.voucher_code ? [voucherFields?.voucher_code] : [],
          existingVoucher: [],
        }),
      );
      navigate(`/${id}/landing`);
      return true;
    }
  };

  useEffect(() => {
    const LS = localStorage.getItem('firstLoading');
    if (!LS && signupId === id && data) {
      const queryParams = qs.parse(search, { ignoreQueryPrefix: true });

      if (queryParams && Object.keys(queryParams).length !== 0 && prefillFields(queryParams)) {
        return;
      }

      //it is login logic after refresh the page(f5 button),
      //and we have the same logic in the - features/login/index.ts
      if (search.includes('token') || token) {
        navigate(`/${id}/verification/${token}`);
      } else if (data.password === 'Yes' && !password) {
        navigate(`/${id}/password`);
      } else if (userInfo === null || hasVoucher) {
        if (hasVoucher && (isVoucherError || !userVouchers.data)) {
          navigate(path);
          return;
        }

        const firstPath = localStorage.getItem('firstPath');
        if (
          data?.landingPage &&
          firstPath &&
          !firstPath.toLowerCase().includes(path.toLowerCase())
        ) {
          navigate(`/${id}/landing`);
        } else if (Object.keys(data?.fees).length > 0) {
          navigate(path);
        }
      }
    }

    // TODO: remove this after testing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, userVouchers.data]);

  useEffect(() => {
    if (signupId === id) {
      dispatch(CompanyActions.getCompanyInfo(signupId));
      dispatch(CompanyActions.getCountriesList());
    }
  }, [dispatch, id, signupId]);
  return (
    <MainLayoutStyled>
      {id && <Loader />}
      <Header />
      <Box className='main-content'>{children}</Box>
      <CompanyFooter />
    </MainLayoutStyled>
  );
};

export default MainLayout;
