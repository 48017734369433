import { styled, Box } from '@mui/material';

export const LoaderWrapper = styled(Box)(({theme}) => ({
  position: 'fixed',
  width: '100%',
  height: '100%',
  zIndex: '10000',
  top: '0',
  left: '0',
  background: theme.palette.transparent.dark,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  '&.cardLoader': {
    position: 'absolute',
  },
}));
