import { createSlice } from '@reduxjs/toolkit';
import { checkPasswordExtraReducer } from './async/checkPassword/checkPassword.reducer';
import { getCompanyExtraReducer } from './async/getCompanyInfo/getCompanyInfo.reducer';
import { getCountriesListExtraReducer } from './async/getCountriesList/getCountriesList.reducer';
import {
  fillUserInfo,
  prefillPaymentInfo,
  resetFullState,
  resetViolationErrors,
  setVoucherError,
  updateVoucherCalculations,
  resetVoucherFetchingStatus,
} from './sync/userInfo.actions';
import { prefillFields } from './sync/prefilledState.actions';

import { initialState } from './initialState';
import { sendUserDataExtraReducer } from './async/sendUserData/sendUserData.reducer';
import { getUserExtraReducer } from './async/getUserInfo/getUserInfo.reducer';
import { verifyVoucherExtraReducer } from './async/verifyVoucher/verifyVoucher.reducer';

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    fillUserInfo,
    resetFullState,
    prefillFields,
    resetViolationErrors,
    prefillPaymentInfo,
    setVoucherError,
    updateVoucherCalculations,
    resetVoucherFetchingStatus,
  },
  extraReducers: builder => {
    getCompanyExtraReducer(builder);
    getCountriesListExtraReducer(builder);
    checkPasswordExtraReducer(builder);
    sendUserDataExtraReducer(builder);
    getUserExtraReducer(builder);
    verifyVoucherExtraReducer(builder);
  },
});

export const { name, actions } = companySlice;
export const companyReducer = companySlice.reducer;
