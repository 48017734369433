import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { ParsedQs } from 'qs';
import { ICompaniesState } from '../interfaces';

export const prefillFields: CaseReducer<
  ICompaniesState,
  PayloadAction<{
    fieldName: string;
    fieldValue: string | string[] | ParsedQs | ParsedQs[] | undefined;
  }>
> = (state, { payload }) => {
  if (!state.prefilled) {
    state['prefilled'] = {};
  }
  state.prefilled[payload.fieldName] = payload.fieldValue;
};
