import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import { formatPrice } from 'utils/formatPrice';
import DeleteIcon from '@mui/icons-material/Delete';

interface VoucherBoxProps {
  code: string;
  value: string;
  isInvalid?: boolean;
  isRedeem?: boolean;
  isDeletable: boolean;
  handleRemove: (code: string) => void;
}

const VoucherBox: React.FC<VoucherBoxProps> = ({
  code,
  value,
  isInvalid = false,
  isDeletable,
  handleRemove,
  isRedeem = false,
}) => {
  const intl = useIntl();

  return (
    <Box
      className={`sectionBox__voucher ${isInvalid ? 'sectionBox__error' : ''} ${
        isRedeem ? 'sectionBox__redeem' : ''
      }`}>
      {isInvalid ? (
        <Box>
          {intl.formatMessage({
            id: 'step0.subsection.error.voucher_placeholder',
            defaultMessage: 'Error occured',
          })}
        </Box>
      ) : (
        <>
          <Box>{code.toUpperCase()}</Box>
          <Box className="sectionBox__voucher__value">
            {formatPrice(value)}
            <DeleteIcon
              fontSize="small"
              className={!isDeletable ? 'disabled' : ''}
              onClick={() => handleRemove(code)}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default VoucherBox;
