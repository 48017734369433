import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from 'store';
import { CompanySelectors } from 'store/company/company.selectors';
import React, { useEffect, useMemo } from 'react';
import { CompanyActions } from 'store/company';
import { IViolationsError, IVoucherData } from 'store/company/interfaces';
import { Stepper } from '..';
import GridRow from './gridRow';
import { useGendersList } from '../StepOne/useGendersList';
import { formatDate } from 'utils/formatDate';
import { FetchingStatus, TranslationsKeys } from 'store/interfaces';
import { formatIBAN } from 'utils/formatIBAN';
import { useTitlesList } from '../StepOne/useTitlesList';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import contactSchema from './contactSchema';
import { CCPLabelCheckbox } from 'components/FormComponents/Checkbox/LabelCheckbox';
import { actions } from 'store/company/company.slice';
import { isHiddenField } from 'utils/hidePrefilledFields';
import { distinctArrayValues } from 'utils/distinctArrayValues';
import { formatPrice } from 'utils/formatPrice';
import Spacer from 'components/Spacer';
import ActionFeedback from '../ActionFeedback/ActionFeedback';
import StartDatePanel from '../FormParts/StartDatePanel';
import { format } from 'date-fns';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { getMaxStartDate, getMinStartDate } from 'utils/prepareDates';

interface StepSummaryProps {
  updateStep: (stepNumber: number) => void;
  sendRequest: (error: IViolationsError[], errorVoucher?: IVoucherData) => void;
}

const StepSummary: React.FC<StepSummaryProps> = ({ updateStep, sendRequest }) => {
  const intl = useIntl();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const gendersList = useGendersList();
  const titles = useTitlesList();
  const currentLanguage = useCurrentLanguage();
  const company = useAppSelector(CompanySelectors.getCompanyData);
  const userInfo = useAppSelector(CompanySelectors.getUserInfo);
  const fetchingStatus = useAppSelector(state => state.company.fetchingStatus);
  const countries = useAppSelector(state => state.company.countriesList.data) || [];
  const hiddenFields = distinctArrayValues(
    [],
    useAppSelector(CompanySelectors.getFullHiddenFields) || [],
  );
  const minimumDate = getMinStartDate(company);
  const maximumDate = getMaxStartDate(company);

  const noBankInfo = company && company.paymentProviders?.length !== 0;
  const paymentValue = useAppSelector(
    state => state.company.vouchers.data?.payableAmount,
  )?.toString();
  const hasVoucher = company?.proFit;
  const hasStep3 = hasVoucher ? noBankInfo && paymentValue !== '0' : noBankInfo;
  const isVoucherInvalid = false; // when BE is ready, voucher expired error (also in StepZero)
  const prevStep = useMemo(() => {
    if (hasStep3) {
      return hasVoucher ? 2 : 3;
    }
    return hasVoucher ? 1 : 2;
  }, [hasStep3, hasVoucher]);

  const userVouchers = useAppSelector(CompanySelectors.getVouchers);
  const voucherList = userVouchers ? userVouchers.data?.valid : [];
  const renderVoucherList = (
    <>
      {voucherList?.map(item => (
        <Box key={item.voucher.code} className='container--inner'>
          <Typography>{item.voucher.code}</Typography>
          <Typography variant='subtitle2'>{formatPrice(item.voucher.initialValue)}</Typography>
        </Box>
      ))}
    </>
  );

  const setSchema = () => {
    let newSchema: any = {};
    let SchemaShaped = contactSchema(!!!hasStep3, hasVoucher === 'yes');
    if (
      company?.signupPageAdditionalCheckboxes &&
      company.signupPageAdditionalCheckboxes.length > 0
    ) {
      company.signupPageAdditionalCheckboxes.forEach((field, i) => {
        newSchema[`signupPageAdditionalCheckboxes_${i}`] = yup
          .boolean()
          .oneOf([true], 'form.employee.error.checkbox.required');
      });
      SchemaShaped = SchemaShaped.shape({
        signupPageAdditionalCheckboxes: yup.object().shape({ ...newSchema }),
      });
    }
    return SchemaShaped;
  };

  const methods = useForm<{
    confirmCompany: boolean;
    confirmPrivacy: boolean;
    confirmPayments?: boolean;
    signupPageAdditionalCheckboxes?: {};
    startDate?: Date | null | string;
  }>({
    defaultValues: {
      confirmCompany: userInfo?.confirmCompany || false,
      confirmPrivacy: userInfo?.confirmPrivacy || false,
      confirmPayments: userInfo?.confirmPayments || false,
      signupPageAdditionalCheckboxes:
        company?.signupPageAdditionalCheckboxes && company.signupPageAdditionalCheckboxes.length > 0
          ? company.signupPageAdditionalCheckboxes.reduce(
              (result, elem, i) => ({
                ...result,
                [`signupPageAdditionalCheckboxes_${i}`]: userInfo?.signupPageAdditionalCheckboxes
                  ? userInfo.signupPageAdditionalCheckboxes[`signupPageAdditionalCheckboxes_${i}`]
                  : false,
              }),
              {},
            )
          : {},
      startDate: userInfo?.startDate
        ? format(new Date(userInfo.startDate), 'yyyy-MM-dd')
        : new Date(),
    },
    resolver: yupResolver(setSchema()),
  });

  const { control, handleSubmit, reset } = methods;

  useEffect(() => {
    if (fetchingStatus === FetchingStatus.REJECTED) reset();
  }, [fetchingStatus]);

  const onSubmit = async (data: any) => {
    dispatch(actions.fillUserInfo(data));
    const resp = await dispatch(CompanyActions.sendUserData({ id, userInfo }));
    if (resp.payload?.violations) {
      sendRequest(resp.payload.violations);
    } else if (hasVoucher && resp.payload?.invalid?.length) {
      sendRequest([], resp.payload);
    } else if (resp.meta.requestStatus !== 'rejected') {
      sendRequest([]);
    }
  };

  const personalDetails = (
    <>
      {userInfo?.title && (
        <GridRow
          hidden={isHiddenField('title', hiddenFields, userInfo)}
          title={intl.formatMessage({
            id: 'form.employee.field.title.label',
            defaultMessage: 'Title',
          })}
          value={titles.filter(title => title.value === userInfo?.title)[0]?.title}
        />
      )}
      <GridRow
        hidden={isHiddenField('fullName', hiddenFields, userInfo)}
        title={intl.formatMessage({
          id: 'form.employee.field.full_name.label',
          defaultMessage: 'Full name',
        })}
        value={`${userInfo?.firstName} ${userInfo?.lastName}`}
      />
      <GridRow
        hidden={isHiddenField('gender', hiddenFields, userInfo)}
        title={intl.formatMessage({
          id: 'form.employee.field.gender.label',
          defaultMessage: 'Gender',
        })}
        value={gendersList.filter(gender => gender.value === userInfo?.gender)[0]?.title}
      />
      <GridRow
        hidden={isHiddenField('dateOfBirth', hiddenFields, userInfo)}
        title={intl.formatMessage({
          id: 'form.employee.field.birthday.label',
          defaultMessage: 'Birthday date',
        })}
        value={
          typeof userInfo?.dateOfBirth === 'string'
            ? formatDate(userInfo?.dateOfBirth, currentLanguage)
            : userInfo?.dateOfBirth
        }
      />
    </>
  );

  const personalDataContent = hasVoucher ? (
    <>
      <Box className='step-info'>
        <Stack
          className='step-info--title'
          direction='row'
          justifyContent='space-between'
          alignItems='center'>
          <label>
            {intl.formatMessage({
              id: 'step4.contract_data.subtitle',
              defaultMessage: 'Contract data',
            })}
          </label>
          <Button variant='text' onClick={() => updateStep(1)} startIcon={<EditIcon />}>
            {intl.formatMessage({
              id: 'step4.action.edit',
              defaultMessage: 'Edit',
            })}
          </Button>
        </Stack>
        <Grid container rowGap='8px'>
          {personalDetails}
          <GridRow
            title={intl.formatMessage({
              id: 'form.employee.field.email.label',
              defaultMessage: 'Corporate email',
            })}
            value={userInfo?.businessEmail}
          />
          {userInfo?.phoneNumber && (
            <GridRow
              title={intl.formatMessage({
                id: 'form.employee.field.phone.label',
                defaultMessage: 'Phone number',
              })}
              value={userInfo?.phoneNumber}
            />
          )}
          {company &&
            company.globalCustomFields?.length > 0 &&
            company.globalCustomFields.map((customField, idx) => (
              <GridRow
                key={idx}
                hidden={isHiddenField(
                  `globalCustomFields.${customField.key}`,
                  hiddenFields,
                  userInfo,
                )}
                title={customField.name[currentLanguage] || ''}
                value={
                  customField.key === 'hansefit_country'
                    ? countries.filter(
                        country => country.code === userInfo?.globalCustomFields[customField.key],
                      )[0]?.name[currentLanguage]
                    : customField.key === 'hansefit_street'
                    ? `${userInfo?.globalCustomFields[customField.key]} ${
                        userInfo?.globalCustomFields['hansefit_houseNumber']
                      }`
                    : userInfo?.globalCustomFields[customField.key]
                }
              />
            ))}
          {company &&
            company.customFields?.length > 0 &&
            company.customFields.map((customField, idx) => (
              <GridRow
                key={idx}
                hidden={isHiddenField(`customFields.${customField.key}`, hiddenFields, userInfo)}
                title={customField.name[currentLanguage] || ''}
                value={userInfo?.customFields[customField.key]}
              />
            ))}
          <GridRow
            hidden={isHiddenField('startDate', hiddenFields, userInfo)}
            title={intl.formatMessage({
              id: 'form.employee.field.service_date.label',
              defaultMessage: 'Service start date',
            })}
            value={
              typeof userInfo?.startDate === 'string'
                ? formatDate(userInfo?.startDate, currentLanguage)
                : userInfo?.startDate
            }
          />
          <GridRow
            hidden={isHiddenField('startDate', hiddenFields, userInfo)}
            title={intl.formatMessage({
              id: 'form.employee.field.duration.label',
              defaultMessage: 'Duration',
            })}
            value={userInfo?.duration}
          />
        </Grid>
      </Box>
    </>
  ) : (
    <>
      <Box className='step-info'>
        <Stack
          className='step-info--title'
          direction='row'
          justifyContent='space-between'
          alignItems='center'>
          <label>
            {intl.formatMessage({
              id: 'step4.personal.subtitle',
              defaultMessage: 'Personal details',
            })}
          </label>
          <Button variant='text' onClick={() => updateStep(1)} startIcon={<EditIcon />}>
            {intl.formatMessage({
              id: 'step4.action.edit',
              defaultMessage: 'Edit',
            })}
          </Button>
        </Stack>
        <Grid container rowGap='8px'>
          {personalDetails}
        </Grid>
      </Box>
      <Box className='step-info'>
        <Stack
          className='step-info--title'
          direction='row'
          justifyContent='space-between'
          alignItems='center'>
          <label>
            {intl.formatMessage({
              id: 'step4.contact.subtitle',
              defaultMessage: 'Contact details',
            })}
          </label>
          <Button variant='text' onClick={() => updateStep(2)} startIcon={<EditIcon />}>
            {intl.formatMessage({
              id: 'step4.action.edit',
              defaultMessage: 'Edit',
            })}
          </Button>
        </Stack>
        <Grid container rowGap='8px'>
          <GridRow
            hidden={isHiddenField('businessEmail', hiddenFields, userInfo)}
            title={intl.formatMessage({
              id: 'form.employee.field.email.label',
              defaultMessage: 'Corporate email',
            })}
            value={userInfo?.businessEmail}
          />
          {userInfo?.phoneNumber && (
            <GridRow
              hidden={isHiddenField('phoneNumber', hiddenFields, userInfo)}
              title={intl.formatMessage({
                id: 'form.employee.field.phone.label',
                defaultMessage: 'Phone number',
              })}
              value={userInfo?.phoneNumber}
            />
          )}
          {company &&
            company.globalCustomFields?.length > 0 &&
            company.globalCustomFields.map((customField, idx) => (
              <GridRow
                key={idx}
                hidden={isHiddenField(
                  `globalCustomFields.${customField.key}`,
                  hiddenFields,
                  userInfo,
                )}
                title={customField.name[currentLanguage] || ''}
                value={
                  customField.key === 'hansefit_country'
                    ? countries.filter(
                        country => country.code === userInfo?.globalCustomFields[customField.key],
                      )[0]?.name[currentLanguage]
                    : customField.key === 'hansefit_street'
                    ? `${userInfo?.globalCustomFields[customField.key]} ${
                        userInfo?.globalCustomFields['hansefit_houseNumber']
                      }`
                    : userInfo?.globalCustomFields[customField.key]
                }
              />
            ))}
          {company &&
            company.customFields?.length > 0 &&
            company.customFields.map((customField, idx) => {
              let value = userInfo?.customFields[customField.key] || '';
              if (
                customField.inputType === 'enum' &&
                customField.localizedValuesMap &&
                customField.localizedValuesMap[value]
              ) {
                value =
                  customField.localizedValuesMap[value][currentLanguage || 'en' || 'de'] || value;
              }
              return (
                <GridRow
                  key={idx}
                  hidden={isHiddenField(`customFields.${customField.key}`, hiddenFields, userInfo)}
                  title={customField.name[currentLanguage] || ''}
                  value={value}
                />
              );
            })}
        </Grid>
      </Box>
    </>
  );

  return (
    <Box maxWidth='680px' className='stepWrapper'>
      <Stepper hasError={isVoucherInvalid} />
      <Typography className='subtext' variant='h5'>
        {intl.formatMessage({
          id: 'step4.description',
          defaultMessage: 'Review and confirm all entered data.',
        })}
      </Typography>
      <Spacer height={48} />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {hasVoucher && (
            <Box className='step-info'>
              <Stack
                className='step-info--title'
                direction='row'
                justifyContent='space-between'
                alignItems='center'>
                <label>
                  {intl.formatMessage({
                    id: 'step4.voucher.subtitle',
                    defaultMessage: 'proFit voucher',
                  })}
                </label>
                <Button
                  variant='text'
                  onClick={() => updateStep(0)}
                  startIcon={<RemoveRedEyeIcon />}>
                  {intl.formatMessage({
                    id: 'step4.action.check',
                    defaultMessage: 'Check',
                  })}
                </Button>
              </Stack>
              <Grid container rowGap='8px'>
                <GridRow
                  title={intl.formatMessage({
                    id: 'form.voucher.field.voucher_code.label',
                    defaultMessage: 'Voucher Code',
                  })}
                  value={renderVoucherList}
                  innerContent={true}
                />
                <GridRow
                  title={intl.formatMessage({
                    id: 'form.voucher.field.balance.label',
                    defaultMessage: 'Voucher value',
                  })}
                  value={formatPrice(userInfo?.customFields?.voucher_value)}
                />
                <GridRow
                  title={intl.formatMessage({
                    id: 'form.voucher.field.contribution.label',
                    defaultMessage: 'Your contribution',
                  })}
                  value={formatPrice(userInfo?.payable_amount)}
                  accent={true}
                  hidden={paymentValue === '0'}
                />
              </Grid>
            </Box>
          )}
          {personalDataContent}
          {hasStep3 && (
            <Box className='step-info'>
              <Stack
                className='step-info--title'
                direction='row'
                justifyContent='space-between'
                alignItems='center'>
                <label>
                  {intl.formatMessage({
                    id: 'step4.payment.subtitle',
                    defaultMessage: 'Payment details',
                  })}
                </label>
                <Button variant='text' onClick={() => updateStep(3)} startIcon={<EditIcon />}>
                  {intl.formatMessage({
                    id: 'step4.action.edit',
                    defaultMessage: 'Edit',
                  })}
                </Button>
              </Stack>
              <Grid container rowGap='8px'>
                <GridRow
                  title={intl.formatMessage({
                    id: 'form.employee.field.account_name.label',
                    defaultMessage: 'Account holder name',
                  })}
                  value={userInfo?.paymentData?.account_holder}
                />
                <GridRow
                  title={intl.formatMessage({
                    id: 'form.employee.field.iban.label',
                    defaultMessage: 'IBAN',
                  })}
                  value={formatIBAN(userInfo?.paymentData?.iban || '')}
                />
                <GridRow
                  title={intl.formatMessage({
                    id: 'form.employee.field.bic.label',
                    defaultMessage: 'BIC of the bank',
                  })}
                  value={userInfo?.paymentData?.bic}
                />
                <GridRow
                  title={intl.formatMessage({
                    id: 'form.employee.field.address.label',
                    defaultMessage: 'Billing address',
                  })}
                  value={`${userInfo?.paymentData?.street} ${userInfo?.paymentData?.houseNumber}, ${
                    userInfo?.paymentData?.zip
                  } ${userInfo?.paymentData?.city}, ${
                    countries.filter(country => country.code === userInfo?.paymentData?.country)[0]
                      ?.name[currentLanguage]
                  }`}
                />
              </Grid>
            </Box>
          )}
          {!hasVoucher && (
            <StartDatePanel
              isSummary
              isFlexibleContract={company?.regularStartDate === 'flexible'}
              maximumDate={maximumDate}
              minimumDate={minimumDate}
            />
          )}
          <Box className='step-info'>
            <Grid container rowGap='8px'>
              <Grid item xs={12} sm={12}>
                <CCPLabelCheckbox
                  control={control}
                  name='confirmCompany'
                  label={
                    company && company.confirmationText
                      ? company.confirmationText
                      : `${intl.formatMessage(
                          {
                            id: 'form.employee.field.confirm_company_checkbox.label',
                            defaultMessage: `I confirm that I am an employee of {companyName}.`,
                          },
                          {
                            companyName: hasVoucher
                              ? userInfo?.customFields?.company_name
                              : company?.name,
                          },
                        )}*`
                  }
                />
              </Grid>
              {!hasStep3 && (
                <Grid item xs={12} sm={12}>
                  {paymentValue !== '0' && (
                    <CCPLabelCheckbox
                      control={control}
                      name='confirmPayments'
                      label={`${intl.formatMessage({
                        id: 'form.employee.field.confirm_payments_checkbox.label',
                        defaultMessage: `I acknowledge that my employer may withhold the amount for membership & registration from my wages`,
                      })}*`}
                    />
                  )}
                </Grid>
              )}
              <Grid item xs={12} sm={12}>
                <CCPLabelCheckbox
                  control={control}
                  name='confirmPrivacy'
                  label=''
                  htmlLabel={`${intl
                    .formatMessage({
                      id: 'form.employee.field.confirm_privacy_checkbox.label',
                      defaultMessage: `I confirm that I have taken note of the <tag>privacy policy</tag> of Hansefit`,
                    })
                    .replace(
                      '<tag>',
                      `<a href=${intl.formatMessage({
                        id: 'step3.privacy_link_url',
                        defaultMessage: 'https://hansefit.de/datenschutz/',
                      })} target="privacy">`,
                    )
                    .replace('</tag>', '</a>')}*`}
                />
              </Grid>
              {company?.signupPageAdditionalCheckboxes &&
              company.signupPageAdditionalCheckboxes.length > 0
                ? company.signupPageAdditionalCheckboxes.map((element, i) => (
                    <Grid item xs={12} sm={12} key={`signupPageAdditionalCheckboxes_${i}`}>
                      <CCPLabelCheckbox
                        control={control}
                        name={`signupPageAdditionalCheckboxes.signupPageAdditionalCheckboxes_${i}`}
                        label=''
                        htmlLabel={element}
                      />
                    </Grid>
                  ))
                : null}
            </Grid>
            {isVoucherInvalid && (
              <Box>
                <Spacer height={24} />
                <ActionFeedback
                  type='error'
                  headline={intl.formatMessage({
                    id: 'step0.subsection.error.headline.voucher_invalid',
                    defaultMessage: 'Your voucher is invalid, because it was canceled',
                  })}
                  body={intl.formatMessage({
                    id: 'step0.subsection.error.body',
                    defaultMessage:
                      'It is not possible to continue. Please go back to proFit and create a new voucher.',
                  })}
                />
              </Box>
            )}
          </Box>
          <Box className='footer step-info'>
            <Button onClick={() => updateStep(prevStep)} variant={'outlined'}>
              {intl.formatMessage({
                id: 'step2.back_button',
                defaultMessage: 'Back',
              })}
            </Button>
            <Button type='submit' variant='contained' disabled={isVoucherInvalid}>
              {intl.formatMessage({
                id: 'step2.confirm',
                defaultMessage: 'Confirm',
              })}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default StepSummary;
