import { FooterWrapper } from './FooterWrapper.styles';
import { Box, Stack, Typography } from '@mui/material';
import { FooterLogo } from 'assets/icons';
import { useAppSelector } from 'store';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';

const CompanyFooter = () => {
  const { pathname } = useLocation();
  const { formatMessage } = useIntl();
  const data = useAppSelector(state => state.company.data);

  if (pathname.includes('landing')) {
    return null;
  }

  return (
    <FooterWrapper>
      <Box className='container' data-testid='stepper-footer'>
        <Box className='footerLogo'>
          <Stack direction='row' spacing={2} justifyItems='center' className='logoContainer'>
            <FooterLogo />
            <a className='link' href='https://hansefit.de/' rel='noreferrer' target='_blank'>
              {formatMessage({
                id: 'footer.links.website.link',
                defaultMessage: 'www.hansefit.de',
              })}
            </a>
            <a
              className='link'
              href='https://hansefit.de/mitarbeiter/faq/'
              rel='noreferrer'
              target='_blank'>
              {formatMessage({
                id: 'footer.links.website.faq',
                defaultMessage: 'FAQ',
              })}
            </a>
          </Stack>

          <Stack direction='row' spacing={2}>
            <a
              className='link'
              href={formatMessage({
                id: 'footer.links.contact.imprint.link',
                defaultMessage: 'https://hansefit.de/impressum',
              })}
              rel='noreferrer'
              target='_blank'>
              {formatMessage({ id: 'footer.links.contact.imprint', defaultMessage: 'Imprint' })}
            </a>
            <a
              className='link'
              href={formatMessage({
                id: 'footer.links.contact.privacy.link',
                defaultMessage: 'https://hansefit.de/datenschutz',
              })}
              rel='noreferrer'
              target='_blank'>
              {formatMessage({ id: 'footer.links.contact.privacy', defaultMessage: 'Privacy' })}
            </a>
            <a
              className='link'
              href={formatMessage({
                id: 'footer.links.contact.terms.link',
                defaultMessage: 'https://hansefit.de/nutzungsbedingungen',
              })}
              rel='noreferrer'
              target='_blank'>
              {formatMessage({
                id: 'footer.links.contact.terms',
                defaultMessage: 'Terms of Use',
              })}
            </a>
          </Stack>
        </Box>
      </Box>
    </FooterWrapper>
  );
};

export default CompanyFooter;
