import { useMemo } from 'react';
import { useAppSelector } from 'store';
import { CompanySelectors } from 'store/company/company.selectors';

export const useIsDirectPay = () => {
  const company = useAppSelector(CompanySelectors.getCompanyData);

  const isDirectPay = useMemo(() => company?.directPay === 'yes', [company]);

  return isDirectPay;
};
