import { Box } from '@mui/material';
import React from 'react';
import Headline from '../Headline';
import TextArea from '../TextArea';
import SignUpBox from '../SignUpBox';

const HeadlineWithBackground: React.FC<{ backgroundImage: string; children: Array<any> }> = ({
  backgroundImage,
  children,
}) => (
  <Box
    className={'main-style header-background'}
    sx={{
      backgroundImage: `linear-gradient(0deg, rgba(0, 25, 77, 0.80) 0%, rgba(0, 25, 77, 0.80) 100%), url(${backgroundImage})`,
    }}
    data-testid='landing-item'>
    <Box className='limit-width'>
      {children &&
        children.length > 0 &&
        children.map((item, index) => {
          switch (item.blockName) {
            case 'signup-landing-page-headline': {
              return (
                <Headline
                  key={`${item.blockName}-${index}`}
                  internal
                  text={item.text}
                  placement={item.placement}
                  backgroundColor='transparent'
                />
              );
            }
            case 'signup-landing-page-text': {
              return (
                <TextArea
                  key={`${item.blockName}-${index}`}
                  internal
                  text={item.text}
                  placement={item.placement}
                  backgroundColor='transparent'
                />
              );
            }
            case 'signup-landing-page-sign-up-box': {
              return (
                <SignUpBox
                  key={`${item.blockName}-${index}`}
                  internal
                  placement={item.placement}
                  badge={item.label}
                  text={item.text}
                  enableInfoBox={item.enableInfoBox}
                  info={item.info}
                  note={item.note}
                  backgroundColor='#FFF'
                  button={item.button}
                />
              );
            }
            default:
              return null;
          }
        })}
    </Box>
  </Box>
);

export default HeadlineWithBackground;
