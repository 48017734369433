import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useCallback, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { defaultTextfieldError } from 'utils/defaultErrorMessage';
import { DefaultTextField } from './DefaultTextField.styles';

export interface PasswordFieldProps {
  label: string;
  name: string;
  control: any;
  isRepeat?: boolean;
}

const CCPPasswordTextField = ({ label, control, name }: PasswordFieldProps) => {
  const intl = useIntl();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleTogglePassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <DefaultTextField
          {...field}
          fullWidth
          error={!!fieldState.error}
          helperText={
            fieldState.error?.message
              ? intl.formatMessage({
                  id: fieldState.error?.message,
                  defaultMessage:
                    defaultTextfieldError[fieldState.error.message] || fieldState.error.message,
                })
              : undefined
          }
          label={label}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleTogglePassword} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default CCPPasswordTextField;
