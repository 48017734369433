import { styled, Box } from '@mui/material';

export const ActionFeedbackWrapper = styled(Box)(({ theme }) => ({
  marginTop: '16px',

  ' & .container': {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    padding: '16px',
  },
  ' & .success': {
    backgroundColor: theme.palette.success.light,
    borderRadius: '4px',
  },
  ' & .error': {
    backgroundColor: theme.palette.error.light,
    borderRadius: '4px',
  },
  ' & .warning': {
    backgroundColor: theme.palette.warning.light,
    borderRadius: '4px',
    width: 'calc(100% + 96px)',
    padding: '0 32px',
    marginLeft: '-48px',
  },
}));
