import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, MenuItem, Typography } from '@mui/material';
import CCPSelect from 'components/FormComponents/Select';
import { useIntl } from 'react-intl';
import { useAppSelector } from 'store';
import { CompanySelectors } from 'store/company/company.selectors';
import { isDisabledField } from 'utils/disablePrefilledFields';
import { isHiddenField } from 'utils/hidePrefilledFields';
import { useGendersList } from '../StepOne/useGendersList';
import { useTitlesList } from '../StepOne/useTitlesList';
import CCPTextField from 'components/FormComponents/TextField';
import CCPDatePicker from 'components/FormComponents/DatePicker';
import { getBirthDate } from 'utils/prepareDates';
import Spacer from 'components/Spacer';
import { distinctArrayValues } from 'utils/distinctArrayValues';
import StartDatePicker from './StartDatePicker';
import { useWindowSize } from 'usehooks-ts';
import { default as theme } from 'theme/theme';

interface PersonalDataProps {
  hasVoucher: boolean;
}

const PersonalData: React.FC<PersonalDataProps> = ({ hasVoucher }) => {
  const windowSize = useWindowSize();
  const isMobile = useMemo(() => windowSize.width < theme.breakpoints.values.sm, [windowSize]);
  const userInfo = useAppSelector(CompanySelectors.getUserInfo);
  const disabledFields = useAppSelector(CompanySelectors.getDisabledFields) || [];
  const formHiddenFields = useAppSelector(CompanySelectors.getHiddenFields) || [];
  const fullHiddenFields = useAppSelector(CompanySelectors.getFullHiddenFields) || [];
  const hiddenFields = distinctArrayValues(formHiddenFields, fullHiddenFields);

  const intl = useIntl();
  const genders = useGendersList();
  const titles = useTitlesList();

  const formMethods = useFormContext();
  const {
    control,
    getValues,
    formState: { errors },
  } = formMethods;

  const isFirstNameWarningError = useMemo(
    () => errors?.firstName?.message === 'Something error',
    [errors?.firstName?.message],
  );
  const isLastNameWarningError = useMemo(
    () => errors?.lastName?.message === 'Something error',
    [errors?.lastName?.message],
  );
  const isDateOfBirthWarningError = useMemo(
    () => errors?.dateOfBirth?.message === 'Something error',
    [errors?.dateOfBirth?.message],
  );

  return (
    <Box className='content first-page'>
      {hasVoucher && (
        <>
          <Typography variant='subtitle2'>
            {intl.formatMessage({
              id: 'step1.subsection.headline.personal_info',
              defaultMessage: 'Personal information',
            })}
          </Typography>
          {!isMobile && <Spacer height={16} />}
        </>
      )}
      <CCPSelect
        control={control}
        disabled={isDisabledField('title', disabledFields, userInfo)}
        hidden={isHiddenField('title', hiddenFields, userInfo)}
        name='title'
        label={`${intl.formatMessage({
          id: 'form.employee.field.title.label',
          defaultMessage: 'Title',
        })}`}>
        {titles.map(title => (
          <MenuItem
            style={{ minHeight: '48px' }}
            key={title.value}
            value={title.value}
            selected={title.value === getValues('title')}>
            {title.title}
          </MenuItem>
        ))}
      </CCPSelect>
      <CCPSelect
        control={control}
        disabled={isDisabledField('gender', disabledFields, userInfo)}
        hidden={isHiddenField('gender', hiddenFields, userInfo)}
        name='gender'
        label={`${intl.formatMessage({
          id: 'form.employee.field.gender.label',
          defaultMessage: 'Gender',
        })}*`}>
        {genders.map(gender => (
          <MenuItem
            key={gender.value}
            value={gender.value}
            className='notranslate'
            selected={gender.value === getValues('gender')}>
            {gender.title}
          </MenuItem>
        ))}
      </CCPSelect>
      <CCPTextField
        control={control}
        disabled={isDisabledField('firstName', disabledFields, userInfo)}
        hidden={isHiddenField('firstName', hiddenFields, userInfo)}
        name='firstName'
        type='string'
        label={`${intl.formatMessage({
          id: 'form.employee.field.first_name.label',
          defaultMessage: 'First name',
        })}*`}
        isWarningError={isFirstNameWarningError}
        notShowErrorText={isFirstNameWarningError}
      />
      <CCPTextField
        control={control}
        disabled={isDisabledField('lastName', disabledFields, userInfo)}
        hidden={isHiddenField('lastName', hiddenFields, userInfo)}
        name='lastName'
        type='string'
        label={`${intl.formatMessage({
          id: 'form.employee.field.last_name.label',
          defaultMessage: 'Last name',
        })}*`}
        isWarningError={isLastNameWarningError}
        notShowErrorText={isLastNameWarningError}
      />
      <CCPDatePicker
        control={control}
        disabled={isDisabledField('dateOfBirth', disabledFields, userInfo)}
        hidden={isHiddenField('dateOfBirth', hiddenFields, userInfo)}
        minDate={getBirthDate('min')}
        maxDate={getBirthDate('max')}
        name='dateOfBirth'
        isBirthdayPicker
        label={`${intl.formatMessage({
          id: 'form.employee.field.date_of_birth.label',
          defaultMessage: 'Date of birth',
        })}*`}
        isWarningError={isDateOfBirthWarningError}
        notShowErrorText={isDateOfBirthWarningError}
      />
    </Box>
  );
};

export default PersonalData;
