import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { axiosWithConfig } from 'api';
import { UserManagementBaseResponse } from 'api/interfaces';

export const checkPassword = createAsyncThunk<any, any>(
  'company/checkPassword',
  async ({ id, password }, { rejectWithValue }) => {
    const endpoint =
      process.env.REACT_APP_PIMCORE_ENDPOINT + `api/companies/sp-settings/identifiers/${id}`;

    const axios = await axiosWithConfig({ withAuthentication: false });

    try {
      const response = await axios.get(endpoint, { params: { password } });
      if (response.status === 204) return password;
      return null;
    } catch (e) {
      const error: AxiosError<UserManagementBaseResponse> =
        e as AxiosError<UserManagementBaseResponse>;
      return rejectWithValue(error.response?.data);
    }
  },
);
