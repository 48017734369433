import HeaderWrapper from './Header.styles';
import { Box, Button } from '@mui/material';
import { ReactComponent as DefaultHansefitLogo } from 'assets/icons/hansefit-logo-epassi.svg';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'store';
import { CompanySelectors } from 'store/company/company.selectors';
import ContrastColor from 'contrast-color';
import { default as theme } from 'theme/theme';

const Header: React.FC = () => {
  const { formatMessage } = useIntl();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const data = useAppSelector(CompanySelectors.getCompanyData);

  //define color near to black  to change register button color from blue to white
  const isDarkHeaderColor = useMemo(() => {
    if (!data?.signupPageColor) return true;
    const CC = new ContrastColor();
    const { contrastColor } = CC;
    const fgColor = contrastColor({
      bgColor: data?.signupPageColor,
      threshold: 100,
    });
    return fgColor === '#FFFFFF';
  }, [data]);

  //define color near to white for change hansefit logo color from white to blue
  const isLightHeaderColor = useMemo(() => {
    if (!data?.signupPageColor) return true;
    const CC = new ContrastColor();
    const { contrastColor } = CC;
    const fgColor = contrastColor({
      bgColor: data?.signupPageColor,
      threshold: 200,
    });
    return fgColor === '#FFFFFF';
  }, [data]);

  const isLanding = useMemo(() => pathname.includes('/landing'), [pathname]);
  const path = useMemo(() => (data?.proFit ? `/${id}/step/0` : `/${id}/step/1`), [data, id]);

  const registerClick = () => navigate(path);

  const registerButton = useMemo(() => {
    if (isLanding) {
      return (
        <Box>
          <Button
            variant='outlined'
            onClick={registerClick}
            color={isDarkHeaderColor ? 'secondary' : 'primary'}
            sx={{ textTransform: 'none' }}>
            {formatMessage({ id: 'header.register', defaultMessage: 'Register' })}
          </Button>
        </Box>
      );
    }
    return null;
  }, [isLanding, isDarkHeaderColor]);

  return (
    <HeaderWrapper isLanding={isLanding}>
      {!id || !data || !data.logo ? (
        <Box className='hansefit_logo'>
          <DefaultHansefitLogo color={isLightHeaderColor ? 'white' : theme.palette.primary.main} />
          {registerButton}
        </Box>
      ) : (
        <Box className='logo'>
          <img className='imgLogo' src={data.logo} alt='Company logo' />
          {registerButton}
        </Box>
      )}
    </HeaderWrapper>
  );
};

export default Header;
