import { ReactNode, useCallback, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'store';
import { useCookies } from 'react-cookie';
import deTranslations from '../../../translations/de.json';
import enTranslations from '../../../translations/en.json';
import { LanguageActions } from 'store/language';
import { ToastProvider } from 'components';
import { Helmet } from 'react-helmet';

const IntlProviderLayout = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();
  const [messages, setMessages] = useState({});
  const [loadMessage, setLoadMessage] = useState(true);
  const [initialCookies, setInitialCookies] = useState(true);
  const [cookies, setCookies] = useCookies(['language_app']);

  const communicationLanguage = useAppSelector(state => state.company.data?.communicationLanguage);
  const signupPageTitle = useAppSelector(state => state.company.data?.signupPageTitle) || '';

  const setCurrentCookies = useCallback(() => {
    let currentLanguage = communicationLanguage?.toLowerCase();

    if (!currentLanguage) {
      currentLanguage = navigator.language.toLowerCase().includes('de') ? 'de' : 'en';
    }

    setCookies('language_app', currentLanguage, { path: '/' });
    setInitialCookies(false);
  }, [communicationLanguage, setCookies]);

  useEffect(() => {
    if (cookies?.language_app) {
      setInitialCookies(false);
      return;
    }
    setCurrentCookies();
  }, [cookies?.language_app, setCurrentCookies]);

  useEffect(() => {
    if (communicationLanguage && cookies.language_app !== communicationLanguage.toLowerCase()) {
      setCurrentCookies();
    }
  }, [communicationLanguage, cookies.language_app, setCurrentCookies]);

  const getMessage = useCallback(async () => {
    try {
      if (
        process.env.REACT_APP_TRANSLATE === 'remote' &&
        window.location.hostname !== 'localhost'
      ) {
        setLoadMessage(true);
        const messageResponse = await dispatch(
          LanguageActions.updateLanguage({
            activeLanguage: cookies.language_app,
          }),
        ).unwrap();
        setMessages(messageResponse);
      } else {
        if (cookies?.language_app === 'de') {
          setMessages(deTranslations);
        } else {
          setMessages(enTranslations);
        }
      }
      setInitialCookies(true);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadMessage(false);
    }
  }, [dispatch, cookies.language_app]);

  useEffect(() => {
    if (initialCookies) {
      return;
    }
    if (cookies?.language_app) {
      getMessage();
    }
  }, [cookies?.language_app, getMessage, initialCookies]);

  if (loadMessage || !cookies?.language_app) {
    return null;
  }

  return (
    <IntlProvider
      key={cookies.language_app}
      locale={cookies.language_app}
      messages={messages}
      onError={() => undefined}>
      <ToastProvider />
      <Helmet>
        <title>
          {signupPageTitle ? signupPageTitle : 'Signup page for Hansefit customer portal'}
        </title>
      </Helmet>
      {children}
    </IntlProvider>
  );
};

export default IntlProviderLayout;
