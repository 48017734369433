import { styled } from '@mui/material';

export const FooterWrapper = styled('footer')(({ theme }) => ({
  background: theme.palette.background.paper,
  padding: '26px 16px',
  [theme.breakpoints.down('sm')]: {
    padding: '36px 16px',
  },

  '.container': {
    margin: '0 auto',
    width: '100%',
  },

  '.title': {
    fontFamily: 'NutmegHeadline-UltraBlack',
    textTransform: 'uppercase',
    fontSize: '20px',
    color: theme.palette.neutralDark.contrastText,
    lineHeight: '1.6',
    textAlign: 'center',
  },

  '.description pre': {
    whiteSpace: 'break-spaces',
    fontFamily: 'Roboto-Regular',
    fontSize: '18px',
    color: theme.palette.neutralDark.dark,
    lineHeight: '1.56',
    letterSpacing: '0.17px',
    textAlign: 'center',
    marginTop: '16px',

    '&::first-line': {
      fontFamily: 'NutmegHeadline-UltraBlack',
    },
  },

  '.contactsRow': {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
    margin: '56px auto 100px',
    gap: 36,
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      padding: '0 16px',
      flexDirection: 'column',
    },
  },
  '.contactsItem': {
    fontFamily: 'Roboto-Medium',
    fontSize: '16px',
    color: theme.palette.primary.main,
    letterSpacing: '0.15px',
    textDecoration: 'none',
    '&:first-child': {
      marginLeft: '0px',
    },
  },
  '.logoContainer': {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: 28,
      marginBottom: 32,

      '.link': {
        margin: 0,
      },
    },
  },
  '.footerLogo': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },

    '.link': {
      fontFamily: 'Roboto-Regular',
      fontSize: 14,
      lineHeight: 1.8,
      letterSpacing: 0.13,
      color: theme.palette.neutralDark.main,
      textDecoration: 'unset',

      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
}));
