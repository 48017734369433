import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { ICompaniesState } from 'store/company/interfaces';
import { FetchingStatus } from 'store/interfaces';
import { getUserInfo } from './getUserInfo';

export const getUserExtraReducer = (builder: ActionReducerMapBuilder<ICompaniesState>) => {
  builder.addCase(getUserInfo.pending, (state, action) => {
    state.fetchingStatus = FetchingStatus.PENDING;
    state.error = null;
    state.errorViolations = null;
  });
  builder.addCase(getUserInfo.fulfilled, (state, { payload }) => {
    if (payload && payload.contactInfo) {
      state.userInfo = payload.contactInfo;
    } else {
      state.error = { message: 'invitation link is incorrect' };
    }
    state.fetchingStatus = FetchingStatus.FULFILLED;
  });

  builder.addCase(getUserInfo.rejected, (state, action) => {
    state.error = action.error;
    state.fetchingStatus = FetchingStatus.REJECTED;
  });
};
