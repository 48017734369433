// @ts-nocheck

import { useAppSelector } from 'store';
import { default as theme } from 'theme/theme';

import LandingWrapper from './Landing.styles';
import {
  Headline,
  SingleImage,
  TextArea,
  ButtonArea,
  ColumnsArea,
  ImageCollection,
  Video,
  StudioList,
} from './components';
import { getCDNVideoLink } from 'utils/getCDNVideoLink';
import { useEffect, useState } from 'react';
import { FetchingStatus } from 'store/interfaces';
import { CompanySelectors } from 'store/company/company.selectors';
import { useNavigate, useParams } from 'react-router';
import LightboxPopup from './components/LightboxPopup';
import AccordionBlock from './components/AccordionBlock';
import LandingFooter from './components/LandingFooter';
import SignUpBox from './components/SignUpBox';
import HeadlineWithBackground from './components/HeadlineWithBackground';
import ImageBanner from './components/ImageBanner';
import LandingBox from './components/LandingBox';

const Landing = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [toggleLightbox, setToggleLightbox] = useState(false);
  const [lightboxPath, setLightboxPath] = useState('');

  const data = useAppSelector(state => state.company.data);
  const userVouchers = useAppSelector(CompanySelectors.getVouchers);

  useEffect(() => {
    if (userVouchers.fetchingStatus === FetchingStatus.FULFILLED && userVouchers.data) {
      if (userVouchers.data.valid.length === 0) {
        navigate(`/${id}/step/0`);
      }
    }
  }, [id, navigate, userVouchers]);

  if (!data) {
    return null;
  }
  const { signupPagePhone, signupPageEmail } = data;

  const getColumnsColor = (columnItem: any) => {
    const firstFoundElementWithColor = columnItem.columns.find(
      (item: any) => item[0] && item[0].backgroundColor,
    );
    if (firstFoundElementWithColor && firstFoundElementWithColor[0]) {
      return firstFoundElementWithColor[0].backgroundColor;
    }
    return theme.palette.background.paper;
  };

  return (
    <LandingWrapper>
      {toggleLightbox && (
        <LightboxPopup path={lightboxPath} handleClose={() => setToggleLightbox(false)} />
      )}
      {data.landingPage &&
        data.landingPage.map((item, index) => {
          switch (item.blockName) {
            case 'signup-landing-page-header': {
              return (
                <HeadlineWithBackground
                  key={index}
                  backgroundImage={item.backgroundImage}
                  children={item.children}
                />
              );
            }
            case 'signup-landing-page-headline': {
              return (
                <Headline
                  key={index}
                  placement={item.placement}
                  text={item.text}
                  backgroundColor={item.backgroundColor || theme.palette.background.paper}
                />
              );
            }
            case 'signup-landing-page-sign-up-box': {
              return (
                <SignUpBox
                  key={index}
                  placement={item.placement}
                  badge={item.label}
                  text={item.text}
                  enableInfoBox={item.enableInfoBox}
                  info={item.info}
                  note={item.note}
                  backgroundColor='#FFF'
                  button={item.button}
                />
              );
            }
            case 'signup-landing-page-image-banner': {
              return (
                <ImageBanner
                  key={index}
                  backgroundColor={item.backgroundColor}
                  headline={item.headline}
                  headlineImage={item.image.url}
                  items={item.items}
                />
              );
            }
            case 'signup-landing-page-video': {
              return (
                <Video
                  key={index}
                  placement={item.placement}
                  path={getCDNVideoLink(item.video)}
                  backgroundColor={item.backgroundColor || theme.palette.background.paper}
                  type={item.video.type}
                  poster={item.video.poster || ''}
                  title={item.video.title || ''}
                  description={item.video.description || ''}
                />
              );
            }
            case 'signup-landing-page-image': {
              return (
                <SingleImage
                  key={index}
                  placement={item.placement}
                  imageUrl={item.image.url}
                  backgroundColor={item.backgroundColor || theme.palette.background.paper}
                  imageWidth={item.width}
                  linkUrl={item.link?.path || ''}
                  linkTarget={item.link?.target || ''}
                />
              );
            }
            case 'signup-landing-page-text': {
              return (
                <TextArea
                  key={index}
                  placement={item.placement}
                  text={item.text}
                  backgroundColor={item.backgroundColor || theme.palette.background.paper}
                />
              );
            }
            case 'signup-landing-page-button': {
              return (
                <ButtonArea
                  key={index}
                  placement={item.placement}
                  text={item.text}
                  link={item.link.path}
                  style={item.style}
                  target={item.link.target}
                  handlePopup={() => {
                    setToggleLightbox(true);
                    setLightboxPath(item.link.path);
                  }}
                  backgroundColor={item.backgroundColor || theme.palette.background.paper}
                />
              );
            }
            case 'signup-landing-page-columns': {
              return (
                <ColumnsArea
                  key={index}
                  placement={item.placement}
                  columns={item.columns}
                  backgroundColor={item.backgroundColor}
                />
              );
            }
            case 'signup-landing-page-image-collection': {
              return (
                <ImageCollection
                  key={index}
                  placement={item.placement}
                  images={item.images}
                  backgroundColor={item.backgroundColor || theme.palette.background.paper}
                  type={item.type}
                />
              );
            }
            case 'signup-landing-page-list': {
              return (
                <StudioList
                  key={index}
                  placement={item.placement}
                  backgroundColor={item.backgroundColor || theme.palette.background.paper}
                  items={item.items}
                  headline={item.headline}
                />
              );
            }
            case 'signup-landing-page-accordion': {
              return (
                <AccordionBlock
                  key={index}
                  items={item.items}
                  headline={item.headline}
                  backgroundColor={item.backgroundColor || theme.palette.background.paper}
                />
              );
            }
            case 'signup-landing-page-box-with-button': {
              return (
                <LandingBox
                  key={index}
                  backgroundColor={item.backgroundColor}
                  boxBackground={item.boxColor}
                  text={item.text}
                  button={item.button}
                  isVertical={item.isVertical}
                />
              );
            }

            default: {
              return null;
            }
          }
        })}
      <LandingFooter signupPagePhone={signupPagePhone} signupPageEmail={signupPageEmail} />
    </LandingWrapper>
  );
};

export default Landing;
