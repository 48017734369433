import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosWithConfig } from 'api';
import { UserManagementBaseResponse } from 'api/interfaces';
import axios, { AxiosError } from 'axios';
import { ICompaniesState, IUserInfo } from 'store/company/interfaces';
import showToast from 'utils/showToast';

export const sendUserData = createAsyncThunk<any, any>(
  'company/sendUserData',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { company } = getState() as { company: ICompaniesState };

      if (company.data && company.userInfo) {
        const phone = company.userInfo.phoneNumber
          ? company.userInfo.phoneNumber.replaceAll('/', '-').trim()
          : '';

        let endpoint;

        if (company.userInfo.eu_id?.length) {
          endpoint = `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/companies/sp-settings/${company.data.companyId}/endusers/${company.userInfo.eu_id}`;
        } else {
          endpoint = `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/companies/sp-settings/${company.data.companyId}/endusers`;
        }

        const globalCustomFields = {} as IUserInfo['globalCustomFields'];
        if (company?.userInfo?.globalCustomFields) {
          Object.keys(company.userInfo.globalCustomFields).forEach(key => {
            if (key === 'hansefit_street') {
              globalCustomFields[key] = `${company.userInfo?.globalCustomFields[key] || ''} ${
                company.userInfo?.globalCustomFields['hansefit_houseNumber'] || ''
              }`.trim();
            } else if (key !== 'hansefit_houseNumber') {
              globalCustomFields[key] = company.userInfo?.globalCustomFields[key]?.trim() || '';
            }
          });
        }

        // TODO: Type this correctly
        let customFields: any = undefined;
        if (company.signupId?.toLowerCase() === 'profit') {
          customFields = {
            ...(company?.userInfo?.customFields ? company?.userInfo?.customFields : {}),
            voucher_code: company.vouchers.data?.valid.map(el => el.voucher.code).toString(),
            voucher_value: Number(company.vouchers.data?.totalVoucherValue),
          };
        }

        const isIbanRequired = company.data.proFit
          ? company.vouchers.data?.payableAmount.toString() !== '0' &&
            !company.userInfo.paymentData?.ibanPrefilled
          : !company.userInfo.paymentData?.ibanPrefilled;

        const ibanData =
          company.userInfo.paymentData && isIbanRequired && company.userInfo.paymentData?.iban
            ? {
                iban: company.userInfo.paymentData.iban.replaceAll(' ', ''),
                bic: company.userInfo.paymentData.bic,
                accountHolderName: company.userInfo.paymentData.account_holder.trim(),
              }
            : {};

        const postData = {
          signupId: company.signupId,
          firstName: company.userInfo.firstName.trim(),
          lastName: company.userInfo.lastName.trim(),
          businessEmail: company.userInfo.businessEmail || null,
          gender: company.userInfo.gender,
          dateOfBirth: company.userInfo.dateOfBirth,
          startDate: company.userInfo.startDate,
          phoneNumber: phone,
          customFields: customFields ? customFields : company.userInfo.customFields,
          globalCustomFields,
          sepa: company.userInfo.paymentData
            ? {
                ...ibanData,
                street: company.userInfo.paymentData.street?.trim() || '',
                houseNumber: company.userInfo.paymentData.houseNumber?.trim() || '',
                city: company.userInfo.paymentData.city?.trim() || '',
                zipcode: company.userInfo.paymentData.zip?.trim() || '',
                country: company.userInfo.paymentData.country || 'DE',
              }
            : null,
          companyId: company.data.companyId,
        };

        if (company.userInfo.title) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          postData['title'] = company.userInfo.title;
        }
        if (company.userInfo.eu_id?.length) {
          await axios.patch(endpoint, postData, {
            headers: {
              'content-type': 'application/merge-patch+json',
            },
          });
        } else await axios.post(endpoint, postData);
        return {};
      }
    } catch (e: any) {
      const error = e as AxiosError<UserManagementBaseResponse>;
      console.error(e);

      if (Number(error.response?.status) === 500) {
        showToast('error', {
          id: 'toast.signup.creation_user.error_500',
          defaultMessage:
            'Please try again later. If it does not work then, reach out to your Hansefit representative.',
        });
      } else if (Number(error.response?.status) === 400 && error.response?.data?.invalid) {
        //do nothing
      } else if (Number(error.response?.status) !== 422) {
        showToast(
          'error',
          {
            id: 'toast.signup.creation_user.error',
            defaultMessage: 'User could not be created',
          },
          error.response?.data?.detail,
          -1,
        );
      }

      return rejectWithValue(error.response?.data);
    }
  },
);

export default sendUserData;
