import { styled } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { default as theme } from 'theme/theme';

export const ToastWrapper = styled(ToastContainer)(() => ({
  width: 'auto',

  '.Toastify__toast': {
    padding: 0,
    minHeight: 'auto',
    boxShadow: `0 4px 10px 0 ${theme.palette.transparent.boxShadowDark01}`,
  },

  '.Toastify__toast-body': {
    margin: 0,
    padding: 0,
  },

  '.toast__wrapper_content': {
    gap: 12,
    alignItems: 'center',
    padding: '9px 16px 9px 12px',
    background: theme.palette.background.paper,

    '.MuiAlert-icon': {
      padding: 0,
      margin: 0,
    },

    '.MuiAlert-message': {
      padding: 0,
      fontFamily: 'Roboto-Regular',
      fontSize: 16,
      lineHeight: 1.38,
      color: theme.palette.neutralDark.contrastText,

      span: {
        fontFamily: 'Roboto-Bold',
        fontWeight: 'bold',
      },
    },
  },
}));
