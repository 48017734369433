import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { BaseSetting } from 'features/landing/interfaces';
import { ReactComponent as InsertPhotoIcon } from 'assets/icons/atoms-icon-image.svg';
import { useNavigate, useParams } from 'react-router';
import { default as theme } from 'theme/theme';

const SingleImage: React.FC<
  BaseSetting & {
    imageUrl: string;
    internal?: boolean;
    imageWidth?: string;
    linkUrl?: string;
    linkTarget?: string;
    onlyImage?: boolean;
  }
> = ({
  imageUrl,
  backgroundColor,
  placement,
  internal = false,
  imageWidth = 'auto',
  linkUrl,
  linkTarget = '_blank',
  onlyImage = false,
}) => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const removeFirstSlash = (url: string) => (url.startsWith('/') ? url.substr(1) : url);
  const goToPage = () => {
    if (linkUrl) {
      if (linkUrl.includes('://')) {
        window.open(linkUrl, linkTarget || '_blank');
      } else {
        if (!linkTarget || linkTarget !== '_blank') {
          navigate(
            id && linkUrl.includes(id)
              ? `/${removeFirstSlash(linkUrl)}`
              : `/${id}/${removeFirstSlash(linkUrl)}`,
          );
        } else {
          window.open(
            `${window.location.origin}/${removeFirstSlash(linkUrl)}`,
            linkTarget || '_blank',
          );
        }
      }
    }
  };

  const styles = useMemo(() => {
    if (onlyImage) {
      return {
        borderRadius: '8px',
        border: `1px solid #CBD5E1`,
      };
    }
    return {};
  }, [onlyImage]);

  return (
    <Box
      className={internal ? '' : 'main-style'}
      sx={{ backgroundColor }}
      data-testid='landing-item'>
      <Box className='limit-width' textAlign={placement}>
        {imageUrl ? (
          <img
            src={imageUrl}
            style={{
              ...styles,
              ...{ maxWidth: '100%', width: imageWidth, cursor: linkUrl ? 'pointer' : 'default' },
            }}
            onClick={goToPage}
            alt='partner'
          />
        ) : (
          <Box className='landing-empty-image'>
            <InsertPhotoIcon
              fontSize='inherit'
              fill={theme.palette.primary.main}
              style={{
                cursor: linkUrl ? 'pointer' : 'default',
              }}
              onClick={goToPage}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SingleImage;
