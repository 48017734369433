import { rangeValidation } from 'utils/dateRangeValidation';
import yup from 'utils/yupIBAN';

export const contactSchema = (
  hasPaymentData: boolean,
  startDateLimits?: { min: Date; max: Date } | undefined,
) => {
  const iban = hasPaymentData
    ? { iban: yup.string() }
    : {
        iban: yup
          .string()
          .required('form.employee.error.iban.required')
          .iban('form.employee.error.iban.invalid'),
      };

  const bic = hasPaymentData
    ? { bic: yup.string() }
    : { bic: yup.string().required('form.employee.error.bic.required') };

  const startDate = startDateLimits
    ? {
        startDate: yup
          .date()
          .typeError('form.employee.error.service_date.invalid')
          .nullable()
          .test('isValid', 'form.employee.error.service_date.invalid_range', (val, context) =>
            rangeValidation(val, startDateLimits),
          )
          .required('form.employee.error.service_date.required'),
      }
    : { startDate: yup.date() };
  return yup
    .object({
      account_holder: yup.string().when('accountHolderIsContractHolder', {
        is: false,
        then: yup.string().required('form.employee.error.accountHolderName.required'),
      }),
      street: yup.string().required('form.employee.error.street.required'),
      houseNumber: yup.string().required('form.employee.error.houseNumber.required'),
      city: yup.string().required('form.employee.error.city.required'),
      zip: yup.string().required('form.employee.error.zip.required'),
      country: yup.string().when('billingAddressIsContactAddress', {
        is: false,
        then: yup.string().required('form.employee.error.country.required'),
      }),
      confirmSepaCredentials: yup.boolean().oneOf([true], 'form.employee.error.checkbox.required'),
      ...iban,
      ...bic,
      ...startDate,
    })
    .required();
};

export default contactSchema;
