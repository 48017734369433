import { Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { useAppSelector } from 'store';
import { CompanySelectors } from 'store/company/company.selectors';
import { default as theme } from 'theme/theme';

interface StepperProps {
  hasError?: boolean;
}

const Stepper: React.FC<StepperProps> = ({ hasError }) => {
  const intl = useIntl();
  const { stepNumber } = useParams();
  const company = useAppSelector(CompanySelectors.getCompanyData);
  const paymentValue = useAppSelector(state => state.company.vouchers.data?.payableAmount);

  const noBankInfo = company && company.paymentProviders?.length !== 0;
  const hasVoucher = company?.proFit;
  const hasStep3 = hasVoucher ? noBankInfo && paymentValue?.toString() !== '0' : noBankInfo;

  const showCheckIcon = (currentStep: number) =>
    Number(stepNumber) > currentStep || stepNumber === 'summary';

  return (
    <Box className='steps'>
      {!hasVoucher && (
        <>
          <Box className={`steps__item ${stepNumber === '1' ? 'steps__item_active' : ''}`}>
            {showCheckIcon(1) && <CheckCircleIcon htmlColor={theme.palette.success.main} />}
            <b>1</b>
            <span>
              {intl.formatMessage({
                id: 'step1.title',
                defaultMessage: 'Add personal info',
              })}
            </span>
          </Box>
          <Box className={`steps__item ${stepNumber === '2' ? 'steps__item_active' : ''}`}>
            {showCheckIcon(2) && <CheckCircleIcon htmlColor={theme.palette.success.main} />}
            <b>2</b>
            <span>
              {intl.formatMessage({
                id: 'step2.title',
                defaultMessage: 'Add contact info',
              })}
            </span>
          </Box>
          {hasStep3 && (
            <Box className={`steps__item ${stepNumber === '3' ? 'steps__item_active' : ''}`}>
              {showCheckIcon(3) && <CheckCircleIcon htmlColor={theme.palette.success.main} />}
              <b>3</b>
              <span>
                {intl.formatMessage({
                  id: 'step3.title',
                  defaultMessage: 'Payment method',
                })}
              </span>
            </Box>
          )}
          <Box className={`steps__item ${stepNumber === 'summary' ? 'steps__item_active' : ''}`}>
            <b>{hasStep3 ? '4' : '3'}</b>
            <span>
              {intl.formatMessage({
                id: 'step4.title',
                defaultMessage: 'Summary',
              })}
            </span>
          </Box>
          <Box className='steps__back' />
        </>
      )}
      {hasVoucher && (
        <>
          <Box
            className={`steps__item ${stepNumber === '0' ? 'steps__item_active' : ''} ${
              hasError && stepNumber === '0' && 'steps__item_error'
            }`}>
            {showCheckIcon(0) && <CheckCircleIcon htmlColor={theme.palette.success.main} />}
            {hasError && stepNumber === '0' && (
              <Box className='container-icon'>
                {' '}
                <ErrorIcon fontSize='small' color='error' />
              </Box>
            )}
            <b>1</b>
            <span>
              {intl.formatMessage({
                id: 'step0.title',
                defaultMessage: 'Voucher code',
              })}
            </span>
          </Box>
          <Box className={`steps__item ${stepNumber === '1' ? 'steps__item_active' : ''}`}>
            {showCheckIcon(1) && <CheckCircleIcon htmlColor={theme.palette.success.main} />}
            <b>2</b>
            <span>
              {intl.formatMessage({
                id: 'step1.voucher.title',
                defaultMessage: 'Contract data',
              })}
            </span>
          </Box>
          {hasStep3 && (
            <Box className={`steps__item ${stepNumber === '2' ? 'steps__item_active' : ''}`}>
              {showCheckIcon(3) && <CheckCircleIcon htmlColor={theme.palette.success.main} />}
              <b>3</b>
              <span>
                {intl.formatMessage({
                  id: 'step3.title',
                  defaultMessage: 'Payment method',
                })}
              </span>
            </Box>
          )}
          <Box
            className={`steps__item ${stepNumber === 'summary' ? 'steps__item_active' : ''} ${
              hasError && stepNumber === 'summary' && 'steps__item_error'
            }`}>
            {hasError && stepNumber === 'summary' && (
              <Box className='container-icon'>
                {' '}
                <ErrorIcon fontSize='small' color='error' />
              </Box>
            )}
            <b>{hasStep3 ? '4' : '3'}</b>
            <span>
              {intl.formatMessage({
                id: 'step4.title',
                defaultMessage: 'Summary',
              })}
            </span>
          </Box>
          <Box className='steps__back' />
        </>
      )}
    </Box>
  );
};

export default Stepper;
