import Amplify from 'aws-amplify';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import 'react-toastify/dist/ReactToastify.css';

import { store, persistor } from 'store/store';

import awsConfig from './aws-exports';
import './assets/fonts/stylesheet.css';
import ThemeProviderWrapper from 'components/ThemeProvider';

Amplify.configure(awsConfig);

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProviderWrapper />
    </PersistGate>
  </Provider>
);

export default App;
