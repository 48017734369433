import contactSchema from 'features/stepper/components/StepTwo/contactSchema';
import { ICompanyState, ICustomField, IUserInfo } from 'store/company/interfaces';
import * as yup from 'yup';

function getContactsSchema(
  company: ICompanyState | null,
  violationErrors: any,
  userInfo: IUserInfo | null,
  requiredCustomFields: boolean,
  startDateLimits?: { min: Date; max: Date } | undefined,
) {
  let newSchema: any = {};
  let EditEmployeeSchemaShaped = contactSchema(
    company?.validEmailDomains,
    {
      error: violationErrors?.find((item: any) => item.propertyPath === 'businessEmail'),
      emailVal: userInfo?.businessEmail || '',
    },
    company?.activationLetterRecipientMode === 'eu',
    startDateLimits,
  );

  const getRequiredError = (field: ICustomField, yupObj: yup.StringSchema) =>
    field.mandatory === 'yes'
      ? yupObj.required('form.employee.error.custom_field.required')
      : yupObj;

  if (requiredCustomFields) {
    const mandatoryCustomField = company?.customFields;
    if (mandatoryCustomField && mandatoryCustomField.length) {
      mandatoryCustomField.forEach(field => {
        if (field.inputType === 'integer')
          newSchema[field.key] = getRequiredError(
            field,
            yup
              .string()
              .test('is-integer', 'form.employee.error.custom_field.invalid_integer', value => {
                if (value) {
                  return /^\d+$/.test(value);
                } else return true;
              }),
          );
        else if (field.inputType === 'float')
          newSchema[field.key] = getRequiredError(
            field,
            yup
              .string()
              .test('is-decimal', 'form.employee.error.custom_field.invalid_decimal', value => {
                if (value) {
                  return /^\d*[\.{1}\d*]\d*$/.test(value);
                } else return true;
              }),
          );
        else newSchema[field.key] = getRequiredError(field, yup.string()).nullable();
      });
      EditEmployeeSchemaShaped = EditEmployeeSchemaShaped.shape({
        customFields: yup.object().shape({ ...newSchema }),
      });
    }
    const mandatoryGlobalCustomField = company?.globalCustomFields.filter(
      field => field.mandatory === 'yes',
    );
    if (mandatoryGlobalCustomField && mandatoryGlobalCustomField.length) {
      newSchema = {};
      mandatoryGlobalCustomField.forEach(field => {
        newSchema[field.key] = yup.string().required('form.employee.error.custom_field.required');
      });
      EditEmployeeSchemaShaped = EditEmployeeSchemaShaped.shape({
        globalCustomFields: yup.object().shape({ ...newSchema }),
      });
    }
  }

  return EditEmployeeSchemaShaped;
}

export default getContactsSchema;
