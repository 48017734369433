import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { ICompaniesState } from 'store/company/interfaces';
import { verifyVoucher } from './verifyVoucher';
import { FetchingStatus } from 'store/interfaces';
import { initialState } from 'store/company/initialState';

export const verifyVoucherExtraReducer = (builder: ActionReducerMapBuilder<ICompaniesState>) => {
  builder.addCase(verifyVoucher.pending, (state, action) => {
    state['invalidSubmittedVouchers'] = [];
    state.vouchers.fetchingStatus = FetchingStatus.PENDING;
    state.vouchers.error = null;
  });

  builder.addCase(verifyVoucher.fulfilled, (state, { payload }) => {
    state.vouchers.fetchingStatus = FetchingStatus.FULFILLED;
    state.vouchers.data = payload;
    state.vouchers.error = null;
  });

  builder.addCase(verifyVoucher.rejected, (state, { payload }) => {
    const errorObj = payload as any;
    state.vouchers.fetchingStatus = FetchingStatus.REJECTED;
    state.vouchers.data = errorObj?.violations ? initialState.vouchers.data : errorObj;
    state.vouchers.error = errorObj?.violations
      ? errorObj?.violations
      : { message: errorObj.invalid[0].error };
  });
};
