import { styled } from '@mui/material';

const HeaderWrapper = styled('header')<{ isLanding: boolean }>(({ theme, isLanding }) => ({
  background: theme.palette.primary.light,
  height: '80px',
  padding: '0 24px',

  '.colorSchema,.logo': {
    fontFamily: 'Roboto-Regular',
    fontSize: '14px',
    textTransform: 'uppercase',
  },
  '.colorSchema': {
    color: theme.palette.neutralLight.dark,
    padding: '32px',
  },
  '.hansefit_logo': {
    display: 'flex',
    alignItems: 'center',
    gap: 15,
    height: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      justifyContent: isLanding ? 'space-between' : 'center',
    },
  },
  '.logo': {
    width: isLanding ? 'auto' : '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      justifyContent: isLanding ? 'space-between' : 'center',
    },
    '.imgLogo': {
      height: '100%',
      maxHeight: '80px',
      padding: '10px 0',
    },
  },
}));

export default HeaderWrapper;
