import { styled, Dialog } from '@mui/material';

export const ModalWrapper = styled(Dialog)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: theme.palette.transparent.blue07,
  },
  '& .MuiPaper-root': {
    maxWidth: 'unset',
    width: 620,
    minWidth: 620,
    boxShadow: `0 10px 10px 0 ${theme.palette.transparent.boxShadowDark01}`,
    [theme.breakpoints.down('sm')]: {
      minWidth: 340,
    },

    form: {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: 'calc(100% - 64px)',
      overflow: 'hidden',
    },
    '& .MuiDialogTitle-root': {
      padding: '36px 48px',
      paddingBottom: 0,
      lineHeight: 1.6,
      textAlign: 'left',
      '&.borderActive': {
        borderBottom: `1px solid ${theme.palette.neutralLight.dark}`,
      },
    },
    '& .MuiDialogContent-root': {
      padding: '0 48px',
    },
    '& .MuiDialogActions-root': {
      display: 'flex',
      flexWrap: 'wrap',
      padding: '0 48px',
      gap: 16,
      '&>:not(:first-of-type)': {
        marginLeft: 'unset',
      },
    },
  },

  '& button': {
    borderRadius: 24,
    fontFamily: 'Roboto-Medium',
    border: `2px solid ${theme.palette.primary.main}`,
    fontSize: 16,
    padding: '4px 16px',
    textTransform: 'inherit',
    minWidth: 146,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
    },

    '&:hover': {
      border: `2px solid ${theme.palette.primary.main}`,
    },
    '&:disabled': {
      border: `2px solid ${theme.palette.transparent.boxShadowDark01}`,
    },
  },

  '& .icon-clear': {
    '&:hover': {
      color: theme.palette.neutralDark.main,
      cursor: 'pointer',
    },
  },
}));
