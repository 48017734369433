import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { IAccordionItem } from 'store/company/interfaces';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextArea from '../TextArea';
import AccordionBlockWrapper from './AccordionBlock.styles';
import Spacer from 'components/Spacer';
import { useState } from 'react';
import { default as theme } from 'theme/theme';

const AccordionBlock: React.FC<{
  items: IAccordionItem[];
  headline: string;
  backgroundColor?: string;
}> = ({ items, headline, backgroundColor = theme.palette.background.paper }) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleAccordion =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  return (
    <AccordionBlockWrapper background={backgroundColor} className='main-style '>
      <Box className='limit-width'>
        <Spacer height={24} />
        <Typography variant='h4' className='accordion-title'>
          {headline}
        </Typography>
        <Spacer height={24} />
        <Box>
          {items.map((element, index) => (
            <Accordion
              key={index}
              elevation={0}
              disableGutters
              className='MuiAccordion-root'
              expanded={expanded === `panel${index}`}
              onChange={handleAccordion(`panel${index}`)}>
              <AccordionSummary className='limit-width' expandIcon={<ExpandMoreIcon />}>
                <TextArea placement='left' text={element.title} backgroundColor='transparent' />
              </AccordionSummary>
              <AccordionDetails className='accordion-text'>
                <TextArea placement='left' text={element.text} backgroundColor='transparent' />
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
        <Spacer height={24} />
      </Box>
    </AccordionBlockWrapper>
  );
};

export default AccordionBlock;
