import { Box, Button, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { default as theme } from 'theme/theme';
import { useWindowSize } from 'usehooks-ts';
import { sanitizeText } from 'utils/sanitizeText';

const LandingBox: React.FC<{
  text: string;
  button: {
    text: string;
    style: 'primary' | 'secondary' | 'link';
    backgroundColor: string;
    placement: string;
    link: {
      path: string;
      target: '_blank' | '_self' | '_top' | '_parent' | 'lightbox';
    };
  };
  boxBackground?: string;
  backgroundColor?: string;
}> = ({
  text,
  button,
  backgroundColor = theme.palette.background.paper,
  boxBackground = theme.palette.background.paper,
}) => {
  const windowSize = useWindowSize();
  const santinizedText = useMemo(() => sanitizeText(text), [text]);
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const removeFirstSlash = (url: string) => (url.startsWith('/') ? url.substr(1) : url);
  const goToPage = () => {
    if (button.link.path.includes('://')) {
      window.open(button.link.path, button.link.target || '_blank');
    } else {
      if (!button.link.target || button.link.target !== '_blank') {
        navigate(
          id && button.link.path.includes(id)
            ? `/${removeFirstSlash(button.link.path)}`
            : `/${id}/${removeFirstSlash(button.link.path)}`,
        );
      } else {
        window.open(
          `${window.location.origin}/${removeFirstSlash(button.link.path)}`,
          button.link.target || '_blank',
        );
      }
    }
  };

  return (
    <Box className='main-style' sx={{ backgroundColor }} data-testid='landing-item'>
      <Box
        className='limit-width'
        sx={{
          padding: '24px',
          backgroundColor: boxBackground,
          borderRadius: '8px',
          display: 'flex',
          flexDirection: windowSize.width < theme.breakpoints.values.sm ? 'column' : 'row',
          alignItems: windowSize.width < theme.breakpoints.values.sm ? 'flex-start' : 'center',
          justifyContent:
            windowSize.width < theme.breakpoints.values.sm ? 'space-around' : 'space-between',
          gap: windowSize.width < theme.breakpoints.values.sm ? '8px' : '60px',
        }}>
        <Box flex='1'>
          <Typography
            variant='body1'
            className='text-area'
            dangerouslySetInnerHTML={{
              __html: santinizedText,
            }}
          />
        </Box>
        {button.text ? (
          button.style === 'link' ? (
            <Button variant='text' className='link-button' onClick={goToPage}>
              {button.text}
            </Button>
          ) : (
            <Button
              variant={button.style === 'primary' ? 'contained' : 'outlined'}
              onClick={goToPage}>
              {button.text}
            </Button>
          )
        ) : null}
      </Box>
    </Box>
  );
};

export default LandingBox;
