import axios, { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { axiosWithConfig } from 'api';
import { UserManagementBaseResponse } from 'api/interfaces';

export const getUserInfo = createAsyncThunk<any, any>(
  'company/getUserInfo',
  async ({ id, token }, { rejectWithValue }) => {
    const endpoint =
      process.env.REACT_APP_PIMCORE_ENDPOINT + `api/companies/sp-settings/identifiers/${id}`;

    try {
      const { data } = await axios.get(endpoint, { params: { invitationToken: token } });

      return data[0];
    } catch (e) {
      const error: AxiosError<UserManagementBaseResponse> =
        e as AxiosError<UserManagementBaseResponse>;
      return rejectWithValue(error.response?.data);
    }
  },
);
