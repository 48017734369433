import { Box, MenuItem, Typography } from '@mui/material';
import CCPSelect from 'components/FormComponents/Select';
import { useIntl } from 'react-intl';
import { useAppSelector } from 'store';
import { CompanySelectors } from 'store/company/company.selectors';
import { isDisabledField } from 'utils/disablePrefilledFields';
import { isHiddenField } from 'utils/hidePrefilledFields';
import CCPTextField from 'components/FormComponents/TextField';
import Spacer from 'components/Spacer';
import { ICountriesList, ICustomField } from 'store/company/interfaces';
import { globalCustomFieldsSort } from 'utils/hardcodedData';
import { distinctArrayValues } from 'utils/distinctArrayValues';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import CCPAutocomplete from 'components/FormComponents/CCPAutocomplete';
import { useFormContext } from 'react-hook-form';

interface ContactInfoProps {
  hasVoucher: boolean;
  minimumDate?: Date;
  maximumDate?: Date;
}

const ContactInfo: React.FC<ContactInfoProps> = ({ hasVoucher }) => {
  const intl = useIntl();
  const currentLanguage = useCurrentLanguage();
  const { control, setValue, getValues, trigger } = useFormContext();

  const company = useAppSelector(CompanySelectors.getCompanyData);
  const userInfo = useAppSelector(CompanySelectors.getUserInfo);
  const disabledFields = useAppSelector(CompanySelectors.getDisabledFields) || [];
  const formHiddenFields = useAppSelector(CompanySelectors.getHiddenFields) || [];
  const fullHiddenFields = useAppSelector(CompanySelectors.getFullHiddenFields) || [];
  const hiddenFields = distinctArrayValues(formHiddenFields, fullHiddenFields);

  const countries = useAppSelector(state => state.company.countriesList.data) || [];

  let dialCodes: any[] = [];

  const prioritiesGroup = countries.reduce(function (arr: any, a: ICountriesList) {
    arr[a.priority] = arr[a.priority] || [];
    arr[a.priority].push(a);
    return arr;
  }, Object.create(null));

  let sortedCountries: ICountriesList[] = [];

  Object.keys(prioritiesGroup).forEach(group => {
    prioritiesGroup[group] = prioritiesGroup[group].sort((a: ICountriesList, b: ICountriesList) =>
      a.name[currentLanguage || 'en'].localeCompare(b.name[currentLanguage || 'en']),
    );
    sortedCountries = [...sortedCountries, ...prioritiesGroup[group]];
    prioritiesGroup[group] = prioritiesGroup[group].sort(
      (a: ICountriesList, b: ICountriesList) => Number(a.callingCode) - Number(b.callingCode),
    );
    dialCodes = [...dialCodes, ...prioritiesGroup[group]];
  });

  const getSecondElemKey = (secondElem: ICustomField) =>
    secondElem ? secondElem.key : 'hansefit_houseNumber';

  const blurBusinessEmail = () => {
    if (company?.validEmailDomains) {
      trigger('businessEmail');
    }
  };

  return (
    <Box className='main-form'>
      {hasVoucher && (
        <>
          <Typography variant='subtitle2'>
            {intl.formatMessage({
              id: 'step1.subsection.headline.contact_info',
              defaultMessage: 'Contact information',
            })}
          </Typography>
          <Spacer height={20} />
        </>
      )}
      <CCPTextField
        control={control}
        disabled={isDisabledField('businessEmail', disabledFields, userInfo)}
        hidden={isHiddenField('businessEmail', hiddenFields, userInfo)}
        name='businessEmail'
        onBlur={blurBusinessEmail}
        label={
          <span>
            {`${intl.formatMessage({
              id: 'form.employee.field.email.label',
              defaultMessage: 'Corporate email',
            })}${company?.activationLetterRecipientMode === 'eu' ? '*' : ''}`}{' '}
          </span>
        }
      />
      <Spacer height={6} />
      <Typography variant='h5' fontSize={14}>
        {intl.formatMessage({
          id: 'form.employee.field.email.help_description',
          defaultMessage: 'Your employer can see this address.',
        })}
      </Typography>
      <Spacer height={30} />
      <CCPTextField
        control={control}
        disabled={isDisabledField('businessEmail', disabledFields, userInfo)}
        hidden={isHiddenField('businessEmail', hiddenFields, userInfo)}
        name='confirmEmail'
        onPaste={event => event.preventDefault()}
        label={
          <span>
            {`${intl.formatMessage({
              id: 'form.employee.field.confirm_email.label',
              defaultMessage: 'Confirm email',
            })}${company?.activationLetterRecipientMode === 'eu' ? '*' : ''}`}{' '}
          </span>
        }
      />
      <Spacer height={6} />
      <Typography variant='h5' fontSize={14}>
        {intl.formatMessage({
          id: 'form.employee.field.confirm_email.help_description',
          defaultMessage: 'Enter the email address again.',
        })}
      </Typography>
      {company?.activationLetterRecipientMode === 'eu' && (
        <>
          <Spacer height={8} />
          <Typography variant='body1' fontSize={14} fontWeight='bold'>
            {intl.formatMessage({
              id: 'form.employee.field.email.helper_label',
              defaultMessage:
                'Your activation information for the app will be send to this e-mail.',
            })}
          </Typography>
        </>
      )}

      {!hasVoucher && (
        <>
          <Spacer height={30} />
          <Box className='phone-content'>
            <CCPSelect
              control={control}
              disabled={isDisabledField('phoneNumber', disabledFields, userInfo)}
              hidden={isHiddenField('phoneNumber', hiddenFields, userInfo)}
              name='phoneNumberPrefix'
              label={intl.formatMessage({
                id: 'form.employee.field.phone_prefix.label',
                defaultMessage: 'Prefix',
              })}>
              {dialCodes.map(({ callingCode }) => (
                <MenuItem
                  key={callingCode}
                  value={callingCode}
                  selected={callingCode === getValues('phoneNumberPrefix')}>
                  {callingCode}
                </MenuItem>
              ))}
            </CCPSelect>
            <CCPTextField
              control={control}
              disabled={isDisabledField('phoneNumber', disabledFields, userInfo)}
              hidden={isHiddenField('phoneNumber', hiddenFields, userInfo)}
              name='phoneNumber'
              label={intl.formatMessage({
                id: 'form.employee.field.phone_number.label',
                defaultMessage: 'Phone',
              })}
            />
          </Box>
        </>
      )}
      {company && (company.globalCustomFields?.length > 0 || company.customFields?.length > 0) && (
        <Spacer height={48} />
      )}
      {company && company.globalCustomFields?.length > 0 && (
        <Box className='personalInfo'>
          {company.globalCustomFields.some(item => item.isAddressRelevant === 'yes') && (
            <Box>
              <Typography className='subtitle' variant='body1' fontSize='16px'>
                {intl.formatMessage({
                  id: 'form.employee.field.global_address.label',
                  defaultMessage: 'Address',
                })}
                :
              </Typography>
              <Typography variant='h5'>
                {intl.formatMessage({
                  id: 'form.employee.field.global_address.label_helper',
                  defaultMessage:
                    'You company needs you address to send you your activation letter.',
                })}
              </Typography>
            </Box>
          )}
          {globalCustomFieldsSort.map(item => {
            const firstElem = company.globalCustomFields.filter(
              globalItem => globalItem.key === item[0],
            )[0];
            if (item.length > 1 || (firstElem && firstElem.key === 'hansefit_street')) {
              let secondElem = company.globalCustomFields.filter(
                globalItem => globalItem.key === item[1],
              )[0];
              if ((firstElem && secondElem) || (firstElem && firstElem.key === 'hansefit_street')) {
                return (
                  <Box
                    key={`${firstElem.key}${secondElem ? secondElem.key : ''}`}
                    className={`content ${
                      firstElem.key === 'hansefit_street'
                        ? 'street-row'
                        : secondElem.key === 'hansefit_city'
                        ? 'city-row'
                        : ''
                    }`}>
                    {firstElem.inputType !== 'enum' && (
                      <CCPTextField
                        key={firstElem.key}
                        disabled={isDisabledField(
                          `globalCustomFields.${firstElem.key}`,
                          disabledFields,
                          userInfo,
                        )}
                        hidden={isHiddenField(
                          `globalCustomFields.${firstElem.key}`,
                          hiddenFields,
                          userInfo,
                        )}
                        type={firstElem.inputType}
                        control={control}
                        name={`globalCustomFields.${firstElem.key}`}
                        label={`${firstElem.name[currentLanguage || 'en' || 'de']}${
                          firstElem.mandatory === 'yes' ? '*' : ''
                        }`}
                      />
                    )}
                    {(!secondElem || secondElem.inputType !== 'enum') && (
                      <CCPTextField
                        key={secondElem ? secondElem.key : 'hansefit_houseNumber'}
                        disabled={isDisabledField(
                          `globalCustomFields.${getSecondElemKey(secondElem)}`,
                          disabledFields,
                          userInfo,
                        )}
                        hidden={isHiddenField(
                          `globalCustomFields.${getSecondElemKey(secondElem)}`,
                          hiddenFields,
                          userInfo,
                        )}
                        type={secondElem ? secondElem.inputType : 'string'}
                        control={control}
                        name={`globalCustomFields.${getSecondElemKey(secondElem)}`}
                        label={
                          secondElem
                            ? `${secondElem.name[currentLanguage || 'en' || 'de']}${
                                secondElem.mandatory === 'yes' ? '*' : ''
                              }`
                            : `${intl.formatMessage({
                                id: 'form.employee.field.houseNumber.label',
                                defaultMessage: 'House number',
                              })}${firstElem.mandatory === 'yes' ? '*' : ''}`
                        }
                      />
                    )}
                  </Box>
                );
              } else {
                if (firstElem) {
                  secondElem = firstElem;
                }
                return secondElem && secondElem.inputType !== 'enum' ? (
                  <CCPTextField
                    key={secondElem.key}
                    disabled={isDisabledField(
                      `globalCustomFields.${secondElem.key}`,
                      disabledFields,
                      userInfo,
                    )}
                    hidden={isHiddenField(
                      `globalCustomFields.${secondElem.key}`,
                      hiddenFields,
                      userInfo,
                    )}
                    type={secondElem.inputType}
                    control={control}
                    name={`globalCustomFields.${secondElem.key}`}
                    label={`${secondElem.name[currentLanguage || 'en' || 'de']}${
                      secondElem.mandatory === 'yes' ? '*' : ''
                    }`}
                  />
                ) : null;
              }
            }
            if (firstElem) {
              return firstElem.key === 'hansefit_country' ? (
                <CCPSelect
                  key={firstElem.key}
                  disabled={isDisabledField(
                    `globalCustomFields.${firstElem.key}`,
                    disabledFields,
                    userInfo,
                  )}
                  hidden={isHiddenField(
                    `globalCustomFields.${firstElem.key}`,
                    hiddenFields,
                    userInfo,
                  )}
                  control={control}
                  name={`globalCustomFields.${firstElem.key}`}
                  label={`${firstElem.name[currentLanguage || 'en' || 'de']}${
                    firstElem.mandatory === 'yes' ? '*' : ''
                  }`}>
                  {sortedCountries.map(country => (
                    <MenuItem key={country.key} value={country.code}>
                      {country.name[currentLanguage]}
                    </MenuItem>
                  ))}
                </CCPSelect>
              ) : (
                firstElem.inputType !== 'enum' && (
                  <CCPTextField
                    key={firstElem.key}
                    disabled={isDisabledField(
                      `globalCustomFields.${firstElem.key}`,
                      disabledFields,
                      userInfo,
                    )}
                    hidden={isHiddenField(
                      `globalCustomFields.${firstElem.key}`,
                      hiddenFields,
                      userInfo,
                    )}
                    type={firstElem.inputType}
                    control={control}
                    name={`globalCustomFields.${firstElem.key}`}
                    label={`${firstElem.name[currentLanguage || 'en' || 'de']}${
                      firstElem.mandatory === 'yes' ? '*' : ''
                    }`}
                  />
                )
              );
            }
            return null;
          })}
          <Spacer height={0} />
        </Box>
      )}
      {company && company.customFields?.length > 0 && (
        <Box className='personalInfo'>
          {hasVoucher && (
            <Typography variant='subtitle2'>
              {intl.formatMessage({
                id: 'step1.subsection.headline.other_info',
                defaultMessage: 'Company name',
              })}
            </Typography>
          )}
          {company.customFields.map((customField, idx) => {
            const label = customField.name[currentLanguage || 'en' || 'de'];
            const fieldName = `customFields.${customField.key}`;
            if (customField.inputType === 'enum') {
              const options: Array<{ label: string; id: string }> = [];
              if (customField.localizedValuesMap) {
                Object.keys(customField.localizedValuesMap).forEach(keyName => {
                  if (customField.localizedValuesMap) {
                    const curr = customField.localizedValuesMap[keyName];
                    options.push({
                      id: keyName,
                      label: curr[currentLanguage || 'en' || 'de'] || keyName,
                    });
                  }
                });
              }
              if (
                options.length === 1 ||
                (customField.isFirstValueDefault === 'yes' && !getValues(fieldName))
              ) {
                setValue(fieldName, options[0].id);
              }
              return (
                <CCPAutocomplete
                  key={idx}
                  options={options}
                  control={control}
                  disabled={
                    options.length === 1 || isDisabledField(fieldName, disabledFields, userInfo)
                  }
                  hidden={isHiddenField(fieldName, hiddenFields, userInfo)}
                  name={fieldName}
                  width='100%'
                  label={`${label}${customField.mandatory === 'yes' ? '*' : ''} `}
                />
              );
            } else {
              return (
                <CCPTextField
                  key={idx}
                  disabled={isDisabledField(fieldName, disabledFields, userInfo)}
                  hidden={isHiddenField(fieldName, hiddenFields, userInfo)}
                  type={customField.inputType}
                  control={control}
                  name={fieldName}
                  label={`${label}${customField.mandatory === 'yes' ? '*' : ''}`}
                />
              );
            }
          })}
        </Box>
      )}
    </Box>
  );
};

export default ContactInfo;
