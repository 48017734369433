import { Grid, Typography } from '@mui/material';
import React from 'react';

const GridRow: React.FC<{
  hidden?: boolean;
  title: string;
  value: string | Date | null | undefined | JSX.Element;
  accent?: boolean;
  innerContent?: boolean;
}> = ({ title, value, hidden, accent = false, innerContent = false }) => {
  return hidden ? null : (
    <>
      <Grid item xs={12} sm={4}>
        <Typography variant='h5' fontSize={'14px'} fontFamily='Roboto-Medium'>
          {title}:
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        {innerContent ? (
          <> {value}</>
        ) : (
          <Typography
            variant='body1'
            fontSize={'16px'}
            minHeight='25px'
            fontWeight={accent ? 600 : 400}>
            {value?.toString()}
          </Typography>
        )}
      </Grid>
    </>
  );
};

export default GridRow;
