import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';

import { RouterWrapper } from 'routes/RouterWrapper';
import { useAppSelector } from 'store/store';

import { useMemo } from 'react';
import CCPTheme from 'theme/theme';

const ThemeProviderWrapper: React.FC = () => {
  const data = useAppSelector(store => store.company.data);

  const theme = useMemo(() => {
    const newTheme = CCPTheme;
    if (data?.signupPageColor) newTheme.palette.primary.light = data.signupPageColor;
    else newTheme.palette.primary.light = CCPTheme.palette.primary.main;
    return createTheme(newTheme);
  }, [data]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RouterWrapper />
    </ThemeProvider>
  );
};

export default ThemeProviderWrapper;
