// A custom theme for this app
import { createTheme, Theme } from '@mui/material';
import { deDE } from '@mui/material/locale';

declare module '@mui/material/styles' {
  interface PaletteColor {
    darker?: string;
  }

  interface SimplePaletteColorOptions {
    darker?: string;
  }
  interface Palette {
    neutralLight: SimplePaletteColorOptions;
    neutralDark: SimplePaletteColorOptions;
    primaryLight: SimplePaletteColorOptions;
    transparent: SimplePaletteColorOptions & {
      dropdownSelected: string;
      boxShadowDark01: string;
      boxShadowDark02: string;
      dark05: string;
      blue05: string;
      blue07: string;
      disabledInput: string;
      darkYellow: string;
    };
    gradient: SimplePaletteColorOptions;
    tableTemplate: { fontColor: string; backgroundColor: string };
  }
  interface PaletteOptions {
    neutralLight: SimplePaletteColorOptions;
    neutralDark: SimplePaletteColorOptions;
    primaryLight: SimplePaletteColorOptions;
    transparent: SimplePaletteColorOptions & {
      dropdownSelected: string;
      boxShadowDark01: string;
      boxShadowDark02: string;
      dark05: string;
      blue05: string;
      blue07: string;
      disabledInput: string;
      darkYellow: string;
    };
    gradient: SimplePaletteColorOptions;
    tableTemplate: { fontColor: string; backgroundColor: string };
  }
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    hd: true; // 1920
    qhd: true; // 2560
  }
}

const CCPTheme: Theme = createTheme(
  {
    breakpoints: {
      values: {
        xs: 300, // phone
        sm: 768, // tablets
        md: 900, // small laptop
        lg: 1200, // desktop
        xl: 1536, // large screens
        hd: 1920, // hd
        qhd: 2560, // qhd
      },
    },
    typography: {
      fontFamily: [
        'Roboto-Regular',
        'Helvetica',
        'Arial',
        'sans-serif',
        'NutmegHeadline-UltraBlack',
      ].join(','),
      h1: {
        fontSize: 40,
        marginTop: '42px',
        fontFamily: 'NutmegHeadline-UltraBlack',
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#000',
      },
      h2: {
        fontSize: 20,
        fontFamily: 'NutmegHeadline-UltraBlack',
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#000',
      },
      h3: {
        fontSize: 24,
        fontFamily: 'NutmegHeadline-UltraBlack',
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#000',
      },
      h4: {
        fontSize: 18,
        fontFamily: 'NutmegHeadline-UltraBlack',
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#000',
      },
      h5: {
        fontSize: 16,
        lineHeight: 1.38,
        color: '#475569',
      },
      body1: {
        fontSize: 18,
        lineHeight: 1.56,
        color: '#1F2126',
      },
      body2: {
        fontSize: 16,
        lineHeight: '24px',
        color: '#1F2126',
      },
      subtitle1: {
        fontSize: 16,
        lineHeight: 1.38,
        color: '#1F2126',
      },
      subtitle2: {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: 1.38,
        color: '#1F2126',
      },
      caption: {
        display: 'block',
        fontSize: 14,
        lineHeight: '18px',
        color: '#64748B',
      },
      overline: {
        textTransform: 'none',
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '14px',
        color: '#6b7986',
      },
    },
    palette: {
      background: {
        default: '#eff2f6',
        paper: '#fff',
      },
      primary: {
        main: '#003bb3', //blue-main-000
        dark: '#002a80', //blue-main-001
        darker: '#001f5c', //blue-main-002
        contrastText: '#fff',
      },
      secondary: {
        main: '#fff',
      },
      primaryLight: {
        light: '#f8f9fb', //blue-light-000
        main: '#eff2f6', //blue-light-001
        dark: '#e5f1ff', //blue-light-002
        darker: '#97b7e1', //blue-light-003
      },
      neutralLight: {
        light: '#fff', //white-000
        main: '#ebebeb', //light-grey-000
        dark: '#d8d8d8', //light-grey-002
      },
      neutralDark: {
        light: '#a3acb5', //light-grey-003
        main: '#6b7986', //light-grey-004
        dark: '#1f2126', // black-000
        contrastText: '#000', // black for a lot of titles(I don't know why we have no this color in the designs)
      },
      error: {
        main: '#D0004A',
        light: '#FCE5EE',
      },
      success: {
        main: '#2bbc9b',
        light: '#2BBC9B33',
      },
      warning: {
        main: '#F9A500',
        light: '#F9A50020',
      },
      transparent: {
        light: '#ffffff33', // white 20%
        main: '#ffffff80', // white 50%
        dark: '#ffffffcc', // white 80%
        dropdownSelected: '#003bb34d', //rgba(0, 59, 179, 0.3)
        boxShadowDark01: '#0000001a', //rgba(0, 0, 0, 0.1)
        boxShadowDark02: '#00000033', //rgba(0, 0, 0, 0.2)
        dark05: '#00000080', //rgba(0, 0, 0, 0.5)
        blue05: '#eff2f680', //rgba(239, 242, 246, 0.5)
        blue07: '#eff2f6b3', //rgba(239, 242, 246, 0.7)
        disabledInput: '#d8d8d880', //rgba(216, 216, 216, 0.5)
        darkYellow: '#f9a50033', //rgba(249, 165, 0, 0.2)
      },
      gradient: {
        main: 'linear-gradient(124deg, #002A80 14.57%, #D3E6F5 87.33%)', //gradient blue-01
      },
      tableTemplate: {
        fontColor: '#a9bbcd',
        backgroundColor: '#a9bbcd33',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 18,
            width: 'fit-content',
            height: 'fit-content',
            minWidth: 142,
            padding: '8px 18px',
            border: 'none',
            fontFamily: 'Roboto-Medium',
            fontWeight: 500,
            fontSize: 16,
            lineHeight: '20px',
            textTransform: 'unset',
            '.MuiTouchRipple-root': {
              display: 'none',
            },
            '&.MuiButton-outlined': {
              padding: '6px 16px',
              border: '2px solid currentColor',
            },
            '&.MuiButton-outlinedPrimary:hover,&.MuiButton-outlinedSecondary:hover': {
              color: '#97B7E1',
              borderColor: '#97B7E1',
              backgroundColor: '#EFF2F6',
            },
            '&.MuiButton-outlinedPrimary:active,&.MuiButton-outlinedSecondary:active': {
              backgroundColor: '#E5F1FF',
            },
            '&.MuiButton-outlinedError:hover': {
              color: '#EC7FA6',
            },
            '&.MuiButton-outlined:disabled': {
              color: '#6B7986',
              borderColor: '#6B7986',
              backgroundColor: 'transparent',
            },

            '&.MuiButton-containedPrimary:hover': {
              backgroundColor: '#002A80',
              color: '#97B7E1',
            },
            '&.MuiButton-containedPrimary:active': {
              backgroundColor: '#001F5C',
            },
            '&.MuiButton-containedError:hover': {
              backgroundColor: '#B20F49',
              color: '#EC7FA6',
            },
            '&.MuiButton-containedError:active': {
              backgroundColor: '#800E37',
            },
            '&.MuiButton-contained:disabled': {
              backgroundColor: '#A3ACB5',
              color: '#EBEBEB',
            },

            '&.MuiButton-textPrimary': {
              color: '#6B7986',
              backgroundColor: 'transparent',
            },
            '&.MuiButton-textPrimary:hover': {
              color: '#003BB3',
            },
            '&.MuiButton-textPrimary:active': {
              color: '#97B7E1',
            },
            '&.MuiButton-textPrimary:disabled': {
              color: '#A3ACB5',
            },
          },
        },
      },
    },
  },
  deDE,
);

export default CCPTheme;
