import { Box } from '@mui/material';

export interface SpacerProps {
  height: number | string;
}

const Spacer = ({ height }: SpacerProps) => {
  return <Box sx={{ height, display: 'flex', width: '100%' }} />;
};

export default Spacer;
