import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { DefaultTextField } from '../TextField/DefaultTextField.styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateView, LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { defaultTextfieldError } from 'utils/defaultErrorMessage';
import { useCallback, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { enUS, de } from 'date-fns/locale';
import { default as theme } from 'theme/theme';

export interface InputFieldProps {
  label: string;
  name: string;
  control: any;
  width?: number | string;
  disabled?: boolean;
  hidden?: boolean;
  isMonthPicker?: boolean;
  minDate?: any;
  maxDate?: any;
  isBirthdayPicker?: boolean;
  isWarningError?: boolean;
  notShowErrorText?: boolean;
}

const CCPDatePicker = ({
  label,
  control,
  name,
  width,
  disabled,
  hidden,
  isMonthPicker = false,
  minDate = undefined,
  maxDate = undefined,
  isBirthdayPicker = false,
  isWarningError = false,
  notShowErrorText = false,
}: InputFieldProps) => {
  const intl = useIntl();
  const [cookies] = useCookies(['language_app']);

  const { setValue, trigger } = useFormContext();

  const onChange = useCallback(
    async (data: any) => {
      if (isMonthPicker) {
        if (data && !isNaN(data.getTime()) && data > minDate && data < maxDate) {
          setValue(name, new Date(data.getFullYear(), data.getMonth(), 1));
        } else {
          setValue(name, data);
        }
      } else {
        setValue(name, data);
      }
      await trigger(name);
    },
    [trigger, name, setValue, isMonthPicker, minDate, maxDate],
  );
  const views: DateView[] = useMemo(() => {
    if (!isMonthPicker) {
      return ['year', 'month', 'day'];
    }
    if (minDate && maxDate && minDate.getFullYear() === maxDate.getFullYear()) {
      return ['month'];
    }
    return ['month', 'year'];
  }, [isMonthPicker, minDate, maxDate]);

  const errorIconColor = (error?: { message?: string } | undefined) =>
    isWarningError && !!error ? 'warning' : !!error ? 'error' : 'default';

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={cookies.language_app === 'de' ? de : enUS}>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <DesktopDatePicker
            {...field}
            minDate={minDate}
            maxDate={maxDate}
            disabled={disabled}
            views={views}
            value={field?.value ? new Date(field?.value) : null}
            label={label}
            openTo={views[isBirthdayPicker ? 0 : views.length - 1]}
            format={isMonthPicker ? 'MM.yyyy' : 'dd.MM.yyyy'}
            onChange={onChange}
            sx={{
              width,
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderWidth: '1px',
              },
              '.Mui-disabled fieldset.MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.transparent.disabledInput,
              },
              '.MuiFormLabel-root.MuiInputLabel-root.Mui-error': {
                color: isWarningError ? theme.palette.warning.main : theme.palette.error.main,
              },
              '.MuiInputBase-root.MuiOutlinedInput-root.Mui-error fieldset.MuiOutlinedInput-notchedOutline':
                {
                  borderColor: isWarningError
                    ? theme.palette.warning.main
                    : theme.palette.error.main,
                },
            }}
            slots={{ textField: DefaultTextField }}
            slotProps={{
              openPickerButton: {
                color: errorIconColor(error),
              },
              textField: {
                error: !!error,
                helperText:
                  error?.message && !notShowErrorText ? (
                    <span data-test-id='validationErrorText'>
                      {intl.formatMessage({
                        id: error?.message,
                        defaultMessage: defaultTextfieldError[error.message] || error.message,
                      })}
                    </span>
                  ) : undefined,
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default CCPDatePicker;
