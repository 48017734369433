import { useIntl } from 'react-intl';

export const useTitlesList = () => {
  const intl = useIntl();

  return [
    {
      title: '',
      value: '',
    },
    {
      title: intl.formatMessage({ id: 'form.employee.title.dr', defaultMessage: 'Dr.' }),
      value: 'Dr.',
    },
    {
      title: intl.formatMessage({ id: 'form.employee.title.prof', defaultMessage: 'Prof.' }),
      value: 'Prof.',
    },
    {
      title: intl.formatMessage({
        id: 'form.employee.title.prof_dr',
        defaultMessage: 'Prof. Dr.',
      }),
      value: 'Prof. Dr.',
    },
  ];
};
