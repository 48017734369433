import { Box, Typography } from '@mui/material';
import { BaseSetting } from 'features/landing/interfaces';
import React, { useMemo } from 'react';
import { sanitizeText } from 'utils/sanitizeText';

const TextArea: React.FC<BaseSetting & { text: string; internal?: boolean; color?: string }> = ({
  text,
  backgroundColor,
  placement,
  internal = false,
  color,
}) => {
  const santinizedText = useMemo(() => sanitizeText(text), [text]);

  return (
    <Box
      className={internal ? '' : 'main-style'}
      sx={{ backgroundColor }}
      data-testid='landing-item'>
      <Box className='limit-width'>
        <Typography
          variant='body1'
          color={color ? color : 'initial'}
          className='text-area'
          dangerouslySetInnerHTML={{
            __html: santinizedText,
          }}
        />
      </Box>
    </Box>
  );
};

export default TextArea;
