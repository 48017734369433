import { Box, List, ListItem, Typography } from '@mui/material';
import checkmarkIcon from 'assets/icons/checkmark-icon.svg';
import { useMemo } from 'react';
import { sanitizeText } from 'utils/sanitizeText';

const ImageBanner: React.FC<{
  headline: string;
  headlineImage?: string;
  items: Array<string>;
  backgroundColor?: string;
}> = ({ headline, headlineImage, items, backgroundColor }) => {
  const santinizedlist = useMemo(() => items.map(text => sanitizeText(text)), [items]);

  const leftList = santinizedlist.slice(0, Math.ceil(santinizedlist.length / 2));
  const rightList = santinizedlist.slice(Math.ceil(santinizedlist.length / 2));

  return (
    <Box
      sx={{
        backgroundColor,
        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%), url(${headlineImage})`,
      }}
      className='main-style checkmark-background'
      data-testid='landing-item'>
      <Box className='limit-width'>
        <Box flex='2' margin='auto'>
          <Typography variant='h2' className='headline'>
            {headline}
          </Typography>
        </Box>
        <List className='image-banner-list' sx={{ flex: '2' }}>
          {leftList.map((item, index) => (
            <ListItem key={index}>
              <div className='checkmark-item'>
                <img src={checkmarkIcon} />
                <Typography
                  variant='body1'
                  color='white'
                  dangerouslySetInnerHTML={{
                    __html: item,
                  }}
                />
              </div>
            </ListItem>
          ))}
        </List>
        <List className='image-banner-list' sx={{ flex: '2' }}>
          {rightList.map((item, index) => (
            <ListItem key={index}>
              <div className='checkmark-item'>
                <img src={checkmarkIcon} />
                <Typography
                  variant='body1'
                  color='white'
                  dangerouslySetInnerHTML={{
                    __html: item,
                  }}
                />
              </div>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default ImageBanner;
