import { Alert, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { useIntl } from 'react-intl';
import { formatDate } from './formatDate';

const AlertToast: React.FC<{
  type: 'success' | 'error';
  msg: string | { id: string; defaultMessage: string };
  errorMessage?: string;
  replacePlaceholders?: { [key: string]: string };
}> = ({ type, msg, replacePlaceholders = null, errorMessage = '' }) => {
  const intl = useIntl();

  const currentDate = new Date();
  return (
    <Alert
      className='toast__wrapper_content'
      icon={
        type === 'success' ? (
          <CheckCircleRoundedIcon fontSize='small' htmlColor='#2bbc9b' />
        ) : (
          <ErrorRoundedIcon fontSize='small' htmlColor='#e50051' />
        )
      }>
      <Typography
        variant='body1'
        dangerouslySetInnerHTML={{
          __html:
            typeof msg === 'string' ? msg : intl.formatMessage(msg, replacePlaceholders || {}),
        }}
      />
      {errorMessage && (
        <>
          <Typography variant='h5'>
            <b>
              {intl.formatMessage({
                id: 'toast.signup.creation_user.error_timestamp',
                defaultMessage: 'Error time:',
              })}
            </b>
            {` `}
            {formatDate(currentDate.toString(), 'de')}-
            {`${currentDate.getUTCHours()}:${currentDate.getUTCMinutes()}:${currentDate.getUTCSeconds()}:${currentDate.getUTCMilliseconds()}`}
          </Typography>
          <Typography
            variant='h5'
            dangerouslySetInnerHTML={{
              __html: errorMessage,
            }}
          />
        </>
      )}
    </Alert>
  );
};

const showToast = (
  type: 'success' | 'error',
  msg: string | { id: string; defaultMessage: string },
  errorMessage?: string,
  autoClose?: number,
  replacePlaceholders?: { [key: string]: string },
): void => {
  toast.success(
    <AlertToast
      type={type}
      msg={msg}
      replacePlaceholders={replacePlaceholders}
      errorMessage={errorMessage}
    />,
    {
      autoClose: autoClose && autoClose === -1 ? false : autoClose,
    },
  );
};

export default showToast;
