import { IViolationResponseError } from 'store/company/interfaces';
import { rangeValidation } from 'utils/dateRangeValidation';
import * as yup from 'yup';
//if we will upgrade this regex we need to update regex in the CCP project
const phoneRegExp = /^[0-9 \-\/]{5,20}$/;

const isValidEmailDomains = (val: string | undefined, emailValidation: string | undefined) => {
  if (emailValidation) {
    const validationArray = emailValidation.split(' ').filter(item => item.length > 0);
    let isValid = false;
    if (val) {
      validationArray.forEach(item => {
        if (!isValid) {
          isValid = val.split('@')[1] === item;
        }
      });
    }
    return isValid || val === '';
  } else {
    return true;
  }
};

const violationValidation = (
  val: string | undefined,
  violationErrors: { error: IViolationResponseError | undefined; emailVal: string },
) => {
  if (violationErrors.error) {
    return val !== violationErrors.emailVal;
  } else {
    return true;
  }
};

export const contactSchema = (
  emailValidation: string | undefined,
  violationErrors: { error: IViolationResponseError | undefined; emailVal: string },
  emailRequired: boolean,
  startDateLimits?: { min: Date; max: Date } | undefined,
) => {
  const startDate = startDateLimits
    ? {
        startDate: yup
          .date()
          .typeError('form.employee.error.service_date.invalid')
          .nullable()
          .test('isValid', 'form.employee.error.service_date.invalid_range', (val, context) =>
            rangeValidation(val, startDateLimits),
          )
          .required('form.employee.error.service_date.required'),
      }
    : { startDate: yup.date() };
  return yup
    .object({
      phoneNumber: yup
        .string()
        .nullable()
        .test('isValid', 'form.employee.error.phone.invalid', val => {
          if (val) return phoneRegExp.test(val as string) === true;
          else return true;
        }),
      phoneNumberPrefix: yup
        .string()
        .nullable()
        .test('isValid', 'form.employee.error.phone_prefix.required', (val, context) => {
          if (context.parent.phoneNumber) {
            return (val as string).length > 0;
          }
          return true;
        }),
      businessEmail: emailRequired
        ? yup
            .string()
            .trim()
            .required('form.employee.error.corporate_email.required')
            .email('form.employee.error.corporate_email.invalid')
            .test('isValid', 'form.employee.error.corporate_email.invalid_domains', val =>
              isValidEmailDomains(val, emailValidation),
            )
            .test(
              'isViolationError',
              'employee_management.form.error.businessemail.is_not_unique_email',
              val => violationValidation(val, violationErrors),
            )
        : yup
            .string()
            .trim()
            .email('form.employee.error.corporate_email.invalid')
            .test('isValid', 'form.employee.error.corporate_email.invalid_domains', val =>
              isValidEmailDomains(val, emailValidation),
            )
            .test(
              'isViolationError',
              'employee_management.form.error.businessemail.is_not_unique_email',
              val => violationValidation(val, violationErrors),
            ),
      confirmEmail: yup
        .string()
        .oneOf([yup.ref('businessEmail')], 'form.employee.error.confirm_email_mismatch')
        .when('businessEmail', {
          is: (businessEmail: string) => businessEmail.length > 0,
          then: yup.string().required('form.employee.error.custom_field.required'),
        }),
      ...startDate,
    })
    .required();
};

export default contactSchema;
