import { useEffect } from 'react';
import { UseFormClearErrors, UseFormWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { IViolationsError } from 'store/company/interfaces';

export const useViolationErrors = (params: {
  scope: string;
  errors: IViolationsError[] | null;
  watch: UseFormWatch<any>;
  clearErrors: UseFormClearErrors<any>;
}): IViolationsError[] => {
  const intl = useIntl();
  const { scope, errors, watch, clearErrors } = params;
  const violationErrors: IViolationsError[] = [];

  if (errors) {
    errors.forEach(violation => {
      violationErrors.push({
        propertyPath: violation.propertyPath,
        message: intl.formatMessage({
          id: `${scope}.form.error.${
            violation.propertyPath.toLowerCase().includes('customfields')
              ? 'customField'
              : violation.propertyPath
          }.${violation.message}`.toLowerCase(),
          defaultMessage: 'Something error',
        }),
        code: violation.code,
      });
    });
  }

  useEffect(() => {
    const subscription = watch((form, field) => {
      const error = errors?.find(error => error.propertyPath === field.name);
      if (error && field.type === 'change') {
        clearErrors(field.name);
      }
    });

    return () => subscription.unsubscribe();
  }, [clearErrors, errors, watch]);

  return violationErrors;
};
