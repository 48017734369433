import { Box } from '@mui/material';

export interface UserLayoutProps {
  children: JSX.Element | JSX.Element[];
}

const UserLayout: React.FC<UserLayoutProps> = ({ children }) => {
  return <Box>{children}</Box>;
};

export default UserLayout;
