import { styled, Box, Typography } from '@mui/material';
import ErrorSvg from '@mui/icons-material/Error';

type ContainerProps = {
  isWarningMessage?: boolean;
};

export const Container = styled(Box)<ContainerProps>(({ theme, isWarningMessage }) => ({
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: isWarningMessage ? theme.palette.transparent.darkYellow : 'inherit',
  padding: isWarningMessage ? '15px' : '0',
  textAlign: 'left',
}));

export const TextContainer = styled(Box)(({ theme }) => ({
  marginLeft: 12,
}));

export const ErrorTitle = styled(Typography)(({ theme }) => ({
  marginTop: 2,
  fontFamily: 'Roboto-Bold',
}));

export const ErrorDescription = styled(Typography)(({ theme }) => ({
  marginTop: 2,
  fontStyle: 'normal',
}));

export const ErrorIcon = styled(ErrorSvg)(({ theme }) => ({
  fill: theme.palette.error.main,
  height: 18,
  width: 18,
  position: 'relative',
  top: 4,
}));

export const WarningIcon = styled(ErrorSvg)(({ theme }) => ({
  fill: theme.palette.warning.main,
  height: 18,
  width: 18,
  position: 'relative',
  top: 4,
}));

export const ErrorItem = styled('li')(({ theme }) => ({
  listStyleType: 'none',
  fontFamily: 'Roboto-Regular',
}));
