import { ToastWrapper } from './ToastProvider.styles';

const ToastProvider: React.FC = () => (
  <ToastWrapper
    rtl={false}
    icon={false}
    position="top-center"
    draggable={false}
    autoClose={3000}
    className="toast__wrapper"
    closeButton={false}
    newestOnTop={false}
    hideProgressBar={true}
  />
);

export default ToastProvider;
