import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { BaseSetting } from 'features/landing/interfaces';
import HlsPlayer from './HlsPlayer';
import { useWindowSize } from 'hooks/useWindowSize';

export interface videoAttributes {
  type: 'youtube' | 'vimeo' | 'dailymotion' | 'asset';
  id?: number;
  path: string;
  cdnPath?: string;
  description?: string;
  poster?: string;
  title?: string;
}
const Video: React.FC<
  BaseSetting &
    videoAttributes & {
      internal?: boolean;
    }
> = ({
  path,
  backgroundColor,
  placement,
  internal = false,
  type,
  poster = '',
  title = '',
  description = '',
}) => {
  const windowSize = useWindowSize();

  const isMobile = useMemo(() => windowSize?.width && windowSize?.width < 768, [windowSize]);
  const width = useMemo(() => (isMobile ? '100%' : '600'), [isMobile]);
  const height = useMemo(() => (isMobile ? 'auto' : '360'), [isMobile]);

  const getVideoUrl = (type: 'youtube' | 'vimeo' | 'dailymotion' | 'asset', path: string) => {
    switch (type) {
      case 'dailymotion':
        return `http://www.dailymotion.com/embed/video/${path}`;
      case 'vimeo':
        return `https://player.vimeo.com/video/${path}`;
      case 'youtube':
        return `https://www.youtube.com/embed/${path}`;
    }
  };
  const videoType = type === 'asset' ? path.substring(path.lastIndexOf('.') + 1) : '';

  return (
    <Box
      className={internal ? '' : 'main-style'}
      sx={{ backgroundColor }}
      data-testid='landing-item'>
      <Box className='limit-width' textAlign={placement}>
        {type === 'asset' ? (
          videoType === 'm3u8' ? (
            <HlsPlayer url={path} width={width} height={height} />
          ) : (
            <video width={width} height={height} controls>
              <source src={path} type={`video/${videoType}`} />
            </video>
          )
        ) : (
          <iframe
            title={title}
            src={getVideoUrl(type, path)}
            width={width}
            height={height}
            allow='autoplay; fullscreen'
          />
        )}
      </Box>
    </Box>
  );
};

export default Video;
