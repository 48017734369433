import { useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'store';

import React, { useEffect } from 'react';
import { CompanyActions } from 'store/company';
import { useNavigate, useParams } from 'react-router';
import { CompanySelectors } from 'store/company/company.selectors';
import Loader from 'components/Loader';

const Verification: React.FC = () => {
  const { id, token } = useParams<{ id: string; token: string }>();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const error = useAppSelector(CompanySelectors.getError);
  const userInfo = useAppSelector(CompanySelectors.getUserInfo);
  // const api = useAppSelector(InitialPasswordSelectors.api);

  useEffect(() => {
    if (token) {
      dispatch(CompanyActions.getUserInfo({ id: id, token: token }));
    }
  }, [token]);

  useEffect(() => {
    if (userInfo) navigate(`/${id}/step/1`);
  }, [userInfo]);

  useEffect(() => {
    if (error) navigate(`/`);
  }, [error]);

  return <Loader showAsSpin={true} />;
};

export default Verification;
