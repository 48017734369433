import { ICountriesList } from "store/company/interfaces";

export const getDialCodes = (countries: ICountriesList[]) => {
    let dialCountries: ICountriesList[] = JSON.parse(JSON.stringify(countries));
    return dialCountries
      ?.sort(function (a: ICountriesList, b: ICountriesList) {
        return Number(a.callingCode) - Number(b.callingCode);
      })
      .map(item => ({ dialCode: item.callingCode })) || [];
}

export const getPhonePrefix = (phone: string, dialCodes: any) => {
    for (let i = 0; i < dialCodes.length; i++) {
      if (phone.startsWith(dialCodes[i].dialCode)) {
        return dialCodes[i].dialCode;
      }
    }
    return '';
  };

export  const getPhoneWithoutPrefix = (phone: string, dialCodes: any) => {
    for (let i = 0; i < dialCodes.length; i++) {
      if (phone.startsWith(dialCodes[i].dialCode)) {
        return phone.replace(dialCodes[i].dialCode, '');
      }
    }
    return phone;
  };