import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const RequiredInfo = () => {
  const { formatMessage } = useIntl();

  return (
    <Box className='required-wrapper'>
      <ErrorOutlineIcon />
      <Typography variant='h5' fontSize={14}>
        {formatMessage({
          id: 'stepper.asterisk_info',
          defaultMessage: 'The fields marked with (*) are mandatory fields and must be filled in.',
        })}
      </Typography>
    </Box>
  );
};

export default RequiredInfo;
