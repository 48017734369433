import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useAppSelector } from 'store';
import { FetchingStatus } from 'store/interfaces';

import { LoaderWrapper } from './Loader.styles';

interface Props {
  portalId?: string;
  showAsSpin?: boolean;
}

const Loader: React.FC<Props> = ({ portalId = '', showAsSpin = false }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { fetchingStatus } = useAppSelector<{
    fetchingStatus: FetchingStatus;
  }>(state => state.company);

  useEffect(() => {
    if ([FetchingStatus.IDLE, FetchingStatus.PENDING].includes(fetchingStatus)) {
      setIsLoading(true);
      document.body.style.overflow = 'hidden';
    } else {
      setIsLoading(false);
      document.body.style.overflow = 'auto';
    }
  }, [fetchingStatus]);

  const portalParent = portalId ? document.getElementById(portalId) : null;

  const LoaderComponent = createPortal(
    <LoaderWrapper>
      <CircularProgress />
    </LoaderWrapper>,
    portalParent ? portalParent : document.body,
  );

  return showAsSpin ? LoaderComponent : isLoading ? LoaderComponent : null;
};

export default Loader;
