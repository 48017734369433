import { Box, Button, MenuItem, Typography } from '@mui/material';

import { PaymentImage } from 'assets/icons';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from 'store';
import { FormProvider, useForm } from 'react-hook-form';
import { IPaymentForm } from 'features/stepper/interfaces';
import { yupResolver } from '@hookform/resolvers/yup';
import contactSchema from './contactSchema';
import { actions } from 'store/company/company.slice';
import CCPTextField from 'components/FormComponents/TextField';
import { CompanySelectors } from 'store/company/company.selectors';
import React, { useEffect, useState } from 'react';
import Spacer from 'components/Spacer';
import { ICountriesList, IUserInfo, IViolationsError } from 'store/company/interfaces';
import { Stepper } from '..';
import { CCPLabelCheckbox } from 'components/FormComponents/Checkbox/LabelCheckbox';
import { formatIBAN } from 'utils/formatIBAN';
import CCPSelect from 'components/FormComponents/Select';
import { TranslationsKeys } from 'store/interfaces';
import { useCookies } from 'react-cookie';
import PaymentDetails from '../FormParts/PaymentDetails';
import StartDatePanel from '../FormParts/StartDatePanel';
import { getMaxStartDate, getMinStartDate } from 'utils/prepareDates';
import { format } from 'date-fns';
import RequiredInfo from '../FormParts/RequiredInfo';
const { ibanToBic } = require('iban-to-bic');

interface StepThreeProps {
  updateStep: (stepNumber: number | string) => void;
  sendRequest: (error: IViolationsError[]) => void;
}

const StepThree: React.FC<StepThreeProps> = ({ updateStep, sendRequest }) => {
  const [cookies, setCookies] = useCookies(['language_app']);
  const intl = useIntl();
  const navigate = useNavigate();
  const { id, stepNumber } = useParams();
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector(CompanySelectors.getUserInfo);
  const data = useAppSelector(CompanySelectors.getCompanyData);
  const company = useAppSelector(CompanySelectors.getCompanyData);
  const paymentData = userInfo?.paymentData;
  const [showBic, setShowBic] = useState<boolean>(true);
  const [ibanTouched, setIbanTouched] = useState<boolean>(false);
  const hasStep3 = data && data.paymentProviders?.length !== 0;
  const countries = useAppSelector(state => state.company.countriesList.data) || [];
  const currentLanguage: TranslationsKeys = cookies.language_app || 'de';
  let prioritiesGroup = countries.reduce(function (arr: any, a: ICountriesList) {
    arr[a.priority] = arr[a.priority] || [];
    arr[a.priority].push(a);
    return arr;
  }, Object.create(null));

  let sortedCountries: ICountriesList[] = [];

  Object.keys(prioritiesGroup).forEach(group => {
    prioritiesGroup[group] = prioritiesGroup[group].sort((a: ICountriesList, b: ICountriesList) =>
      a.name[currentLanguage || 'en'].localeCompare(b.name[currentLanguage || 'en']),
    );
    sortedCountries =
      group === 'null'
        ? [...prioritiesGroup[group], ...sortedCountries]
        : [...sortedCountries, ...prioritiesGroup[group]];
  });

  useEffect(() => {
    if (!hasStep3) {
      navigate(`/${id}/step/1`);
    }
  }, [hasStep3]);

  let isIbanPrefilled = paymentData?.ibanPrefilled || false;
  const minimumDate = getMinStartDate(company);
  const maximumDate = getMaxStartDate(company);

  const methods = useForm<IPaymentForm>({
    defaultValues: {
      accountHolderIsContractHolder: paymentData?.accountHolderIsContractHolder || false,
      iban: paymentData?.iban ? formatIBAN(paymentData.iban) : '',
      bic: paymentData?.bic || '',
      account_holder: paymentData?.account_holder || '',
      billingAddressIsContactAddress: paymentData?.billingAddressIsContactAddress || false,
      street: paymentData?.street || '',
      houseNumber: paymentData?.houseNumber || '',
      city: paymentData?.city || '',
      zip: paymentData?.zip || '',
      country: paymentData?.country || countries?.find(item => item.code === 'DE') ? 'DE' : '',
      confirmSepaCredentials: paymentData?.confirmSepaCredentials || false,
      startDate: userInfo?.startDate || null,
    },
    resolver: yupResolver(
      contactSchema(isIbanPrefilled && !ibanTouched, {
        min: minimumDate,
        max: maximumDate,
      }),
    ),
  });

  const { control, handleSubmit, getValues, watch, setValue } = methods;

  const onSubmit = async (data: IPaymentForm) => {
    dispatch(
      actions.fillUserInfo({
        paymentData: {
          ...data,
          bic: showBic ? ibanToBic(data.iban) || '' : data.bic,
          ibanPrefilled: ibanTouched ? false : !data.iban.includes('XXXX') ? false : true,
          account_holder: data.accountHolderIsContractHolder
            ? `${userInfo?.firstName.trim()} ${userInfo?.lastName.trim()}`
            : data.account_holder,
          street: data.street,
          houseNumber: data.houseNumber,
          zip: data.zip,
          city: data.city,
          country: data.billingAddressIsContactAddress
            ? userInfo?.globalCustomFields['hansefit_country'] || 'DE'
            : data.country,
        } as IPaymentForm,
        startDate: format(new Date(data.startDate as Date), 'yyyy-MM-dd'),
      } as IUserInfo),
    );
    updateStep('summary');
  };

  useEffect(() => {
    validateIban(paymentData?.bic);
  }, []);

  const validateIban = async (bicDefaultValue?: any) => {
    if (getValues('iban')) {
      const bicValue = ibanToBic(getValues('iban'));
      setValue(
        'bic',
        bicValue ? bicValue : typeof bicDefaultValue === 'string' ? bicDefaultValue : '',
      );
      setShowBic(bicValue && bicValue.length !== 0);
    }
  };

  const hasAddressGlobalFields = () => {
    return (
      userInfo?.globalCustomFields &&
      (userInfo?.globalCustomFields['hansefit_street'] ||
        userInfo?.globalCustomFields['hansefit_houseNumber'] ||
        userInfo?.globalCustomFields['hansefit_zip'] ||
        userInfo?.globalCustomFields['hansefit_city'] ||
        userInfo?.globalCustomFields['hansefit_country'])
    );
  };

  useEffect(() => {
    if (getValues('billingAddressIsContactAddress')) {
      if (userInfo?.globalCustomFields['hansefit_street']) {
        setValue('street', userInfo?.globalCustomFields['hansefit_street']);
      }

      if (userInfo?.globalCustomFields['hansefit_houseNumber']) {
        setValue('houseNumber', userInfo?.globalCustomFields['hansefit_houseNumber']);
      }
      if (userInfo?.globalCustomFields['hansefit_zip']) {
        setValue('zip', userInfo?.globalCustomFields['hansefit_zip']);
      }
      if (userInfo?.globalCustomFields['hansefit_city']) {
        setValue('city', userInfo?.globalCustomFields['hansefit_city']);
      }

      if (userInfo?.globalCustomFields['hansefit_country']) {
        setValue('country', userInfo?.globalCustomFields['hansefit_country']);
      }
    }
  }, [watch('accountHolderIsContractHolder'), watch('billingAddressIsContactAddress')]);

  const paymentDetailsFields = (
    <>
      <CCPLabelCheckbox
        control={control}
        name={'accountHolderIsContractHolder'}
        label={intl.formatMessage({
          id: 'form.employee.field.account_is_contract.label',
          defaultMessage: 'Account holder is the contract holder',
        })}
      />
      {!getValues('accountHolderIsContractHolder') && (
        <CCPTextField
          control={control}
          name={'account_holder'}
          label={`${intl.formatMessage({
            id: 'form.employee.field.account_name.label',
            defaultMessage: 'Account holder name',
          })}*`}
        />
      )}
      <CCPTextField
        control={control}
        name={'iban'}
        onBlur={validateIban}
        label={`${intl.formatMessage({
          id: 'form.employee.field.iban.label',
          defaultMessage: 'IBAN',
        })}*`}
      />
      {!showBic && (
        <CCPTextField
          control={control}
          name={'bic'}
          label={`${intl.formatMessage({
            id: 'form.employee.field.bic.label',
            defaultMessage: 'BIC of the bank',
          })}*`}
        />
      )}
    </>
  );

  return (
    <Box className='stepWrapper'>
      <Stepper />
      <Box className='headline'>
        <Typography className='paymentTitle' variant='body1'>
          {intl.formatMessage({
            id: 'step3.method',
            defaultMessage: 'Available payment method:',
          })}
          <PaymentImage />
        </Typography>
        <Spacer height={24} />
        <Typography variant='h5'>
          {intl.formatMessage({
            id: 'step3.description',
            defaultMessage: 'Please, provide payment details to Hansefit a SEPA direct payment.',
          })}
        </Typography>
        <Spacer height={12} />
        <RequiredInfo />
      </Box>
      <Spacer height={36} />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className='billingForm'>
          <Box className='billing-form'>
            {!isIbanPrefilled && paymentDetailsFields}
            {ibanTouched && paymentDetailsFields}
            {isIbanPrefilled && !ibanTouched && (
              <PaymentDetails
                iban={paymentData?.iban}
                edit={() => {
                  setIbanTouched(true);
                  setValue('account_holder', '');
                  setValue('iban', '');
                  setValue('bic', '');
                }}
              />
            )}
            <Typography className='subtitle' variant='body1' fontSize='16px'>
              {intl.formatMessage({
                id: 'form.employee.field.address.label',
                defaultMessage: 'Billing address',
              })}
              :
            </Typography>
            {hasAddressGlobalFields() && (
              <CCPLabelCheckbox
                control={control}
                name={'billingAddressIsContactAddress'}
                label={intl.formatMessage({
                  id: 'form.employee.field.billing_is_contact_address.label',
                  defaultMessage: 'Billing address is the same as contact information address',
                })}
              />
            )}
            <Box className='billing-address street-row'>
              <CCPTextField
                control={control}
                disabled={
                  getValues('billingAddressIsContactAddress') &&
                  !!userInfo?.globalCustomFields['hansefit_street']
                }
                name={'street'}
                label={`${intl.formatMessage({
                  id: 'form.employee.field.street.label',
                  defaultMessage: 'Street',
                })}*`}
              />
              <CCPTextField
                control={control}
                disabled={
                  getValues('billingAddressIsContactAddress') &&
                  !!userInfo?.globalCustomFields['hansefit_houseNumber']
                }
                name={'houseNumber'}
                label={`${intl.formatMessage({
                  id: 'form.employee.field.houseNumber.label',
                  defaultMessage: 'House number',
                })}*`}
              />
            </Box>
            <Box className='billing-address city-row'>
              <CCPTextField
                control={control}
                disabled={
                  getValues('billingAddressIsContactAddress') &&
                  !!userInfo?.globalCustomFields['hansefit_zip']
                }
                name={'zip'}
                label={`${intl.formatMessage({
                  id: 'form.employee.field.zip.label',
                  defaultMessage: 'ZIP code',
                })}*`}
              />
              <CCPTextField
                control={control}
                disabled={
                  getValues('billingAddressIsContactAddress') &&
                  !!userInfo?.globalCustomFields['hansefit_city']
                }
                name={'city'}
                label={`${intl.formatMessage({
                  id: 'form.employee.field.city.label',
                  defaultMessage: 'City',
                })}*`}
              />
            </Box>
            <CCPSelect
              control={control}
              disabled={
                getValues('billingAddressIsContactAddress') &&
                !!userInfo?.globalCustomFields['hansefit_country']
              }
              name={`country`}
              label={`${intl.formatMessage({
                id: 'form.employee.field.country.label',
                defaultMessage: 'Country',
              })}*`}>
              {sortedCountries.map(country => (
                <MenuItem key={country.key} value={country.code}>
                  {country.name[currentLanguage]}
                </MenuItem>
              ))}
            </CCPSelect>
            <Box style={{ maxWidth: '690px' }}>
              <CCPLabelCheckbox
                control={control}
                name={'confirmSepaCredentials'}
                label={`${intl.formatMessage({
                  id: 'form.employee.field.confirm_checkbox.label',
                  defaultMessage: `I confirm that by issuing the above SEPA Direct Debit Mandate, I have authorized the named payee to collect payments by direct debit from the above bank account on the basis of the "Terms and Conditions for Payments by Direct Debit in the SEPA Direct Debit Scheme". I am obliged to inform you immediately in writing of any changes or cancellation of the SEPA Business-to-Business Direct Debit Mandate vis-à-vis the payment recipient.`,
                })}*`}
              />
            </Box>
          </Box>
          <StartDatePanel
            isFlexibleContract={company?.regularStartDate === 'flexible'}
            maximumDate={maximumDate}
            minimumDate={minimumDate}
          />
          <Box className={`footer ${data?.proFit ? 'profit' : ''}`}>
            <Button onClick={() => updateStep(Number(stepNumber) - 1)} variant={'outlined'}>
              {intl.formatMessage({
                id: 'step2.back_button',
                defaultMessage: 'Back',
              })}
            </Button>
            <Button type='submit' variant={'contained'}>
              {intl.formatMessage({
                id: 'step2.continue',
                defaultMessage: 'Continue: Summary page',
              })}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default StepThree;
