import {
  Typography,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  Box,
  CircularProgress,
} from '@mui/material';
import Spacer from 'components/Spacer';
import { useIntl } from 'react-intl';
import { ModalWrapper } from './Modal.styles';
import { DefaultTextField } from 'components/FormComponents/PasswordTextField/DefaultTextField.styles';
import CancelIcon from '@mui/icons-material/Cancel';
import { useEffect, useState } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useAppDispatch, useAppSelector } from 'store';
import { CompanyActions } from 'store/company';
import { actions } from 'store/company/company.slice';
import { CompanySelectors } from 'store/company/company.selectors';
import { Voucher } from '..';
import { IVoucher, VoucherCalculations } from 'store/company/interfaces';
import { FetchingStatus } from 'store/interfaces';

interface AddVoucherModalProps {
  allVouchers: Voucher[];
  handleClose: () => void;
  handleAdd: (validVouchers: IVoucher[] | [], newCosts: VoucherCalculations) => void;
}

const AddVoucherModal: React.FC<AddVoucherModalProps> = ({
  allVouchers,
  handleClose,
  handleAdd,
}) => {
  const { formatMessage } = useIntl();
  const [voucherInput, setVoucherInput] = useState('PF');
  const [voucherInvalid, setVoucherInvalid] = useState(false);
  const [voucherAmountIsLarge, setVoucherAmountIsLarge] = useState(false);

  const voucherState = useAppSelector(state => state.company.vouchers);
  const userInfo = useAppSelector(CompanySelectors.getUserInfo);
  const userData = userInfo?.customFields.proFit_UserId;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (voucherState.fetchingStatus === FetchingStatus.FULFILLED && voucherState.data) {
      const newCosts = {
        profitBalance: voucherState.data.totalVoucherValue,
        membershipValue: voucherState.data.hansefitCost,
        paymentValue: voucherState.data.payableAmount,
      };
      handleAdd(voucherState.data.valid, newCosts);
      dispatch(actions.resetVoucherFetchingStatus(FetchingStatus.IDLE));
      closePopup();
    } else if (voucherState.fetchingStatus === FetchingStatus.REJECTED) {
      if (voucherState.error?.message === 'voucher_value_is_too_large') {
        setVoucherAmountIsLarge(true);
      } else if (voucherState.error?.message === 'voucher_is_invalid') {
        setVoucherInvalid(true);
      } else {
        //maybe we need to add some another error message
        setVoucherInvalid(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucherState.fetchingStatus]);

  const closePopup = () => {
    handleClose();
    dispatch(actions.resetVoucherFetchingStatus(FetchingStatus.IDLE));
    setVoucherInvalid(false);
    setVoucherAmountIsLarge(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const input = event.target.value;
    const checkIfCopied = input.slice(0, 4);
    const prepareValue = checkIfCopied === 'PFPF' ? input.slice(2) : input;

    setVoucherInput(prepareValue);
    setVoucherInvalid(false);
    setVoucherAmountIsLarge(false);
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key !== 'Backspace' && voucherInput.length === 8) setVoucherInput(voucherInput + '-');
    if (event.key === 'Enter') handleSubmit();
  };

  const handleSubmit = async () => {
    const checkVoucher = {
      userData,
      newVoucher: [voucherInput],
      existingVoucher: allVouchers.map(voucher => voucher.code),
    };
    dispatch(CompanyActions.verifyVoucher(checkVoucher));
  };

  return (
    <ModalWrapper open={true} onClose={closePopup}>
      <DialogTitle variant='h2'>
        {(voucherInvalid || voucherAmountIsLarge) && (
          <Box>
            <ErrorOutlineIcon fontSize='large' color='error' />
          </Box>
        )}
        <Box>
          {voucherInvalid
            ? formatMessage({
                id: 'step0.add_voucher.modal.headline.invalid_voucher',
                defaultMessage: 'Voucher is invalid',
              })
            : voucherAmountIsLarge
            ? formatMessage({
                id: 'step0.add_voucher.modal.headline.voucher_is_too_large',
                defaultMessage: 'Voucher is too large',
              })
            : formatMessage({
                id: 'step0.add_voucher.modal.headline',
                defaultMessage: 'Add voucher code',
              })}
        </Box>
      </DialogTitle>

      <DialogContent>
        <Spacer height={24} />
        <Typography variant='body2'>
          {voucherInvalid
            ? formatMessage({
                id: 'step0.add_voucher.modal.subheadline.invalid_voucher',
                defaultMessage:
                  'This voucher code is invalid. Check the code again ot try another voucher.',
              })
            : voucherAmountIsLarge
            ? formatMessage({
                id: 'step0.add_voucher.modal.subheadline.voucher_is_too_large',
                defaultMessage:
                  'The vouchers sum is more than the Hansefit costs and that the voucher can not be added.',
              })
            : formatMessage({
                id: 'step0.add_voucher.modal.subheadline',
                defaultMessage: 'Enter your new voucher code below.',
              })}
        </Typography>
        <Spacer height={46} />

        <DefaultTextField
          fullWidth
          label={formatMessage({
            id: 'step0.add_voucher.modal.text_field.label',
            defaultMessage: 'Voucher code',
          })}
          type='text'
          disabled={voucherState.fetchingStatus === FetchingStatus.PENDING}
          value={voucherInput}
          helperText={formatMessage({
            id: 'step0.add_voucher.modal.text_field.helper',
            defaultMessage: 'e.g.: PF012345-XXXXXX',
          })}
          onChange={event => handleChange(event)}
          onKeyUp={event => handleKeyUp(event)}
          error={voucherInvalid || voucherAmountIsLarge}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                {voucherState.fetchingStatus !== FetchingStatus.PENDING ? (
                  <CancelIcon
                    fontSize='small'
                    color='disabled'
                    className='icon-clear'
                    onClick={() => {
                      setVoucherInput('');
                      setVoucherInvalid(false);
                      setVoucherAmountIsLarge(false);
                    }}
                  />
                ) : (
                  <CircularProgress size='1rem' />
                )}
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>
      <Spacer height={46} />
      <DialogActions>
        <Button
          variant='outlined'
          className='cancelBtn'
          onClick={closePopup}
          disabled={voucherState.fetchingStatus === FetchingStatus.PENDING}>
          {formatMessage({
            id: 'step0.add_voucher.modal.cta.cancel',
            defaultMessage: 'Cancel',
          })}
        </Button>
        <Button
          variant='contained'
          onClick={handleSubmit}
          disabled={
            voucherInvalid ||
            voucherAmountIsLarge ||
            voucherState.fetchingStatus === FetchingStatus.PENDING ||
            voucherInput.length < 3
          }>
          {formatMessage({
            id: 'step0.add_voucher.modal.cta.confirm',
            defaultMessage: 'Add voucher code',
          })}
        </Button>
      </DialogActions>
      <Spacer height={36} />
      {/* </form> */}
    </ModalWrapper>
  );
};

export default AddVoucherModal;
