import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { ICompaniesState } from 'store/company/interfaces';
import { FetchingStatus } from 'store/interfaces';
import { getCountriesList } from './getCountriesList';

export const getCountriesListExtraReducer = (builder: ActionReducerMapBuilder<ICompaniesState>) => {
  builder.addCase(getCountriesList.pending, (state, action) => {
    if (!state.countriesList)
      state['countriesList'] = { fetchingStatus: FetchingStatus.PENDING, data: [], error: null };
    else {
      state.countriesList.fetchingStatus = FetchingStatus.PENDING;
      state.countriesList.error = null;
    }
  });
  builder.addCase(getCountriesList.fulfilled, (state, action) => {
    if (action.payload && action.payload.length > 0) {
      state.countriesList.data = action.payload;
      state.countriesList.fetchingStatus = FetchingStatus.FULFILLED;
    } else {
      state.countriesList.error = { message: 'API is broken' };
      state.countriesList.fetchingStatus = FetchingStatus.REJECTED;
    }
  });

  builder.addCase(getCountriesList.rejected, (state, action) => {
    state.countriesList.error = action.error;
    state.countriesList.fetchingStatus = FetchingStatus.REJECTED;
  });
};
