import { rangeValidation } from 'utils/dateRangeValidation';
import * as yup from 'yup';

export const personalInfoSchema = (
  dateOfBirthLimits: { min: Date; max: Date } | undefined,
  startDateLimits?: { min: Date; max: Date } | undefined,
) => {
  const startDate = startDateLimits
    ? {
        startDate: yup
          .date()
          .typeError('form.employee.error.service_date.invalid')
          .nullable()
          .test('isValid', 'form.employee.error.service_date.invalid_range', (val, context) =>
            rangeValidation(val, startDateLimits),
          )
          .required('form.employee.error.service_date.required'),
      }
    : { startDate: yup.date() };

  return yup
    .object({
      firstName: yup.string().required('form.employee.error.first_name.required'),
      lastName: yup.string().required('form.employee.error.last_name.required'),
      gender: yup.string().required('form.employee.error.gender.required'),
      dateOfBirth: yup
        .date()
        .typeError('form.employee.error.date_of_birth.invalid')
        .nullable()
        .test('isValid', 'form.employee.error.date_of_birth.invalid_range', (val, context) =>
          rangeValidation(val, dateOfBirthLimits),
        )
        .required('form.employee.error.date_of_birth.required'),
      ...startDate,
    })
    .required();
};

export default personalInfoSchema;
