import { ICompanyState } from "store/company/interfaces";

export  const prepareSaveData = (data: any, objectName: 'customFields' | 'globalCustomFields', company: ICompanyState | null) => {
    if (company && company[objectName]) {
      Object.keys(data[objectName]).forEach(item => {
        const currentCF = company[objectName].find(cf => cf.key === item);
        if (
          currentCF &&
          data[objectName][item] &&
          ['integer', 'float'].includes(currentCF.inputType)
        ) {
          data[objectName][item] = Number(data[objectName][item]);
        }
      });
    }
    return data;
  };