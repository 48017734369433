/**
 * StepZero
 * Component is currently only used for proFit usecase
 */

import { Box, Button, Typography } from '@mui/material';
import { Stepper } from '..';
import { useIntl } from 'react-intl';
import CostDetails from './costDetails';
import { useNavigate, useParams } from 'react-router-dom';
import Spacer from 'components/Spacer';
import { useAppDispatch, useAppSelector } from 'store';
import { CompanySelectors } from 'store/company/company.selectors';
import VoucherBox from './voucherBox';
import { useCallback, useEffect, useMemo, useState } from 'react';
import AddVoucherModal from './Modals/addVoucherModal';
import ActionFeedback from '../ActionFeedback/ActionFeedback';
import DeleteVoucherModal from './Modals/deleteVoucherModal';
import { actions } from 'store/company/company.slice';
import { IVoucher, VoucherCalculations } from 'store/company/interfaces';
import { CompanyActions } from 'store/company';
import { FetchingStatus } from 'store/interfaces';
import { formatPrice } from 'utils/formatPrice';
import { ErrorIcon } from 'components/ModalError/ModalError.styles';

interface StepOneProps {
  updateStep: (stepNumber: number) => void;
}
export interface Voucher {
  code: string;
  initialValue: string;
  isInvalid?: boolean;
}

// Profit allows to use 15 vouchers for one registration
// https://hansefit.atlassian.net/browse/CCP-1822
const NUMBER_MAX_VOUCHERS_PROFIT = 15;

const StepZero = ({ updateStep }: StepOneProps) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { id, stepNumber } = useParams();
  const company = useAppSelector(CompanySelectors.getCompanyData);
  const userVouchers = useAppSelector(CompanySelectors.getVouchers);
  const userInfo = useAppSelector(CompanySelectors.getUserInfo);
  const invalidSubmittedVouchers =
    useAppSelector(store => store.company.invalidSubmittedVouchers) || [];
  const verification_error_message: string | null = useAppSelector(
    store => store.company.errorVoucher,
  );

  const [profitCalculations, setProfitCalculations] = useState<VoucherCalculations>({
    profitBalance: '',
    membershipValue: '',
    paymentValue: '',
  });
  const [isVoucherValueError, setIsVoucherValueError] = useState<boolean>(false);
  const [isVoucherInvalid, setIsVoucherInvalid] = useState<boolean>(false);
  const [isInvalidUserData, setIsInvalidUserData] = useState<boolean>(false);

  const [feedbackSuccess, setFeedbackSuccess] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const removalModalInitState = { voucherCode: '', voucherValue: '' };
  const [removeModal, setRemoveModal] = useState(removalModalInitState);
  const voucherList = useMemo(
    () => (userVouchers && userVouchers.data ? userVouchers.data.valid : []),
    [userVouchers],
  );

  const isVerificationError = !!verification_error_message;
  const duration = userInfo?.duration || '';
  const maxVoucherLimit = NUMBER_MAX_VOUCHERS_PROFIT;

  useEffect(() => {
    if (userVouchers.fetchingStatus === FetchingStatus.FULFILLED && userVouchers.data) {
      dispatch(actions.resetVoucherFetchingStatus(FetchingStatus.IDLE));
    }
  }, [dispatch, userVouchers.data, userVouchers.fetchingStatus]);

  const prepareVoucherList = useCallback(
    (vouchers: [] | IVoucher[]) => {
      if (!userVouchers.data) {
        return [];
      }

      return vouchers.map((el: any) => ({
        code: el.voucher.code,
        initialValue: el.voucher.initialValue,
        isInvalid: false,
        isRedeem: !!userVouchers.data?.invalid.find(invalid =>
          el.voucher.code.includes(invalid.voucher.code),
        ),
      }));
    },
    [userVouchers.data],
  );

  useEffect(() => {
    if (userVouchers.data) {
      setProfitCalculations({
        membershipValue: userVouchers.data.hansefitCost,
        profitBalance: userVouchers.data.totalVoucherValue.toString(),
        paymentValue: userVouchers.data.payableAmount.toString(),
      });
      setIsVoucherValueError(
        userVouchers.data.invalid.length === 1 &&
          userVouchers.data.invalid[0].error === 'voucher_value_is_too_large' &&
          userVouchers.data.valid.length === 0,
      );
      setIsVoucherInvalid(
        userVouchers.data.invalid.length === 1 &&
          userVouchers.data.invalid[0].error === 'voucher_is_invalid' &&
          userVouchers.data.valid.length === 0,
      );
      setIsInvalidUserData(
        userVouchers.data.invalid.length === 1 &&
          userVouchers.data.invalid[0].error === 'userData_is_invalid' &&
          userVouchers.data.valid.length === 0,
      );
    }

    if (voucherList) {
      setVouchers(prepareVoucherList(voucherList));
    }
  }, [prepareVoucherList, userVouchers.data, voucherList]);

  const [vouchers, setVouchers] = useState(
    voucherList && voucherList.length ? prepareVoucherList(voucherList) : [],
  );

  const addVoucher = (validVouchers: IVoucher[] | [], newCosts: VoucherCalculations) => {
    const updVoucherList = prepareVoucherList(validVouchers);
    setVouchers(updVoucherList);
    setProfitCalculations(newCosts);
    setFeedbackSuccess(true);
  };

  const removeVoucher = (voucherData: { voucherCode: string; voucherValue: string }) => {
    setFeedbackSuccess(false);
    dispatch(
      CompanyActions.verifyVoucher({
        userData: userInfo?.customFields.proFit_UserId,
        newVoucher: [],
        existingVoucher: vouchers
          .filter((el: any) => el.code !== voucherData.voucherCode)
          .map(item => item.code),
      }),
    );
  };

  const toNextStep = () => {
    dispatch(actions.updateVoucherCalculations(profitCalculations));
    updateStep(Number(stepNumber) + 1);
  };

  const errorHeader = useMemo(() => {
    if (isVerificationError) {
      return verification_error_message === 'user_is_already_signed_up'
        ? intl.formatMessage({
            id: 'step0.subsection.error.headline.user_is_already_signed_up.title',
            defaultMessage: 'You already signed up for Hansefit.',
          })
        : verification_error_message === 'voucher_value_is_too_large'
        ? intl.formatMessage({
            id: 'step0.subsection.error.headline.value_error',
            defaultMessage: 'You voucher value is too high',
          })
        : intl.formatMessage({
            id: 'step0.subsection.error.headline.voucher_is_invalid.title',
            defaultMessage: 'Your voucher is invalid.',
          });
    } else if (isVoucherValueError) {
      return intl.formatMessage({
        id: 'step0.subsection.error.headline.value_error',
        defaultMessage: 'You voucher value is too high',
      });
    } else if (isVoucherInvalid) {
      return intl.formatMessage({
        id: 'step0.subsection.error.headline.voucher_invalid',
        defaultMessage: 'Your voucher is invalid, because it was canceled',
      });
    } else {
      return intl.formatMessage({
        id: 'step0.subsection.error.headline.data_missing',
        defaultMessage: 'Important information is missing',
      });
    }
  }, [
    isVerificationError,
    verification_error_message,
    intl,
    isVoucherValueError,
    isVoucherInvalid,
  ]);

  const errorBody = useMemo(() => {
    if (isVerificationError) {
      return verification_error_message === 'user_is_already_signed_up'
        ? intl.formatMessage({
            id: 'step0.subsection.error.user_is_already_signed_up.body',
            defaultMessage:
              'Please contact Hansefit, if you have any questions regarding your membership.',
          })
        : verification_error_message === 'voucher_value_is_too_large'
        ? intl.formatMessage(
            {
              id: 'step0.subsection.error.amount.body',
              defaultMessage:
                'Please cancel the voucher in the proFit portal and generate a new one with a maximum value {amount}',
            },
            { amount: profitCalculations.membershipValue },
          )
        : intl.formatMessage({
            id: 'step0.subsection.error.voucher_is_invalid.body',
            defaultMessage:
              'It is not possible to continue. Please go back to proFit and create a new voucher.',
          });
    } else if (isVoucherValueError) {
      return intl.formatMessage(
        {
          id: 'step0.subsection.error.amount.body',
          defaultMessage:
            'Please cancel the voucher in the proFit portal and generate a new one with a maximum value {amount}',
        },
        { amount: profitCalculations.membershipValue },
      );
    } else if (isVoucherInvalid) {
      return intl.formatMessage({
        id: 'step0.subsection.error.voucher_invalid.body',
        defaultMessage:
          'It is not possible to continue. Please go back to proFit and create a new voucher.',
      });
    } else {
      return intl.formatMessage({
        id: 'step0.subsection.error.data_missing.body',
        defaultMessage:
          'It is not possible to continue, because important informagtion is missing from the request.',
      });
    }
  }, [
    isVerificationError,
    verification_error_message,
    intl,
    isVoucherValueError,
    profitCalculations.membershipValue,
    isVoucherInvalid,
  ]);

  const isError =
    isVerificationError ||
    isVoucherInvalid ||
    isInvalidUserData ||
    voucherList.length === 0 ||
    isVoucherValueError;
  const cantAddMoreVouchers =
    vouchers.length >= maxVoucherLimit ||
    isVerificationError ||
    vouchers.length === 0 ||
    profitCalculations.paymentValue === '0';

  if (!company) {
    return null;
  }

  return (
    <>
      {addModal && (
        <AddVoucherModal
          allVouchers={vouchers}
          handleAdd={addVoucher}
          handleClose={() => setAddModal(false)}
        />
      )}
      {removeModal.voucherCode && (
        <DeleteVoucherModal
          voucherData={removeModal}
          handleRemove={removeVoucher}
          handleClose={() => setRemoveModal(removalModalInitState)}
        />
      )}
      <Box className='stepWrapper'>
        <Stepper hasError={isVerificationError} />
        <Typography variant='h5'>
          {intl.formatMessage({
            id: 'step0.description.upper',
            defaultMessage: 'Please, confirm voucher information',
          })}
        </Typography>
        <Spacer height={24} />
        <Box className='section'>
          <Typography variant='subtitle2'>
            {intl.formatMessage({
              id: 'step0.subsection.headline.voucher_code',
              defaultMessage: 'Your voucher code',
            })}
          </Typography>
          {/* feedback actions(after add/remove vouchers) --- start */}
          {invalidSubmittedVouchers.length > 0 && (
            <ActionFeedback
              headline={intl.formatMessage({
                id: 'step0.subsection.success.headline.redeem_vouchers',
                defaultMessage: 'Vouchers are invalid',
              })}
              body={
                <>
                  {invalidSubmittedVouchers.map(item => (
                    <Typography key={item.voucher.code}>
                      {`${item.voucher.code} (${formatPrice(item.voucher.initialValue)})`}
                    </Typography>
                  ))}
                  <Spacer height={24} />
                  <Typography variant='body2'>
                    {intl.formatMessage({
                      id: 'step0.subsection.success.body.redeem_vouchers',
                      defaultMessage:
                        'Add another voucher or complete the registration with the remaining valid vouchers.',
                    })}
                  </Typography>
                </>
              }
              type='warning'
            />
          )}
          {feedbackSuccess && (
            <ActionFeedback
              headline={intl.formatMessage({
                id: 'step0.subsection.success.headline.add_voucher',
                defaultMessage: 'You voucher was successefully verified',
              })}
              type='success'
            />
          )}
          {/* feedback actions(after add/remove vouchers) --- start */}
        </Box>
        {/* vouchers list --- start */}
        <Box className='sectionBox'>
          <Spacer height={34} />
          {isVerificationError || vouchers.length === 0 ? (
            <VoucherBox
              isDeletable={false}
              code='error'
              value='error'
              isInvalid={true}
              isRedeem={false}
              handleRemove={() => {}}
            />
          ) : (
            vouchers.map((voucher, idx) => (
              <VoucherBox
                key={voucher.code + idx}
                isDeletable={vouchers.length > 1}
                code={voucher.code}
                value={voucher.initialValue}
                isInvalid={voucher.isInvalid}
                isRedeem={voucher.isRedeem}
                handleRemove={() =>
                  setRemoveModal({ voucherCode: voucher.code, voucherValue: voucher.initialValue })
                }
              />
            ))
          )}
          <Spacer height={34} />
        </Box>
        {/* vouchers list --- end */}
        <Spacer height={18} />
        <Box
          className='section'
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          {vouchers.length >= maxVoucherLimit && (
            <Box className='hint'>
              <ErrorIcon fontSize='small' color='disabled' />
              <Typography variant='body1' fontSize={12}>
                {intl.formatMessage(
                  {
                    id: 'step0.subsection.success.body.max_voucher_limit',
                    defaultMessage: 'You reached the maximum of {maxVoucherLimit} vouchers',
                  },
                  { maxVoucherLimit },
                )}
              </Typography>
            </Box>
          )}
          {profitCalculations.paymentValue === '0' && (
            <Box className='hint'>
              <ErrorIcon fontSize='small' color='disabled' />
              <Typography variant='body1' fontSize={12}>
                {intl.formatMessage({
                  id: 'step0.subsection.success.body.zero_payment_value',
                  defaultMessage: 'You have no payment value',
                })}
              </Typography>
            </Box>
          )}

          <Box className='cta-container'>
            <Button
              className='secondaryBtn'
              data-testid='addVoucherButton'
              onClick={() => {
                setAddModal(true);
                setFeedbackSuccess(false);
              }}
              variant='outlined'
              disabled={cantAddMoreVouchers}>
              {intl.formatMessage({
                id: 'step0.button.add_voucher',
                defaultMessage: 'Add voucher',
              })}
            </Button>
          </Box>
        </Box>

        {/* calculations block --- start */}
        {!isError && (
          <Box className='section'>
            <Spacer height={24} />
            <Typography variant='subtitle2'>
              {intl.formatMessage({
                id: 'step0.subsection.headline.costs_calculations',
                defaultMessage: 'Cost of your membership',
              })}
            </Typography>
            <Box className='sectionBox'>
              <Box className='sectionBox__costs'>
                <CostDetails
                  bodyText={intl.formatMessage({
                    id: 'step0.subsection.headline.costs_calculations.membership.body',
                    defaultMessage: 'Hansefit membership',
                  })}
                  subnote={intl.formatMessage(
                    {
                      id: 'step0.subsection.headline.costs_calculations.membership.subnote.placeholder',
                      defaultMessage: 'Kosten für {duration} Monate Mitgliedschaft',
                    },
                    { duration },
                  )}
                  value={profitCalculations.membershipValue}
                  containerStyle='basic'
                  divider={false}
                />
                <CostDetails
                  bodyText={intl.formatMessage({
                    id: 'step0.subsection.headline.costs_calculations.balance.body',
                    defaultMessage: 'Your proFit balance',
                  })}
                  subnote={intl.formatMessage({
                    id: 'step0.subsection.headline.costs_calculations.balance.subnote',
                    defaultMessage: 'Default text',
                  })}
                  value={profitCalculations.profitBalance}
                  containerStyle='basic'
                />
                <CostDetails
                  bodyText={intl.formatMessage({
                    id: 'step0.subsection.headline.costs_calculations.overall.body',
                    defaultMessage: 'You are paying',
                  })}
                  subnote={intl.formatMessage({
                    id: 'step0.subsection.headline.costs_calculations.overall.subnote',
                    defaultMessage: 'For your Hansefit membership',
                  })}
                  value={profitCalculations.paymentValue}
                  containerStyle='highlight'
                />
              </Box>
            </Box>
            <Spacer height={48} />
            <Typography className='subtext' variant='h5'>
              {intl.formatMessage({
                id: 'step0.description.lower',
                defaultMessage:
                  'Erklärungstext zu den Zahlungsinformationen: Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy.',
              })}
            </Typography>
          </Box>
        )}
        {/* calculations block --- end */}

        {isError ? (
          <Box>
            <Spacer height={24} />
            <ActionFeedback type='error' headline={errorHeader} body={errorBody} />
          </Box>
        ) : null}

        <Box className='footer profit'>
          <Button
            disabled={isError}
            data-testid='goBackButton'
            onClick={() => {
              setFeedbackSuccess(false);
              navigate(`/${id}/landing`);
            }}
            variant='outlined'>
            {intl.formatMessage({
              id: 'step2.back_button',
              defaultMessage: 'Back',
            })}
          </Button>

          <Button
            disabled={isError}
            onClick={toNextStep}
            data-testid='goNextButton'
            variant='contained'>
            {intl.formatMessage({
              id: 'step0.next_button',
              defaultMessage: 'Next',
            })}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default StepZero;
