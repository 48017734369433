import { Box, Button, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import EditIcon from '@mui/icons-material/Edit';
import { DefaultTextField } from 'components/FormComponents/PasswordTextField/DefaultTextField.styles';
import ErrorIcon from '@mui/icons-material/Error';
import Spacer from 'components/Spacer';

interface PaymentDetailsProps {
  iban: string | null | undefined;
  edit: () => void;
}

const PaymentDetails: React.FC<PaymentDetailsProps> = ({ iban, edit }) => {
  const intl = useIntl();
  const formattedIban = iban?.length ? iban.replaceAll('X', '*') : '';
  const handleEdit = () => {
    edit();
  };
  return (
    <Box className="paymentDetailsHolder">
      <DefaultTextField
        name={'iban'}
        value={formattedIban}
        label={`${intl.formatMessage({
          id: 'form.employee.field.iban.label',
          defaultMessage: 'IBAN',
        })}*`}
        className="textField"
        InputProps={{
          endAdornment: (
            <Button
              variant="text"
              onClick={handleEdit}
              startIcon={<EditIcon />}
              className="editBtn">
              {intl.formatMessage({
                id: 'step4.action.edit',
                defaultMessage: 'Edit',
              })}
            </Button>
          ),
        }}
      />
      <Spacer height={12} />
      <Box className="hint">
        <ErrorIcon fontSize="small" color="disabled" />
        <Typography variant="body1" fontSize={14} fontWeight="bold">
          {intl.formatMessage({
            id: 'form.employee.field.iban_change.hint',
            defaultMessage: 'When you click "Edit", your bank details will be cleared out',
          })}
        </Typography>
      </Box>
    </Box>
  );
};

export default PaymentDetails;
