import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { ICompaniesState } from 'store/company/interfaces';
import { FetchingStatus } from 'store/interfaces';
import { getCompanyInfo } from './getCompanyInfo';

export const getCompanyExtraReducer = (builder: ActionReducerMapBuilder<ICompaniesState>) => {
  builder.addCase(getCompanyInfo.pending, (state, action) => {
    state.fetchingStatus = FetchingStatus.PENDING;
    state.error = null;
    state.errorViolations = null;
  });
  builder.addCase(getCompanyInfo.fulfilled, (state, action) => {
    if (action.payload && action.payload.length > 0) {
      state.data = action.payload[0];
    } else {
      state.error = { message: 'Company not exist' };
    }
    state.fetchingStatus = FetchingStatus.FULFILLED;
  });

  builder.addCase(getCompanyInfo.rejected, (state, action) => {
    state.error = action.error;
    state.fetchingStatus = FetchingStatus.REJECTED;
  });
};
