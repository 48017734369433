import { RootState } from 'store/interfaces';

const getError = (state: RootState) => state.company.error;

const getPassword = (state: RootState) => state.company.password;

const getSignupId = (state: RootState) => state.company.signupId;

const getUserInfo = (state: RootState) => state.company.userInfo;

const getCompanyData = (state: RootState) => state.company.data;

const getFetchingStatus = (state: RootState) => state.company.fetchingStatus;

const getViolationErrors = (state: RootState) => state.company.errorViolations;

//getHiddenFields - we need to hide fields for editing, but need to show these fields on the summary page
const getHiddenFields = (state: RootState) => state.company.prefilled.NOSHOW;

//getFullHiddenFields - we need to hide fields from all steps(and from the summary page)
const getFullHiddenFields = (state: RootState) => state.company.prefilled.HIDE;

const getDisabledFields = (state: RootState) => state.company.prefilled.NOCHANGE;

const getVouchers = (state: RootState) => state.company.vouchers;

export const CompanySelectors = {
  getError,
  getPassword,
  getSignupId,
  getUserInfo,
  getCompanyData,
  getFetchingStatus,
  getViolationErrors,
  getHiddenFields,
  getFullHiddenFields,
  getDisabledFields,
  getVouchers,
};
